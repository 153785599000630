import React, { useEffect } from "react";

import {
  Container,
  Switch,
  Box,
  Card,
  Typography,
  Tabs,
  Tab,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import {
  GetTeacherBreaks,
  GetTeacherTiming,
  GetTeacherTimingKids,
} from "../redux/actions/SessionAction";
import RegularWorkingHours from "../component/WorkingHours/RegularWorkingHours";
import KidsWorkingHours from "../component/WorkingHours/KidsWorkingHours";
import { updateInstantBookingOption } from "../redux/actions/AuthAction";
import { useSnackbar } from "react-simple-snackbar";
import BreakHours from "../component/WorkingHours/BreakHours";

const useStyles = makeStyles((theme) => ({
  root: {
    // ...theme.typography.button,
    // backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(5),
  },
  card: {
    padding: "2rem",
  },
  cardDate: {
    padding: "0.6rem",
    margin: "1rem 0",
    width: "100%",
    backgroundColor: "#fff",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "50%",
    maxWidth: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    backgroundColor: "#3ea175",
    color: "#fff",
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));
const label = { inputProps: { "aria-label": "Switch demo" } };
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const WorkingHour = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const teacherData = useSelector((state) => state.TeacherReducer.teacher);
  const localhostteacher = JSON.parse(localStorage.getItem("TEACHER"));
  const [value, setValue] = React.useState(0);
  const [regularTiming, setRegularTiming] = React.useState(false);
  const [kidsTiming, setKidsTiming] = React.useState(true);
  const [subject, setSubject] = React.useState(
    localhostteacher.subjects[0]?.subject
  );

  const [isChecked, setIsChecked] = React.useState({
    English: { adults: false, kids: false },
    IELTS: { adults: false, kids: false },
  });

  const handleChange = (event) => {
    setSubject(event.target.value);
  };

  const changeTab = (event, newValue) => {
    setValue(newValue);
  };

  console.log(teacherData?.subjects, subject, "teacher data");

  useEffect(() => {
    // Check if teacherData and subjects are available
    if (teacherData && teacherData.subjects) {
      const item1 = teacherData.subjects.find(
        (item) => item.subject === "English"
      );
      const item2 = teacherData.subjects.find(
        (item) => item.subject === "IELTS"
      );

      if (item1 || item2) {
        // console.log("ITEM", item, item?.allowWeekSlotsReplication.adults);
        // console.log("TIMING", regularTiming, kidsTiming);
        // console.log("value", value)
        // console.log("adults", item?.allowWeekSlotsReplication.adults, "kids", item?.allowWeekSlotsReplication.kids)
        let data = {};
        if (item1)
          data["English"] = {
            adults: item1?.allowWeekSlotsReplication.adults,
            kids: item1?.allowWeekSlotsReplication.kids,
          };
        if (item2)
          data["IELTS"] = {
            adults: item2?.allowWeekSlotsReplication.adults,
            kids: item2?.allowWeekSlotsReplication.kids,
          };

        setIsChecked(data);
        // if (value) {
        //   console.log("if value", value, item?.allowWeekSlotsReplication.kids)
        //   setIsChecked(item?.allowWeekSlotsReplication.kids)
        // } else {
        //   console.log("else value", value, item?.allowWeekSlotsReplication.adults)
        //   setIsChecked(item?.allowWeekSlotsReplication.adults)
        // }
      } else {
        console.log("NO ITEM"); // Handle the case when the subject is not found
      }
    }
  }, [teacherData]);

  React.useEffect(() => {
    // if(localhostteacher?.isSalesPerson){
    //   localhostteacher.subjects?.map((data) => {
    //     if (subject === data.subject) {
    //       setRegularTiming(data.adults);
    //     }
    //   })
    //   setValue(0);
    // }
    // else
    localhostteacher.subjects?.map((data) => {
      if (subject === data.subject) {
        setRegularTiming(data.adults);
        setKidsTiming(data.kids);
        if (data.adults === false && data.kids === true) {
          setValue(1);
        } else if (data.adults === false && data.kids === false) {
          setValue(0);
        }
      }
    });

    // if(localhostteacher?.isSalesPerson){
    //   dispatch(
    //     GetTeacherBreaks({
    //       teacherId: localhostteacher._id,
    //       forKids: false,
    //       subject: subject,
    //     })
    //   );
    // }
    // else{
    dispatch(
      GetTeacherTiming({
        teacherId: localhostteacher._id,
        forKids: false,
        subject: subject,
      })
    );
    dispatch(
      GetTeacherTimingKids({
        teacherId: localhostteacher._id,
        forKids: true,
        subject: subject,
      })
    );
    // }
  }, [localhostteacher._id, subject]);

  const disableInstantBooking = () => {
    const onSuccess = (msg) => {
      openSnackbar(msg);
    };
    const onError = (msg) => {
      openSnackbar(msg);
    };
    dispatch(
      updateInstantBookingOption(
        !teacherData?.instantBooking,
        onSuccess,
        onError
      )
    );
  };

  console.log("switch value", isChecked);
  return (
    <Container className={classes.root}>
      <Card className={classes.card}>
        {/* <div style={{ paddingBottom: "10px" }}>
          <small ><strong>Note: </strong>Automatic hourly booking will continue until you uncheck the 'Allow Instant Booking' option. The one-hour buffer will not apply if you have enabled instant booking.</small>
        </div> */}

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {false ? (
            <h1 className="heading">Set your breaks</h1>
          ) : (
            <>
              <h1 className="heading">
                Set your working hour for {value === 1 ? "Kids" : ""} {subject}
              </h1>
              <div>
                {/* {
              !teacherData ?
                <p>LoadingButton...</p>
                :
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={teacherData?.instantBooking}
                      onChange={(event) => disableInstantBooking()}
                      color="primary"
                    />
                  }
                  label="Allow instant booking"
                />
            } */}
                <FormControlLabel
                  control={
                    <Switch
                      {...label}
                      checked={
                        isChecked[subject][value === 0 ? "adults" : "kids"]
                      }
                      onChange={(event) =>
                        setIsChecked({
                          ...isChecked,
                          [subject]: {
                            ...isChecked[subject],
                            [value === 0 ? "adults" : "kids"]:
                              event.target.checked,
                          },
                        })
                      }
                      color="primary"
                    />
                  }
                  label="Replicate slots for next week"
                />
              </div>
              <FormControl style={{ width: "10rem" }}>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Subject
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={subject}
                  defaultValue={subject}
                  onChange={handleChange}
                  autoWidth
                  label="Subject"
                >
                  {localhostteacher.subjects?.map((data, i) => (
                    <MenuItem value={data.subject} key={i}>
                      {data.subject}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </div>

        <>
          {false ? (
            <Tabs
              value={value}
              onChange={changeTab}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Break Time" {...a11yProps(0)} />
            </Tabs>
          ) : regularTiming && kidsTiming ? (
            <Tabs
              value={value}
              onChange={changeTab}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              {regularTiming ? (
                <Tab label="Regular Time" {...a11yProps(0)} />
              ) : (
                ""
              )}
              {kidsTiming &&
              !localhostteacher?.isSalesPerson &&
              !localhostteacher?.isFluencyCounsellor ? (
                <Tab label="Kids Time" {...a11yProps(1)} />
              ) : (
                ""
              )}
            </Tabs>
          ) : (
            ""
          )}

          {false ? (
            <TabPanel value={value} index={0}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                {teacherData?.isSalesPerson ||
                teacherData?.isFluencyCounsellor ? (
                  <BreakHours
                    replicate={
                      isChecked[subject][value === 2 ? "adults" : "kids"]
                    }
                    subject={subject}
                  />
                ) : regularTiming ? (
                  <RegularWorkingHours
                    replicate={
                      isChecked[subject][value === 0 ? "adults" : "kids"]
                    }
                    subject={subject}
                  />
                ) : (
                  ""
                )}
              </Box>
            </TabPanel>
          ) : (
            <>
              <TabPanel value={value} index={0}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  {teacherData?.isSalesPerson ||
                  teacherData?.isFluencyCounsellor ? (
                    <RegularWorkingHours
                      replicate={
                        isChecked[subject][value === 0 ? "adults" : "kids"]
                      }
                      subject={subject}
                    />
                  ) : regularTiming ? (
                    <RegularWorkingHours
                      replicate={
                        isChecked[subject][value === 0 ? "adults" : "kids"]
                      }
                      subject={subject}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </TabPanel>

              <TabPanel value={value} index={1}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <KidsWorkingHours
                    subject={subject}
                    replicate={
                      isChecked[subject][value === 0 ? "adults" : "kids"]
                    }
                    regularTiming={regularTiming}
                  />
                </Box>
              </TabPanel>
            </>
          )}
        </>
      </Card>
    </Container>
  );
};

export default WorkingHour;
