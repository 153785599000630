import React from "react";
import { Container, TextField, Paper, Typography } from "@material-ui/core";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {  useSelector } from "react-redux";
import {
  Scheduler,
  DayView,
  WeekView,
  Appointments,
  AppointmentTooltip,
  Toolbar,
  DateNavigator,
  ViewSwitcher,
  MonthView
} from "@devexpress/dx-react-scheduler-material-ui";

import {HistoryDate} from "../demo-data/today-apointment";
import {appointments} from "../demo-data/apointments";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    // ...theme.typography.button,
    // backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
  },
  cardDate: {
    padding: "0.6rem",
    margin: "1rem 0",
    width: "100%",
    backgroundColor: "#fff",
  },
}));

const SessionHistory = () => {
  const classes = useStyles();

  const PastSession = useSelector((state) => state.TeacherReducer.pastSession);
  // const appointments = PastSession?PastSession.map((data,i)=>(
  // {
  //   title: `Clapingo Session ${i}`,
  //   startDate: new Date(
  //   parseInt(historyDate(data.sessionDate,data.startTime).year),
  //   parseInt(historyDate(data.sessionDate,data.startTime).mnth),
  //   parseInt(historyDate(data.sessionDate,data.startTime).date),
  //   // parseInt(historyDate(data.sessionDate,data.startTime).hr),
    
  //      10, parseInt(historyDate(data.sessionDate,data.startTime).min)
  //   ),
  //   endDate: new Date(
  //     parseInt(historyDate(data.sessionDate,data.endTime).year),
  //     parseInt(historyDate(data.sessionDate,data.endTime).mnth),
  //     // parseInt(historyDate(data.sessionDate,data.endTime).date),
  //     // parseInt(historyDate(data.sessionDate,data.endTime).hr),
      
  //      4, 10, 
  //      parseInt(historyDate(data.sessionDate,data.endTime).min)
  //     ),
  //   id: data._id,
  //   location: 'Room 1',
  // }
  // )
  // ):[]

  // title: `Clapingo Session ${i}`,
  // startDate: new Date(HistoryDate(data.sessionDate,data.startTime)),
  // endDate: new Date(HistoryDate(data.sessionDate,data.endTime)),
  // id: data._id,
  // location: 'Room 1',

  return (
    <Container className={classes.root}>
      <Paper>
        <Scheduler 
        data={HistoryDate(appointments)}
        height={1000}>
          <ViewState currentDate={new Date()} defaultCurrentViewName="Week" />
          <DayView startDayHour={10} endDayHour={22} />
          <WeekView startDayHour={10} endDayHour={22} />
          <MonthView startDayHour={10} endDayHour={22} />
          <Toolbar />
          <DateNavigator />
          <ViewSwitcher />
          <Appointments />
          <AppointmentTooltip showCloseButton  />
        </Scheduler>
      </Paper>
    </Container>
  );
};

export default SessionHistory;
