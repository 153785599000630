import React from "react";
import { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Box, Container } from '@material-ui/core';
import VideoImage from "../../src/assets/videoDummy.png";
import { ResourcesUpload } from "../redux/actions/AuthAction";
import { useSnackbar } from "react-simple-snackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
}));

const HelpfulVideo = () => {
  const classes = useStyles();
  const [resourceList, setResourceList] = useState([]);
  const [openSnackbar] = useSnackbar();

  useEffect(() => {
    const data = {
      page: 1,
      limit: 10,
      documentType: "video/mp4",
    };
    const onSuccess = (res) => {
      setResourceList(res?.getAllResourceDetails);
      if(res.statusCode == 500){
        openSnackbar(res.message)
      }
    };
    const onError = (msg) => {
      openSnackbar(msg);
    };
    ResourcesUpload(data, onSuccess, onError);
  }, []);

  const openVideoInNewTab = (videoLink) => {
    window.open(videoLink, '_blank');
  };

  return (
    <Container className={classes.root}>
      <Paper component={Box} p={2} mx="auto">
        <h2 style={{ marginBottom: "1rem" }}>Helpful Video</h2>
        {
        resourceList?.map((video, index) => (
          <div key={video._id} className="resource-content">
            <img
              src={VideoImage}
              width="100"
              height="50"
              style={{ objectFit: "cover", borderRadius: "5%", cursor: "pointer" }}
              onClick={() => openVideoInNewTab(video.documentAwsLink)}
            />
            <div className="content-side">
              <div className="resource-name">{video.documentName}</div>
            </div>
          </div>
        ))
      }
      </Paper>
    </Container>
  );
};

export default HelpfulVideo;
