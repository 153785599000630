import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import takeNotificationPermission from "../helper/notificationHelper";
import { setToken } from "../helper/firebaseHelper";
import toast from "react-hot-toast";
import { Box, Modal } from "@mui/material";

const NotificationRequestPopup = ({notificationReqPopup, setNotificationReqPopup, onNotiReqLater, onNotiReqGrant}) => {

    const onGrant = () => {
        setNotificationReqPopup(false);
        toast.success("You will receive push notification for instant demo requests.");
        setToken();
        if(onNotiReqGrant) onNotiReqGrant();
    }

    const onDenied = () => {
        setNotificationReqPopup(false);
        toast.error("You will not recieve push notification for instant demo requests.");
    }

    const onLater = () => {
      setNotificationReqPopup(false);
      if(onNotiReqLater) onNotiReqLater();
    }
    
  const useStyles = makeStyles((theme) => ({
    popup: {
      fontFamily: "Inter",
      padding: "32px 24px",
      position: "absolute",
      display: "flex",
      alignItems: "center",
      gap: 12,
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: 50,
      background: "white",
      boxShadow:
        "rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px",
      outline: 0,
      border: 'none'
    },
    bellWrapper: {
      minHeight: "74px",
      height: "74px",
      width: "74px",
      minWidth: "74px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#3ea175",
      borderRadius: "50%",

      "& .fa-bell": {
        color: "white",
        fontSize: "24px",
      },
    },
    title: {
      fontSize: "14px",
    },
    btnGroup: {
      display: "flex",
      gap: 16,
      justifyContent: "flex-end",
      marginTop: "16px",

      button: {
        cursor: "pointer",
      },
    },
    allowBtn: {
      color: "white",
      background: "#3ea175",
      padding: "6px 28px",
      border: "none",
      cursor: "pointer",

      '&:hover': {
        background: "#228F70"
      }
    },
    laterBtn: {
      padding: "6px 28px",
      rounded: "10px",
      background: "none",
      color: "gray",
      border: "none",
      cursor: "pointer",
    },
  }));

  const classes = useStyles();

  return (
    <Modal
    open={notificationReqPopup}
    onClose={()=>setNotificationReqPopup(false)}>
    <Box className={classes.popup}>
      <div className={classes.bellWrapper}>
        <i
          style={{ color: "white", fontSize: "24px" }}
          className="fa fa-bell"
        ></i>
      </div>
      <div>
        <p className={classes.title}>
          We'd like to notify you about the latest updates.
        </p>
        <div className={classes.btnGroup}>
          <button className={classes.allowBtn} onClick={() => {takeNotificationPermission(onGrant, onDenied);}}>
            Allow
          </button>
          <button className={classes.laterBtn} onClick={onLater}>
            Later
          </button>
        </div>
      </div>
      </Box>
    </Modal>
  );
};

export default NotificationRequestPopup;
