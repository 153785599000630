import { Avatar, Box, CircularProgress, Container, Grid, } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TeacherReferralProfile } from "../redux/actions/AuthAction";
import CopyToClipboard from "react-copy-to-clipboard";
import gpay from '../assets/gplay.png'
import apple from '../assets/apple.png'
import fb from '../assets/fb.png'
import instagram from '../assets/instagram.png'
import linkedin from '../assets/linkedin.png'
import twitter from '../assets/twitter.png'
import clapingoLogo from '../assets/fulllogo.png'
import { useSnackbar } from "react-simple-snackbar";

let regionNames = new Intl.DisplayNames(['en'], {type: 'region'});

const ReferralProfile = () => {

  let { id } = useParams();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [copied, setCopied] = useState(false);
  const [teacherData, setTeacherData] = useState(null);

  useEffect(() => {
    const onSuccess = (data) => {
      console.log(data)
      setTeacherData(data);
    };
    const onError = (err) => {
      alert(err);
    };
    TeacherReferralProfile(id, onSuccess, onError);
  }, []);

  return (

    <>
      <div className="referral-navbar">
        <img src={clapingoLogo} alt="clapingo logo" />
      </div>

      <div className="referral-title">Spread the word and <span style={{ color: "#7591E8" }}>earn cashback</span></div>
      <div className="grid-container">
        {teacherData ? (
        <Grid container spacing={2}>
          <Grid item   >
            <div>
              <Container maxWidth="sm" className="referral-profile">

                  <div>

                    <Box className="profile">
                      <Avatar
                        alt="Remy Sharp"
                        src={`https://images.weserv.nl/?url=${teacherData.image}&w=150&h=150&output=webp`}
                        className="profileImage"
                      />
                      <Box className="center">
                        <div>
                        <Box className="counts">
                          <h1 className="teacher-name">{teacherData.name}</h1>
                          <p className="star">{teacherData.rating} <i className="fas fa-star"></i></p>
                        </Box>
                        <Box className="counts">
                          <h5>Country</h5>
                          <p>{regionNames.of(teacherData.country ? teacherData.country : "IN")}</p>
                        </Box>    
                        <Box className="counts">
                          <h5>No. of sessions taken</h5>
                          <p>{teacherData.sessionCount}</p>
                        </Box>

                        </div>
                      </Box>
                    </Box>

                  
                    <Box className="about">
                      <h3>About :</h3>
                      <p>{teacherData.description}</p>
                    </Box>

                 
                    <Box className="refer">
                      <div className="install-app">
                        <h3>Install App:</h3>
                        <div className="download-btn">
                          <a href="https://play.google.com/store/apps/details?id=com.app.clapingo&hl=en_IN&gl=US" alt="play store">
                            <img src={gpay} alt="play store" />
                          </a>
                          <a href="https://apps.apple.com/in/app/clapingo/id1595487516" alt="apple store">
                            <img src={apple} alt="apple store" />
                          </a>
                        </div>
                      </div>

                    </Box>
                  </div>

              </Container>
            </div>
          </Grid>
          <Grid item  style={{ display: "flex", alignItems: "center" }}>
            <Container className="referral-profile referral-details">
                  <div>
                    <Box className="refer">
                      <p><strong>{teacherData.name}</strong> has referred you to install Clapingo. Copy the code and get <strong>{teacherData.teacherRefferalToBonus}%</strong> discount in any subscription plan.</p>
                      <div className="referral-code">
                        <input value={teacherData.referral} disabled />
                        <CopyToClipboard
                          text={teacherData.referral}
                          onCopy={() => {
                            openSnackbar("Referral code copied successfully");
                            setCopied(true);
                          }}
                        >
                          <button>Copy</button>
                        </CopyToClipboard>
                      </div>

                      <div className="follow-us">
                        <h3>Follow us on :</h3>
                        <div className="share-btn">
                          <a href="https://www.facebook.com/clapingo/" alt="Facebook">
                            <img src={fb} alt="fb share" />
                          </a>
                          <a href="https://twitter.com/clapingo2020" alt="Twitter">
                            <img src={twitter} alt="twitter" />
                          </a>
                          <a href="https://www.instagram.com/clapingo/" alt="Instagram">
                            <img src={instagram} alt="instagram" />
                          </a>
                          <a href="https://www.linkedin.com/company/clapingo/?originalSubdomain=in" alt="Linkedin">
                            <img src={linkedin} alt="linkedin" />
                          </a>
                        </div>
                      </div>

                    </Box>
                  </div>
            </Container>
          </Grid>
        </Grid>
        ) : (
          <Box style={{ marginLeft: "50%" }}>
            <CircularProgress />
          </Box>
        )}
      </div>


    </>

  );
};

export default ReferralProfile;
