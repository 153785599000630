import moment from "moment";
import "moment-timezone";
import {getTimezone} from 'countries-and-timezones';
const userTimeZone = moment.tz.guess(true)
const zoneDetails = getTimezone(userTimeZone)

if(userTimeZone){
  const locationdata = {
	  //
    country: zoneDetails.countries[0],
    timezone: zoneDetails.name,
  };
  
  localStorage.setItem("TIMEZONE", JSON.stringify(locationdata));
  }

const time_zone = JSON.parse(localStorage.getItem("TIMEZONE"));
if (time_zone) {
  moment.tz.setDefault(time_zone.timezone);
}

export const ScheduleDate = (i) => {
  const date = new Date();

  var week = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  var month = date.getMonth();
  var day = date.getDate();
  var year = date.getFullYear();

  // console.log(`${year+'-'+day+'-'+month}`)

  var date2 = new Date(year, month, day + i);

  return `${
    date2.getMonth() +
    1 +
    "/" +
    date2.getDate() +
    "(" +
    week[date2.getDay()] +
    ")"
  }`;
};

export const FilterTimeSlot = (data) =>{
  var timing = {
    Sunday: [],
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
  };

  data.map((val) => {
 
    if (val.startTime !== "N/A" && val.endTime !== "N/A") {
      moment.tz.setDefault("UTC");
      var time1 = moment(val.startTime, "HH:mm").tz("UTC").toDate();
      var startTime = moment(time1).tz(time_zone.timezone).format("HH:mm");
      var time2 = moment(val.endTime, "HH:mm").tz("UTC").toDate();
      var endTime = moment(time2).tz(time_zone.timezone).format("HH:mm");
      var localDay = convertDay(val.startTime + " " + val.day,time_zone.timezone)
      if (localDay === "Sunday") {
        timing.Sunday.push(splited(startTime) + " - " + splited(endTime));
      } else if (localDay === "Monday") {
        timing.Monday.push(splited(startTime) + " - " + splited(endTime));
      } else if (localDay === "Tuesday") {
        timing.Tuesday.push(splited(startTime) + " - " + splited(endTime));
      } else if (localDay === "Wednesday") {
        timing.Wednesday.push(splited(startTime) + " - " + splited(endTime));
      } else if (localDay === "Thursday") {
        timing.Thursday.push(splited(startTime) + " - " + splited(endTime));
      } else if (localDay === "Friday") {
        timing.Friday.push(splited(startTime) + " - " + splited(endTime));
      } else if (localDay === "Saturday") {
        timing.Saturday.push(splited(startTime) + " - " + splited(endTime));
      }
    }
  });

  return timing;

}

export const FilterTimeSlotWithoutSplit = (data) =>{
  var timing = {
    Sunday: [],
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
  };

  data.map((val) => {
 
    if (val.startTime !== "N/A" && val.endTime !== "N/A") {
      moment.tz.setDefault("UTC");
      var time1 = moment(val.startTime, "HH:mm").tz("UTC").toDate();
      var startTime = moment(time1).tz(time_zone.timezone).format("HH:mm");
      var time2 = moment(val.endTime, "HH:mm").tz("UTC").toDate();
      var endTime = moment(time2).tz(time_zone.timezone).format("HH:mm");
      var localDay = convertDay(val.startTime + " " + val.day,time_zone.timezone)
      if (localDay === "Sunday") {
        timing.Sunday.push((startTime) + " - " + (endTime));
      } else if (localDay === "Monday") {
        timing.Monday.push((startTime) + " - " + (endTime));
      } else if (localDay === "Tuesday") {
        timing.Tuesday.push((startTime) + " - " + (endTime));
      } else if (localDay === "Wednesday") {
        timing.Wednesday.push((startTime) + " - " + (endTime));
      } else if (localDay === "Thursday") {
        timing.Thursday.push((startTime) + " - " + (endTime));
      } else if (localDay === "Friday") {
        timing.Friday.push((startTime) + " - " + (endTime));
      } else if (localDay === "Saturday") {
        timing.Saturday.push((startTime) + " - " + (endTime));
      }
    }
  });

  return timing;

}

export const headerDate = () => {
  const date = new Date();
  console.log(date);
  var week = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  var month = date.getMonth();
  var day = date.getDate();
  var year = date.getFullYear();

  var date2 = new Date(year, month, day + 7);

  var month2 = date2.getMonth();
  var day2 = date2.getDate();
  var year2 = date2.getFullYear();

  return (
    year +
    "/" +
    month +
    1 +
    "/" +
    day +
    "(" +
    week[date.getDay()] +
    ")" +
    "-" +
    year2 +
    "/" +
    month2 +
    1 +
    "/" +
    day2 +
    "(" +
    week[date2.getDay()] +
    ")"
  );
};

/**
 * Take UTC time & covert it asper timezone 24hr AM/PM format
 * @param {str} sessiontime 
 * @returns str
 */

export const formatAMPM = (sessiontime) => {
  moment.tz.setDefault("UTC");
  var time = moment(sessiontime, "HH:mm").toDate();
  var convertedTime = moment(time).tz(time_zone.timezone).format("hh:mm a");

  return convertedTime;
};

/**
 * Take UTC time & covert it asper timezone 12hr format
 * @param {str} time 
 * @returns str
 */

export const formatTimeAMPM = (time) => {
  moment.tz.setDefault("UTC");
  var time = moment(time, "HH:mm").toDate();
  var convertedTime = moment(time).tz(time_zone.timezone).format("h:mm A");
  return convertedTime;
};


export const formatAMPMLocal = (date) => {
  var time = date.split(":", 2);
  var hours = time[0];
  var minutes = time[1];

  var ampm = hours >= 12 ? "PM" : "AM";
  var strTime = hours + ":" + minutes + " " + ampm;

  return strTime;
};

export const utcToLocalDateConverter = (date,endTime) => {
  //set agenda schedule as per user local time
  moment.tz.setDefault('UTC');
  let datetimeA = moment(date + " " + endTime,"YYYY-MM-DD HH:mm").toDate();
  var timeLocal =moment(datetimeA).tz(time_zone.timezone).format('YYYY-MM-DD')
  return timeLocal
};


export const utcToLocalDateConverterHHmm = (date,endTime) => {
  //set agenda schedule as per user local time
  moment.tz.setDefault('UTC');
  let datetimeA = moment(date + " " + endTime,"YYYY-MM-DD HH:mm").toDate();

  var timeLocal =moment(datetimeA).tz(time_zone.timezone).format('YYYY-MM-DD HH:mm a')
  return timeLocal
};

export const utcToLocal = (date,endTime) => {
  //set agenda schedule as per user local time
  moment.tz.setDefault('UTC');
  let date_time = moment(date + " " + endTime,"YYYY-MM-DD HH:mm").toDate();
  return date_time
};

export const UTCToLocalMomentObj = (date) => {
  moment.tz.setDefault('UTC');
  let date_time = moment(date).tz(time_zone.timezone);
  return date_time
};

export const localToUTCDate = (date) => {
  //set agenda schedule as per user local time
  moment.tz.setDefault(time_zone.timezone);
  let date_time = moment(date).tz("UTC").toDate();
  return date_time
};

export const localToUTCMomentObj = (date) => {
  //set agenda schedule as per user local time
  moment.tz.setDefault(time_zone.timezone);
  let date_time = moment(date).tz("UTC");
  return date_time
};

export const localToUTC_YYYY_MM_DD = (date) => {
  //set agenda schedule as per user local time
  moment.tz.setDefault(time_zone.timezone);
  let date_time = moment(date).tz("UTC").format('YYYY-MM-DD');
  return date_time
};

export const LocalToUTC = (date, time) => {
  var datee = moment(date).format("YYYY-MM-DD");
  var dateTime = moment(datee + " " + time, "YYYY-MM-DD HH:mm").toDate();
  return dateTime;
};

export const dateFormater = (date) => {
  var today = new Date(date);
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  today = dd + "-" + mm + "-" + yyyy;
  return today;
};

export const historyDate = (date, time) => {
  const splitedTime = time.split(":");
  const sessionDate = new Date(date);
  sessionDate.setHours(splitedTime[0]);
  sessionDate.setMinutes(splitedTime[1]);

  // const data = sessionDate.getFullYear()+" , "+sessionDate.getMonth()+1+" , "+sessionDate.getDate()+" , "+sessionDate.getHours()+" , "+sessionDate.getMinutes()
  return {
    year: sessionDate.getFullYear(),
    mnth: sessionDate.getMonth() + 1,
    date: sessionDate.getDate(),
    hr: sessionDate.getHours(),
    min: sessionDate.getMinutes(),
  };
};

const convertDay = (hr,zone) =>{
  moment.tz.setDefault('UTC');
  var time =moment(hr,'HH:mm dddd').toDate();
  var day = moment(time).tz(zone).format('dddd');
  return day
}

const startEndTime1 = (hr, zone) => {
  moment.tz.setDefault(zone);
  var time = moment(hr, "HH:mm").toDate();
  var t = moment(time).tz("UTC").format("HH:mm a");
  return t.toString();
};

const splited = (time) => {
  const t = time.split(":");
  if (t[0] === "00") {
    return "00";
  } else {
    return t[0];
  }
};

export const teacherTimeArr = (data) => {
  var timing = {
    Sunday: [],
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
  };

  data.map((val) => {
 
    if (val.startTime !== "N/A" && val.endTime !== "N/A") {
      moment.tz.setDefault("UTC");
      var time1 = moment(val.startTime, "HH:mm").tz("UTC").toDate();
      var startTime = moment(time1).tz(time_zone.timezone).format("HH:mm");
      var time2 = moment(val.endTime, "HH:mm").tz("UTC").toDate();
      var endTime = moment(time2).tz(time_zone.timezone).format("HH:mm");
      var localDay = convertDay(val.startTime + " " + val.day,time_zone.timezone)
      if (localDay === "Sunday") {
        timing.Sunday.push(splited(startTime) + " - " + splited(endTime));
      } else if (localDay === "Monday") {
        timing.Monday.push(splited(startTime) + " - " + splited(endTime));
      } else if (localDay === "Tuesday") {
        timing.Tuesday.push(splited(startTime) + " - " + splited(endTime));
      } else if (localDay === "Wednesday") {
        timing.Wednesday.push(splited(startTime) + " - " + splited(endTime));
      } else if (localDay === "Thursday") {
        timing.Thursday.push(splited(startTime) + " - " + splited(endTime));
      } else if (localDay === "Friday") {
        timing.Friday.push(splited(startTime) + " - " + splited(endTime));
      } else if (localDay === "Saturday") {
        timing.Saturday.push(splited(startTime) + " - " + splited(endTime));
      }
    } else if(val.startTime === "N/A" && val.endTime === "N/A"){
      if (val.day === "Sunday") {
        timing.Sunday.push('N/A');
      } else if (val.day === "Monday") {
        timing.Monday.push('N/A');
      } else if (val.day === "Tuesday") {
        timing.Tuesday.push('N/A');
      } else if (val.day === "Wednesday") {
        timing.Wednesday.push('N/A');
      } else if (val.day === "Thursday") {
        timing.Thursday.push('N/A');
      } else if (val.day === "Friday") {
        timing.Friday.push('N/A');
      } else if (val.day === "Saturday") {
        timing.Saturday.push('N/A');
      }
    }
  });

  return timing;
};


export const currentTimeUtc = () => {
  moment.tz.setDefault(time_zone.timezone);
  var time =moment().toDate()
  var convertedTime = moment(time).tz("UTC").format('HH:mm');
  return convertedTime;
};
export const currentTimeLocal = () => {
  moment.tz.setDefault(time_zone.timezone);
  var time =moment().toDate()
  var convertedTime = moment(time).tz("UTC").format('HH:mm');
  return convertedTime;
};

/**
 * Add an item to a localStorage() object
 * @param {String} name  The localStorage() key
 * @param {String} key   The localStorage() value object key
 * @param {String} value The localStorage() value object value
 */
 export const updateLocalStorageObject = function (name, key, value) {

	// Get the existing data
	var existing = localStorage.getItem(name);

	// If no existing data, create an array
	// Otherwise, convert the localStorage string to an array
	existing = existing ? JSON.parse(existing) : {};

	// Add new data to localStorage Array
	existing[key] = value;

	// Save back to localStorage
	localStorage.setItem(name, JSON.stringify(existing));

};


const TimingSlot = (val, num) => {
  const time = val.split(":");
  const h = parseInt(time[0]);
  const m = parseInt(time[1]) + num;
  if(m === 60){
    return parseInt(h)+1+":"+"00"
  }else{
  return `${h}:${m}`;
  }
};

var makeTimeIntervals = function (startTime, endTime, increment) {
  startTime = startTime.toString().split(':');
  endTime = endTime.toString().split(':');
  increment = parseInt(increment, 10);

  var pad = function (n) { return (n < 10) ? '0' + n.toString() : n; },
      startHr = parseInt(startTime[0], 10),
      startMin = parseInt(startTime[1], 10),
      endHr = parseInt(endTime[0], 10),
      endMin = parseInt(endTime[1], 10),
      currentHr = startHr,
      currentMin = startMin,
      previous = currentHr + ':' + pad(currentMin),
      current = '',
      r = [];

  if(currentHr > endHr ){
    return r;
  }

  do {
      currentMin += increment;
      if ((currentMin % 60) === 0 || currentMin > 60) {
          currentMin = (currentMin === 60) ? 0 : currentMin - 60;
          currentHr += 1;
      }
      current = currentHr + ':' + pad(currentMin);
      r.push(previous);
      previous = current;
} while (currentHr !== endHr);

  return r;
};

export const timeSpliter = (startTime,endTime, duration) => {

  var splited1 = endTime.split(":");

 const endtime = parseInt(splited1[0])+1+":"+splited1[1]

  var arr2 = makeTimeIntervals(startTime,endtime,duration)

  var userTime = []

  if(arr2.length <= 0){
    return userTime;
  }

  if (duration === 15) {
    userTime.push({
      timeView: `${arr2[0]} - ${arr2[1]}`,
      start: `${arr2[0]}`,
      end: `${arr2[1]}`,
    });
    userTime.push({
      timeView: `${TimingSlot(arr2[1], 5)} - ${TimingSlot(arr2[2], 5)}`,
      start: `${TimingSlot(arr2[1], 5)}`,
      end: `${TimingSlot(arr2[2], 5)}`,
    });
    userTime.push({
      timeView: `${TimingSlot(arr2[2], 10)} - ${TimingSlot(arr2[3], 10)}`,
      start: `${TimingSlot(arr2[2], 10)}`,
      end: `${TimingSlot(arr2[3], 10)}`,
    });
  } else if (duration === 25) {
    userTime.push({
      timeView: `${arr2[0]} - ${arr2[1]}`,
      start: `${arr2[0]}`,
      end: `${arr2[1]}`,
    });
    userTime.push({
      timeView: `${TimingSlot(arr2[1], 5)} - ${TimingSlot(arr2[2], 5)}`,
      start: `${TimingSlot(arr2[1], 5)}`,
      end: `${TimingSlot(arr2[2], 5)}`,
    });
  }else if(duration === 25){
    userTime.push({
      timeView: `${arr2[0]} - ${arr2[1]}`,
      start: `${arr2[0]}`,
      end: `${arr2[1]}`,
    });
  } else if (duration === 55) {
    userTime.push({
      timeView: `${arr2[0]} - ${arr2[1]}`,
      start: `${arr2[0]}`,
      end: `${arr2[1]}`,
    });
  }
  return userTime;
};

export const localTimeToUTCConverter = (splitedTime) => {
  const {timezone} = JSON.parse(localStorage.getItem("TIMEZONE"));
  moment.tz.setDefault(timezone);
  var time =moment(splitedTime,"HH:mm").toDate()
  var convertedTime = moment(time).tz("UTC").format('HH:mm');
  return convertedTime;
};


/**
 * Add zero padding
 * @param {string} time 
 * @returns string
 */
 export const zeroPadding = (time) =>{
  const splitedTime = time.split(":")
  const start = String(splitedTime[0]).padStart(2, "0");
  const end = String(splitedTime[1]).padStart(2, "0");
  return `${start}:${end}`
}

export const dayToDate = (startTime,day) =>{
  const date = moment(`${"00:00"} ${day}`,'HH:mm dddd').toDate();
  console.log(date)
}

const converter = (date,timezone) =>{
  moment.tz.setDefault(timezone);
  var utcDate = moment(date).tz("UTC").format('YYYY-MM-DD');
  return utcDate
}

const converterMomentObj = (date,timezone) =>{
  moment.tz.setDefault(timezone);
  var utcDateObj = moment(date).tz("UTC");
  return utcDateObj;
}

export const sessionBookingDate = (date,time) =>{
  const {timezone} = JSON.parse(localStorage.getItem("TIMEZONE"));
  date = moment.utc(date).tz(timezone).format('YYYY-MM-DD');
  moment.tz.setDefault('UTC');
  var dateTime =moment(time,"HH:mm").toDate()
  var localTime = moment(dateTime).tz(timezone).format('HH:mm');
  return converter(`${date}T${localTime}:00`,timezone)
}

export const sessionBookingDateUTCObj = (date,time) =>{
  const {timezone} = JSON.parse(localStorage.getItem("TIMEZONE"));
  date = moment.utc(date).tz(timezone).format('YYYY-MM-DD');
  moment.tz.setDefault('UTC');
  var dateTime =moment(time,"HH:mm").toDate()
  var localTime = moment(dateTime).tz(timezone).format('HH:mm');
  return converterMomentObj(`${date}T${localTime}:00`,timezone)
}

export const availableDate = (i,bookingLag) => {

  var date = new Date();
  date.setHours(date.getHours()+ parseInt(bookingLag))
  var week = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  var month = date.getMonth();
  var day = date.getDate();
  var year = date.getFullYear();

  var date2 = new Date(year, month, day + i);

  return {
    formated: `${
      date2.getMonth() +
      1 +
      "/" +
      date2.getDate() +
      "(" +
      week[date2.getDay()] +
      ")"
    }`,
    month: moment(date2).format('MMM'),
    dateNo:date2.getDate(), 
    date: date2,
    weekDay:week[date2.getDay()]
  };
};

export const localToUTCDateFormater = (date) =>{
  const {timezone} = JSON.parse(localStorage.getItem("TIMEZONE"));
  moment.tz.setDefault(timezone);
  const t2 = moment.utc(date).tz(timezone).format('YYYY-MM-DD');
  return t2;
}

/**
 * Take local timeslot & covert it to utc
 * @param {str} sessiontime 
 * @returns str
 */
export const formatTimeslotLocalToUTC = (sessiontime) => {
  const {timezone} = JSON.parse(localStorage.getItem("TIMEZONE"));
  const splited = sessiontime.split("-")
  moment.tz.setDefault(timezone);
  var time1 =moment(splited[0],"HH:mm").toDate()
  var convertedTime1 = moment(time1).tz('UTC').format('HH:mm');
  var time2 =moment(splited[1],"HH:mm").toDate()
  var convertedTime2 = moment(time2).tz('UTC').format('HH:mm');
  return convertedTime1+'-'+convertedTime2;
};

/**
 * Take UTC timeslot & covert it asper timezone 24hr format
 * @param {str} sessiontime 
 * @returns str
 */
export const formatTimeslotUTCtoLocal = (sessiontime) => {
  const {timezone} = JSON.parse(localStorage.getItem("TIMEZONE"));
  const splited = sessiontime.split("-")
  moment.tz.setDefault('UTC');
  var time1 =moment(splited[0],"HH:mm").toDate()
  var convertedTime1 = moment(time1).tz(timezone).format('HH:mm');
  var time2 =moment(splited[1],"HH:mm").toDate()
  var convertedTime2 = moment(time2).tz(timezone).format('HH:mm');
  return convertedTime1+'-'+convertedTime2;
};

/**
 * Take UTC timeslot & covert it asper timezone 12hr format
 * @param {str} sessiontime 
 * @returns str
 */
export const formatTimeslotUTCtoLocalAMPM = (sessiontime) => {
  const {timezone} = JSON.parse(localStorage.getItem("TIMEZONE"));
  const splited = sessiontime.split("-")
  moment.tz.setDefault('UTC');
  var time1 =moment(splited[0],"HH:mm").toDate()
  var convertedTime1 = moment(time1).tz(timezone).format('h:mm A');
  var time2 =moment(splited[1],"HH:mm").toDate()
  var convertedTime2 = moment(time2).tz(timezone).format('h:mm A');
  return convertedTime1+'-'+convertedTime2;
};

export const formatTimeToUTC = (time) => {
  const {timezone} = JSON.parse(localStorage.getItem("TIMEZONE"));
  moment.tz.setDefault(timezone);
  var time =moment(time,"HH:mm").toDate()
  var convertedTimeObj = moment(time).tz('UTC');
  return convertedTimeObj;
}

export const getRemainingTime = (ISOString)=>{
  moment.tz.setDefault('UTC');
  let timeDiff = moment(ISOString).diff();
  let remainingTime = moment(timeDiff).format('HH:mm:ss');
  return remainingTime;
}

export const hideTimeSlot = (slot,buffer,timeZone,selectedDate) => {
  const date = new Date(new Date().toLocaleString("en-US", { timeZone: timeZone }));
  const splite = slot.split("-");
  var returnValue;

  if (localToUTCDateFormater(date,timeZone) === localToUTCDateFormater(selectedDate,timeZone)) {
    console.log("mylogcheck", slot, localToUTCDateFormater(date,timeZone), localToUTCDateFormater(selectedDate,timeZone), zeroPadding(`${date.getHours() + parseInt(buffer)}:${date.getMinutes()}`), zeroPadding(`${splite[0].trim()}:00`))
      if(zeroPadding(`${date.getHours() + parseInt(buffer)}:${date.getMinutes()}`) >= zeroPadding(`${splite[0].trim()}:00`)) {
        returnValue = "hidden";
      }
  }
  return returnValue;
};

export const getDevice = () => {
  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  let isMobileDevice = regexp.test(details);
  if (isMobileDevice) {
    return "M-TEACHERWEB";
  } else {
    return "TEACHERWEB";
  }
};

export {
  converter
}