import { Box, Drawer, Typography } from "@material-ui/core";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@material-ui/core";

const CustomTableCell = styled(TableCell)({
  borderRight: "1px solid #D9E0DF",
});

const BonosCriteria = ({
  openBonosDrawer,
  setOpenBonosDrawer,
  bonus,
  totalHours,
}) => {
  const bonusData = [
    {
      sessionHours: "60-75 hours",
      bonusAmount: "₹500",
    },
    {
      sessionHours: "75-100 hours",
      bonusAmount: "₹1000",
    },
    {
      sessionHours: ">100 hours",
      bonusAmount: "₹2000",
    },
  ];
  return (
    <Drawer
      sx={{ zIndex: 9 }}
      open={openBonosDrawer}
      anchor={"right"}
      onClose={() => setOpenBonosDrawer(false)}
    >
      <Box sx={{ width: 400 }}>
        <Box
          sx={{
            display: "flex",
            p: 2,
            borderBottom: "1px solid #E6EAE9",
            background: "#F2F5F4",
          }}
        >
          <KeyboardBackspaceIcon
            sx={{ cursor: "pointer", mr: 1 }}
            onClick={() => {
              setOpenBonosDrawer(false);
            }}
          />
          <Typography
            style={{
              color: "#082B28",
              fontFamily: "Inter",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "20px",
            }}
          >
            Bonos Criteria
          </Typography>
        </Box>
        <Box sx={{ m: 2, p: 4, background: "#F9FBF7" }}>
          <TableContainer>
            <Table
              aria-label="a dense table"
              style={{ border: "1px solid #D9E0DF" }}
            >
              <TableHead>
                <TableRow style={{ backgroundColor: "#F2F5F4" }}>
                  <CustomTableCell align="center">
                    Session hours
                  </CustomTableCell>
                  <TableCell align="center">Bonus amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bonusData.map((data, i) => (
                  <TableRow key={i} style={{ background: "white" }}>
                    <CustomTableCell align="center">
                      {data.sessionHours}
                    </CustomTableCell>
                    <TableCell align="center">{data.bonusAmount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              color: "#0E433A",
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "18px",
              mt: 2,
            }}
          >
            Your sessions have{" "}
            <span
              style={{
                fontStyle: "italic",
                fontWeight: 500,
              }}
            >
              {totalHours}
            </span>{" "}
            so your bonus amount is{" "}
            <span
              style={{
                fontStyle: "italic",
                fontWeight: 500,
              }}
            >
              ₹{bonus}.
            </span>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default BonosCriteria;
