import { CircularProgress } from '@material-ui/core';
import React, {useState} from 'react';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { Document, Page, pdfjs } from 'react-pdf';
function PDFViewer({url}) {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }
  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center", gap: "10px"}}>
        <div className='flex-1'>
    <Document
      file={url}
      renderMode="canvas"
      onLoadSuccess={onDocumentLoadSuccess}
      noData={<h1>No PDF found</h1>}
      error={<h1 style={{
  fontWeight: 'bold',
  color: 'red',
  textAlign: 'center'
}}>Failed to load PDF.</h1>}
      loading={<CircularProgress />}
      className="pdfdoc"
    >
      <Page pageNumber={currentPage} renderTextLayer={false} renderAnnotationLayer={false} />
    </Document>
    </div>
    {numPages ? <div className="pdf_control_box" >
    <button className="pdf_control_box_btn left"
          disabled={currentPage === 1}
          onClick={() => {
            currentPage <= 1
              ? setCurrentPage(1)
              : setCurrentPage(currentPage - 1);
          }}
        >
          <ChevronLeft style={{display: "flex", margin: "0 auto"}}/>
        </button>
        <div>{currentPage} of {numPages}</div>
        <button className="pdf_control_box_btn right"
          disabled={currentPage === numPages}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          <ChevronRight style={{display: "flex", margin: "0 auto"}} />
        </button>
        </div> : null}
    </div>
  );
}

export default PDFViewer;
