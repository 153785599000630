import { initializeApp } from "firebase/app";
import { getMessaging, getToken, deleteToken, isSupported } from "firebase/messaging";
import { SaveFCMTokenInDB } from "../redux/actions/AuthAction";

const config = {
    apiKey: "AIzaSyChVeleZgFZhAbj9uVL_WgeJ-UUVSXdKUQ",
    authDomain: "clapingo.firebaseapp.com",
    databaseURL: "https://clapingo.firebaseio.com",
    projectId: "clapingo",
    storageBucket: "clapingo.appspot.com",
    messagingSenderId: "588700685831",
    appId: "1:588700685831:web:b21e357ea1e25141febca2"
};

export const app = initializeApp(config);

let messaging = {};

export const firebaseCloudMessaging = {
  init: async () => {
    try {
      console.log("firebase running init");
      messaging = getMessaging(app);
      console.log("mylogmsg", messaging);
      
      const tokenInLocalStorage = JSON.parse(localStorage.getItem("TEACHER")).webNotificationId;
      
      console.log("tokeninSession", tokenInLocalStorage);

      // Return the token if it is alredy in our session storage
      // if (tokenInLocalStorage !== null) {
      //   return {
      //     new: false,
      //     fcm_token: tokenInLocalStorage
      //   };
      // }

      const status = Notification.permission;
      console.log("firebase status", status);
      
      if (status && status === "granted") {
          // Get new token from Firebase
          console.log("inside granted if");
        const fcm_token = await getToken(messaging, {
          vapidKey: 'BILFa1n1JNvEfnFOIGgWVeRHFGoloP399grPf14cAK3jDBOWwhAhB02_Y3WW8RzWzTiV9_YRjoAVzYr-IAVFt1E',
        });
        console.log("token: ", fcm_token);

        // Set token in our Session storage
        if (fcm_token) {
          console.log("firebase", fcm_token, "gen", tokenInLocalStorage)
          if(tokenInLocalStorage!==fcm_token){ 
            console.log('firebase: New token generated !');
            // alert('New token generated !');
          }
          return {
            new: tokenInLocalStorage!==fcm_token ? true : false,
            fcm_token
          };
        }
        else throw new Error("no token generated");;
      }
    } catch (error) {
      throw new Error(`firebase error: ${error}`);
    }
  },
  deleteToken: async () => {
    try {
      await deleteToken(messaging);
    } catch (error) {
      throw new Error(`firebase error in delete token: ${error}`);
    }
  }
};

export const setToken = async (attempts=0) => {
  const isFBSupported = await isSupported();
  console.log("mylogsupport", isFBSupported);

  if(isFBSupported){
    try {
      const token = await firebaseCloudMessaging.init();
      if (token) {
        console.log("firebase token", token);
        if(token.new) SaveFCMTokenInDB(token.fcm_token);
      }
    } catch (error) {
      console.log(error);
      if(attempts<3){
        attempts++;
        setTimeout(async () => {
          await setToken(attempts);
      }, 1000);
      }
    }
  } else return;  
}

export const deleteFCMToken = async (fn) => {
  const isFBSupported = await isSupported();
  console.log("mylogsupport", isFBSupported);

  if(isFBSupported){
    try {
      await firebaseCloudMessaging.deleteToken();
    } catch (error) {
      console.error(error);
    } finally {
      fn();
    }
  } else return;  
}


// export const onMessageListener = () => {
//   const messaging = getMessaging(app);
//   return new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       resolve(payload);
//     });
//   });
// };
