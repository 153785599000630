import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { GetHomeworkSolution } from "../redux/actions/SessionAction";
import { useDispatch, useSelector } from "react-redux";
import { Player } from "video-react";
import { Document, Page, pdfjs } from "react-pdf";
import "../../node_modules/video-react/dist/video-react.css";
import { makeStyles } from "@material-ui/core/styles";

import {
  Container,
  Paper,
  Box,
  Typography,
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CardContent,
  CardMedia,
  Button,
  Divider,
  Grid,
  CircularProgress,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.grey[300],
    paddingTop: theme.spacing(3),
  },
  title: {
    fontfamily: "Poppins",
    fontstyle: "normal",
    fontweight: "bold",
    fontsize: "48px",
    lineheight: "70px",
    /* or 146% */
    textDecoration: "underline",

    color: "#4DC591",
  },
  subtitle: {
    marginTop: "2%",
    marginBottom: "1%",
  },
  videofile: {
    width: "80%",
    height: "80%",
    border: "2px solid #4DC591",
    padding: "10px",
    borderRadius: "25px",
  },
  images: {
    borderRadius: "25px",
    border: "2px solid #4DC591",
    padding: "10px",
  },
  documentviewer: {
    width: "100% !important",
    height: "100% !important",
    objectfit: "cover",
    borderRadius: "12px !important",
    border: "1px solid $input-border",
  },
}));

const HomeworkPreview = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const classes = useStyles();
  const dispatch = useDispatch();
  const [homework, setHomework] = useState("");
  const [notes, setNotes] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [solutionNumPages, setSolutionNumPages] = useState(null);
  const [solutionPageNumber, setSolutionPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onDocumentLoadSuccessSolution({ numPages }) {
    setSolutionNumPages(numPages);
  }

  const myArr = notes.split("url=")[1];
  useEffect(() => {
    var data = {
      sessionId: props.location.state._id,
    };
    var onSuccess = (responses) => {
      setHomework(responses.submissionLinks);

      setNotes(responses.homeworkNoteLink);
    };
    var onError = (error) => {
      console.log("error");
    };
    dispatch(GetHomeworkSolution(data, onSuccess, onError));
  }, []);

  return (
    <Container className={classes.root} style={{ overflow: "hidden" }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h1 className={classes.title}>Homework</h1>
          <div className={classes.pdffile}>
            <Document
              file={myArr}
              onLoadSuccess={onDocumentLoadSuccess}
              className={classes.documentviewer}
            >
              <Page pageNumber={pageNumber} />
            </Document>

            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: "2rem" }}
                onClick={() => {
                  if (pageNumber > 1) setPageNumber(pageNumber - 1);
                }}
              >
                <i className="fas fa-caret-left"></i>
              </Button>
              <p className="my-auto">
                {pageNumber}/{numPages}
              </p>
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: "2rem" }}
                onClick={() => {
                  if (pageNumber < numPages) setPageNumber(pageNumber + 1);
                }}
              >
                <i className="fas fa-caret-right"></i>
              </Button>
            </Box>

            {/* <p>Page {pageNumber} of {numPages}</p> */}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <h1 className={classes.title}>Solution Submissions</h1>
            {homework?.length > 0
              ? homework?.map((data, i) => (
                  <div>
                    <Box>
                      {/* <img src={data} /> */}
                      {data.indexOf(".pdf") != -1 ? (
                        <div>
                          <h3 className={classes.subtitle}>PDF submission</h3>
                          <div>
                            <Document
                              file={data}
                              onLoadSuccess={onDocumentLoadSuccessSolution}
                              className={classes.documentviewer}
                            >
                              <Page pageNumber={solutionPageNumber} />
                            </Document>
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: "2rem",
                              }}
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ marginRight: "2rem" }}
                                onClick={() => {
                                  if (solutionPageNumber > 1)
                                    setSolutionPageNumber(
                                      solutionPageNumber - 1
                                    );
                                }}
                              >
                                <i className="fas fa-caret-left"></i>
                              </Button>
                              <p className="my-auto">
                                {solutionPageNumber}/{solutionNumPages}
                              </p>
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ marginLeft: "2rem" }}
                                onClick={() => {
                                  if (solutionPageNumber < solutionNumPages)
                                    setSolutionPageNumber(solutionPageNumber + 1);
                                }}
                              >
                                <i className="fas fa-caret-right"></i>
                              </Button>
                            </Box>
                          </div>
                        </div>
                      ) : data.indexOf(".mp4") != -1 ? (
                        <div>
                          <h3 className={classes.subtitle}>Video submission</h3>
                          <div className={classes.videofile}>
                            <Player src={data} />
                            {console.log("its video")}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <h3 className={classes.subtitle}>Image submission</h3>
                          <div>
                            <img src={data} />
                          </div>
                          {console.log("Its Image")}
                        </div>
                      )}
                    </Box>
                  </div>
                ))
              : "No Homework Submitted"}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomeworkPreview;
