import React from "react";
import { useSnackbar } from "react-simple-snackbar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
// import Link from "@material-ui/core/Link";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

//api call
import { TeacherLogin } from "../redux/actions/AuthAction";
import { CircularProgress } from "@material-ui/core";
import { loginFailEvent, loginSuccessEvent } from "../helper/amplitudeHelper";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <a color="inherit" href="https://clapingo.com/">
        Clapingo
      </a>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [number, setNumber] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loder, setLoder] = React.useState(false);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const teacher = JSON.parse(localStorage.getItem("TEACHER"))

  React.useEffect(()=>{
    if(teacher){
      history.push("/");
    }
  })

  const LoginTeacher = (e) => {
    e.preventDefault();
    setLoder(true);
    // if (number.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) && !number.match(/0{5,}/)) {
      const data = {
        phoneNumber: number,
        password: password,
      };
      const onSuccess = (data) => {
        const teacher = data.teacher;
        const eventProps = {
          email: teacher.email,
          phone_number: teacher.phoneNumber,
          tutor_name: teacher.name,
          tutor_id: teacher._id
        };
        loginSuccessEvent(eventProps);
        window.location.reload()
        setLoder(false);
        openSnackbar("Login successfully");
        history.push("/");
      };
      const onError = (msg) => {
        openSnackbar(msg);
        const eventProps = {
          phone_number: number
        }
        loginFailEvent(eventProps);
        setLoder(false);
      };
      dispatch(TeacherLogin(data, onSuccess, onError));
    // } else {
    //   setLoder(false);
    //   openSnackbar("Invalid mobile number");
    // }
  };

  return (
    <>
      <Container component="main" maxWidth="xs" style={{ backgroundColor: "white" }}>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} onSubmit={(e) => LoginTeacher(e)}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="number"
              label="Mobile Number "
              name="number"
              autoComplete="number"
              autoFocus
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loder?true:false}
            >
              {loder ? <CircularProgress /> : "Sign In"}
            </Button>
            <Grid container>
              <Grid item xs={6}>
                <Link
                  to={{
                    pathname: "/verifynumber",
                    state: { Text: "Forgot Password" }
                  }}
                  variant="body2"
                >
                  Forgot password?
                </Link>
              </Grid>

              <Grid
                item
                xs={6}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Link
                  to={{
                    pathname: "/faqs",
                    state: { Text: "FAQs" }
                  }}
                  variant="body2"
                >
                  FAQs ?
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}
