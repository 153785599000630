import React, { useEffect, useState } from 'react'
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, CircularProgress, Modal, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RaiseDispute, TeacherAttendance } from '../redux/actions/SessionAction';
import moment from 'moment';
import { formatAMPM, utcToLocal, utcToLocalDateConverter } from '../helper/sessionHelper';
import { Button } from '@mui/material';
import { useSnackbar } from 'react-simple-snackbar';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import AlarmIcon from '@mui/icons-material/Alarm';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Adsense } from '@ctrl/react-adsense';

const useStyles = makeStyles({
  root: {
    padding: "1rem"
  },
  table: {
    width: '100%'
  },
});

function createData(name, sessionType, meetingId, date, time, attendance, joiningTime, sessionId, startTime, qualityIssue, disputeStatus, exceededLimit, lateJoining, penaltyDispute, qualityIssueReason) {
  return { name, sessionType, meetingId, date, time, attendance, joiningTime, sessionId, startTime, qualityIssue, disputeStatus, exceededLimit, lateJoining, penaltyDispute, qualityIssueReason };
}



const SessionAttendance = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const teacher = useSelector((state) => state.TeacherReducer.teacher);
  const today = new Date().toISOString().split('T')[0];
  const [startDate, setStartDate] = useState(moment().subtract(6, 'days').format("YYYY-MM-DD"))
  const [endDate, setEndDate] = useState(today)

  const [sessionList, setSessionList] = useState(null);
  const [rows, setRows] = useState([]);
  const [fetchSessionLoader, setFetchSessionLoader] = useState(false);
  const [open, setOpen] = React.useState("");
  const [qIssueModal, setQIssueModal] = useState("");
  const handleOpen = (id) => setOpen(id);
  const handleClose = () => setOpen("");

  // console.log("today", today)

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const options = {
    position: "top-right"
  }

  const [openSnackbar, closeSnackbar] = useSnackbar(options);

  useEffect(() => {
    if (teacher) {
      const data = {
        teacherId: teacher._id
      }
      const params = {
        startDate: startDate,
        endDate: endDate
      }

      const onSuccess = (data) => {
        setSessionList(data)
      }
      const onError = (msg) => {
        console.log(msg)
      }
      dispatch(TeacherAttendance(data, params, onSuccess, onError))
    }
  }, [teacher])


  const fetchSession = (e) => {

    e.preventDefault();
    setFetchSessionLoader(true)

    const data = {
      teacherId: teacher._id
    }
    const params = {
      startDate: startDate,
      endDate: endDate
    }

    const onSuccess = (data) => {
      setSessionList(data)
      setFetchSessionLoader(false)
    }
    const onError = (msg) => {
      console.log(msg)
      setFetchSessionLoader(false)
    }
    dispatch(TeacherAttendance(data, params, onSuccess, onError))
  }

  const checkDisputeRaiseLimit = (data) => {
    let meetingEndTime = utcToLocal(data.sessionDate, data.endTime);
    let currentTime = new Date();
    currentTime.setHours(currentTime.getHours())

    // console.log("check data", {sessionDate: data.sessionDate, endTime: data.endTime, currentTime, meetingEndTime});
    let diff = Math.round((currentTime - meetingEndTime) / 3600000);

    if (diff > 24) return true;
    else return false;
  }

  const checkLateJoining = (data) => {
    const joiningTime = data.speakerJoiningTime[0] ? data.speakerJoiningTime[0] : "N/A";
    const startTime = data.startTime;
    return moment(joiningTime, "hh:mm").isAfter(moment(startTime, "hh:mm").add(2, "minutes"));
  }

  const filterSessionList = (data) => {
    return data.filter((row) => {
      let meetingEndTime = utcToLocal(row.sessionDate, row.endTime);
      let currentTime = new Date();
      currentTime.setHours(currentTime.getHours());
      let diff = Math.round((currentTime - meetingEndTime));
      if (diff >= 0) return true;
      else return false;
    });
  }

  useEffect(() => {
    if (sessionList) {
      const filteredSessionList = filterSessionList(sessionList);
      console.log("filteredSessionList", filteredSessionList);

      const filteredRows = filteredSessionList?.map((data) => createData(
        data?.learner?.name ? data.learner.name : "N/A",
        `${data.type} ${data.forKids ? "kid session" : "session"}`,
        data.meetingNo,
        utcToLocalDateConverter(data.sessionDate,data.endTime),
        `${formatAMPM(data.startTime)} - ${formatAMPM(data.endTime)}`,
        data.speakerJoiningTime?.length > 0 ? "Present" : "Absent",
        `${data.speakerJoiningTime[0] ? formatAMPM(data.speakerJoiningTime[0]) : "N/A"}`,
        data._id,
        data.startTime,
        data.qualityIssue,
        data.penaltyDispute ? data.penaltyDispute.status : "not raised",
        checkDisputeRaiseLimit(data),
        checkLateJoining(data),
        data.penaltyDispute,
        data.qualityIssueReason
      )
      );
      setRows(filteredRows);
    }
  }, [sessionList])

  // console.log("sessionList", sessionList);


  // console.log("rows", rows, moment(rows[0].startTime, "hh:mm").add(3, "minutes").format("hh:mm"));

  const raiseDispute = (row) => {
    console.log(row);
    let reason = null;
    if (row.attendance === "Absent") {
      reason = "ATTENDANCE_PENALTY";
      console.log("absent");
    }
    else if (row.lateJoining) {
      console.log("late joining");
      reason = "LATE_JOIN_PENALTY";
    }
    else if (row.qualityIssue) {
      reason = "QUALITY_PENALTY";
    }
    const data = {
      sessionId: row.sessionId,
      disputeType: reason
    }
    console.log(data);
    const onSuccess = () => {
      openSnackbar("Dispute Raised. We will look into it shortly.", [10000]);
    }
    const onError = () => {
      openSnackbar("Can't Raised Dispute");
    }
    if (reason)
      dispatch(RaiseDispute(data, onSuccess, onError));
  }

  const handleFutureDateChange = (event) => {
    const selected = event.target.value;
    if (selected > today) {
      setEndDate(today);
    } else {
      setEndDate(selected);
    }
  };
  //comment

  return (
    <div className={classes.root}>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap'}}>
        <form style={{flex:1}} className='date-picker' onSubmit={(e) => fetchSession(e)}>
          <input type="date" value={startDate} onChange={(e) => setStartDate(moment(e.target.value).format("YYYY-MM-DD"))} />
          <p>to</p>
          <input type='date' max={today} onChange={handleFutureDateChange} value={endDate} />
          <button type='submit' className='search-btn' disabled={fetchSessionLoader} >{fetchSessionLoader ? "Loading..." : "Search"}</button>
        </form>
        {/* <div style={{height: '60px', width: '120px', flexShrink: 0}}>
          <Adsense
            client="ca-pub-3715562101512710"
            slot="JustBaat_Clapingo\/Clapingo_728x90"
            style={{width: "120px", height: "60px", display: "block"}}

            format=""

          />
      </div> */}
      </div>  
      {!sessionList ?
        <Box style={{ marginLeft: "50%" }}>
          <CircularProgress />
        </Box>
        :
        <TableContainer component={Paper} >
          <Table className={classes.table} aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Learner name</TableCell>
                <TableCell align="right">Session type</TableCell>
                <TableCell align="right">Meeting ID</TableCell>
                <TableCell align="right">Date</TableCell>
                <TableCell align="right">Time</TableCell>
                <TableCell align="right">Attendance</TableCell>
                <TableCell align="right">Joining time</TableCell>
                <TableCell align="right">Raise Dispute</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (

                <TableRow key={row.sessionId} className={row.attendance === "Absent" ? 'danger-row' : row.lateJoining ? "warning-row" : "success-row"}>

                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.sessionType}</TableCell>
                  <TableCell align="right">{row.meetingId}</TableCell>
                  <TableCell align="right">{row.date}</TableCell>
                  <TableCell align="right">{row.time}</TableCell>
                  <TableCell align="right">
                  <Box sx={{ display: "flex", alignItems: "center"}}>
                    <div style={{width: "49.3px", display: "flex"}}>{row.attendance}</div>
                    {row.qualityIssue ? <div style={{ cursor: "pointer" }}>
                          <IconButton onClick={()=>setQIssueModal(row.sessionId)} aria-label="delete" size='small' color='info'>
                             <InfoIcon  />
                          </IconButton>
                          <Modal
                            open={qIssueModal===row.sessionId}
                            onClose={()=>setQIssueModal("")}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              <Typography id="modal-modal-title" variant="h6" component="h2">
                                Quality Issue
                              </Typography>
                              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                {row.qualityIssueReason}
                              </Typography>
                            </Box>
                          </Modal>
                    </div> : null}
                  </Box>
                  </TableCell>
                  <TableCell align="right">{row.joiningTime}</TableCell>
                  <TableCell align="right">
                    <Box sx={{ flexDirection: 'column' }}>
                      <div>{(row.disputeStatus !== "REJECTED") && ((!row.exceededLimit) && (row.lateJoining || row.attendance === "Absent")) ? <Button onClick={() => { if (row.disputeStatus == "not raised") raiseDispute(row) }} className={`disputeBtn ${row.disputeStatus !== "not raised" ? "outlined" : "contained"}`}>{row.disputeStatus === "not raised" ? "raise" : row.disputeStatus}</Button> : null}</div>
                      <div style={{ cursor: "pointer" }}>{row.penaltyDispute ? row.penaltyDispute.status == "REJECTED" ?
                        <div>
                          <IconButton onClick={()=>handleOpen(row.sessionId)} aria-label="delete" size='small' color='info'>
                             <InfoIcon  /> <p style={{fontSize:"15px"}}>REJECTED</p>
                          </IconButton>
                          {/* <Button onClick={handleOpen}>REJECTED</Button> */}
                          <Modal
                            open={open===row.sessionId}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              <Typography id="modal-modal-title" variant="h6" component="h2">
                                Reason Of Dispute Rejection
                              </Typography>
                              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                {row.penaltyDispute.rejectedReason}
                              </Typography>
                            </Box>
                          </Modal>
                        </div>
                        : null : null}</div>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }

    </div>

  );
}


export default SessionAttendance