import React, { useEffect, useState } from "react";
import { SortOutlined } from "@material-ui/icons";
import {
  makeStyles,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  CircularProgress,
} from "@material-ui/core";
import {
  FilterFeedbackByRatingType,
  GetFeedbackAndRating,
} from "../redux/actions/SessionAction";
import moment from "moment";
import { formatAMPM } from "../helper/sessionHelper";
import { Adsense } from "@ctrl/react-adsense";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1rem",
  },
  table: {
    width: "100%",
  },
  filterWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 16,
  },
  filter: {
    cursor:'pointer',
    float: "right",
    border: "1px",
    boxShadow: "3px 4px 5px #dadada",
    backgroundColor: "white",
    padding: "10px",
    margin: "10px",
    position:"relative",
  },
  dropdown: {
    boxShadow: "3px 4px 5px 3px #dadada",
    backgroundColor: "white",
    width:'100px',
    position:"absolute",
  },
  posneg1:{
    borderBottom:'1px solid',
    padding:"10px 0px",
    textAlign:'center',
    cursor:'pointer',
  },
  posneg2:{
    padding:"10px 0px",
    textAlign:'center',
    cursor:'pointer',
  }
}));

function createData(feedback, rating, sessionType, date, time) {
  return { feedback, rating, sessionType, date, time };
}

const FeedbackPage = () => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [feedbackList, setFeedbackList] = useState(null);
  const [fetchFeedbackLoader, setFetchFeedbackLoader] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [ratingType, setRatingType] = useState();
  useEffect(() => {
    const onSuccess = (data) => {
      setFeedbackList(data.teacher);
    };
    const onError = (errMsg) => {
      console.log(errMsg);
    };
    GetFeedbackAndRating(
      { staDate: startDate, endDate: endDate },
      onSuccess,
      onError
    );
  }, []);

  useEffect(() => {
    if (ratingType==="Positive" || ratingType==="Negative"){
      const onSuccess = (data)=>{
        setFeedbackList(data.teacher)
      }
      const onError = (errMsg) =>{
        console.log(errMsg)
      }
      FilterFeedbackByRatingType({staDate:startDate,endDate:endDate, ratingType:ratingType},onSuccess,onError)
    }
  }, [ratingType])
  const fetchFeedback = (e) => {
    e.preventDefault();
    setFetchFeedbackLoader(true);
    const onSuccess = (data) => {
      setFeedbackList(data.teacher);
      setFetchFeedbackLoader(false);
    };
    const onError = (errMsg) => {
      setFetchFeedbackLoader(false);
    };
    GetFeedbackAndRating(
      { staDate: startDate, endDate: endDate },
      onSuccess,
      onError
    );
  };

  const rows = feedbackList?.map((data) =>
    createData(
      data?.QA ? data.QA : ["N/A"],
      data?.rating ? data.rating : 0,
      `${data.type} ${data.forKids ? "kid session" : "session"}`,
      moment(new Date(data.sessionDate)).format("YYYY-MM-DD"),
      `${formatAMPM(data.startTime)} - ${formatAMPM(data.endTime)}`
    )
  );

  return (
    <Container className={classes.root} maxWidth="xl">
      <form className="date-picker" onSubmit={(e) => fetchFeedback(e)}>
        <input
          type="date"
          value={startDate}
          onChange={(e) =>
            setStartDate(moment(e.target.value).format("YYYY-MM-DD"))
          }
        />
        <p>to</p>
        <input
          type="date"
          value={endDate}
          onChange={(e) =>
            setEndDate(moment(e.target.value).format("YYYY-MM-DD"))
          }
        />
        <button
          type="submit"
          className="search-btn"
          disabled={fetchFeedbackLoader}
        >
          {fetchFeedbackLoader ? "Loading..." : "Search"}
        </button>
      </form>

      <div className={classes.filterWrapper}>
      {/* <div style={{height: '90px', maxWidth: '728px', flex: 1}}>
        <Adsense
          client="ca-pub-3715562101512710"
          slot="JustBaat_Clapingo\/Clapingo_728x90"
          style={{width: "728px", height: "90px", display: "block"}}
          format="fluid"
        />
      </div> */}
        <div className={classes.filter} onClick={()=>{setOpenDropdown(!openDropdown)}}>
          <SortOutlined /> Filter feedback
          {
            openDropdown&&<div className={classes.dropdown}>
            <div onClick={()=>setRatingType("Positive")} className={classes.posneg1}>Positive</div>
            <div onClick={()=>setRatingType("Negative")} className={classes.posneg2}>Negative</div>
          </div>
          }
        </div>
      </div>

      {!feedbackList ? (
        <Box style={{ marginLeft: "50%" }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Feedback</TableCell>
                <TableCell align="center">Rating</TableCell>
                <TableCell align="center">Session type</TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  className={
                    row.attendance === "Absent" ? "danger-row" : "success-row"
                  }
                >
                  <TableCell align="left">
                    {row?.feedback?.map((data, i) => {
                      return <li key={i}>{data.answer}</li>;
                    })}
                  </TableCell>
                  <TableCell align="center">{row.rating}</TableCell>
                  <TableCell align="center">{row.sessionType}</TableCell>
                  <TableCell align="center">{row.date}</TableCell>
                  <TableCell align="center">{row.time}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default FeedbackPage;
