import useStars from "stars-rating-react-hooks";

const StarRating = ({
  feedbackInfo,
  setFeedbackInfo,
  data,
  subIndex,
  subData,
}) => {
  const { stars, getStarProps, getStarWrapperProps } = useStars({
    totalStars: 5,
    initialSelectedValue: 0,
    renderFull: "★",
    renderEmpty: "☆",
  });

  return (
    <div>
      <span
        {...getStarWrapperProps({
          style: {
            cursor: "pointer"
          }
        })}
      >
        {stars?.map((star, i) => (
          <span
            key={i}
            {...getStarProps(i, {
              style: {
                fontSize: "30px",
                color: "gold",
                cursor: "pointer",
              },
              onClick: (event, ratedValue) => {
                let modFeedbackInfo = JSON.parse(JSON.stringify(feedbackInfo));
                modFeedbackInfo.forEach(category=>{
                  if(category.categoryName===data.categoryName)
                  category.subCategories.forEach(subCategory=>{
                    if(subCategory.subCategoryName===subData.subCategoryName)
                    subCategory.rating = ratedValue;
                  })
                })
                setFeedbackInfo(modFeedbackInfo);
                // setFeedbackInfo({
                //   ...feedbackInfo,
                //   [data]: feedbackInfo[data].map((subData, index) => {
                //     if (index === subIndex) {
                //       return {
                //         ...subData,
                //         [Object.keys(subData)[0]]: ratedValue,
                //       };
                //     }
                //     return subData;
                //   }),
                // });
              },
            })}
          >
            {star}
          </span>
        ))}
      </span>
    </div>
  );
};

export default StarRating;
