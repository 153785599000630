import {
  Box,
  Container,
  makeStyles,
  Paper,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "react-simple-snackbar";
import { utcToLocalDateConverter, utcToLocalDateConverterHHmm } from "../helper/sessionHelper";
import { GetPayouts, WithdrawAmount } from "../redux/actions/SessionAction";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.grey[300],
    paddingTop: theme.spacing(3),
  },
  divider: {
    margin: `${theme.spacing.unit * 3}px 0`,
  },
}));

const Payouts = () => {
  const classes = useStyles();
  const teacher = JSON.parse(localStorage.getItem("TEACHER"));
  const time_zone = JSON.parse(localStorage.getItem("TIMEZONE"));
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [payouts, setPayouts] = useState(null);
  const [withdrawLoader, setWithdrawLoader] = useState(false);

  useEffect(() => {
    const onSuccess = (res) => {
      setPayouts(res.payoutsData);
    };

    const onError = (msg) => {
        openSnackbar(msg);
    };

    GetPayouts(teacher._id, onSuccess, onError);
  }, []);

// const withdrawPayoutAmont = (payoutId) =>{

//     setWithdrawLoader(payoutId)
//     const onSuccess = (res) => {
//       setWithdrawLoader(false)
//       const newPayouts = payouts.map((payout)=>{
//         if(payout._id === payoutId){
//           payout.status = "Requested"
//         }
//         return payout;
//       });
      
//       setPayouts(newPayouts)
//       openSnackbar('Amount successfully withdrawn !')
//     };
  
//     const onError = (msg) => {
//         setWithdrawLoader(false)
//         openSnackbar(msg);
//     };

//     WithdrawAmount({payoutId:payoutId},onSuccess,onError)
//   }


  return (
    <Container className={`${classes.root}`}>
      {payouts ? (
        payouts.length > 0 ? (
          
            <Paper component={Box} p={2} mx="auto">
              <h1 className="heading">Payouts</h1>
              {
                payouts.map((data, i) => (
                  <Box mt={3} className={classes.cardDiv} mx="auto" key={i}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <div className="payout-card">
                        <div className="flex payment">
                          <h5>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "INR",
                            }).format(data.approvedAmount)}
                          </h5>
                          <span className={data.status}>{data.status}</span>
                        </div>
                        <div className="flex dates">
                          <span>
                            <p>Created At:</p>
                            <p>{utcToLocalDateConverterHHmm(data.createdAt)}</p>
                          </span>
  
                          <span>
                            <p>Start date:</p>
                            <p>{utcToLocalDateConverterHHmm(data.startDate)}</p>
                          </span>
  
                          <span>
                            <p>End date:</p>
                            <p>{utcToLocalDateConverterHHmm(data.endDate)}</p>
                          </span>
                          {/* {
                            data.status === "Approved"?
                            <button onClick={()=>withdrawPayoutAmont(data._id) } disabled={withdrawLoader === data._id} >{withdrawLoader === data._id?"Transferring...":'Withdraw'}</button>
                            :""
                          } */}
                          
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
                ))
              }
            </Paper>
          
        ) : (
          <h1>Payouts not available</h1>
        )
      ) : (
        <Box style={{ marginLeft: "50%" }}>
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
};

export default Payouts;