import React,{useState,useEffect} from 'react'
import OtpInput from 'react-otp-input';
import { useSnackbar } from "react-simple-snackbar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

//api call
// import { TeacherLogin } from "../redux/actions/AuthAction";
// import Toster from "../component/Toster";
import { CircularProgress } from "@material-ui/core";
import { TeacherForgotPassword, VerifyTeacherOtp } from '../../redux/actions/AuthAction';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://clapingo.com/">
        Clapingo
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function OtpForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [otp, setOtp] = React.useState("");
  const [loder, setLoder] = React.useState(false);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [resendOTP, setResendOTP] = useState(false);
  const teacher = JSON.parse(localStorage.getItem("TEACHER"))
  const userNumber = JSON.parse(localStorage.getItem("NUMBER"))
  const time_zone = JSON.parse(localStorage.getItem("TIMEZONE"));

  const handleChange = otp => setOtp( otp );

  const reSendOtp = (e) => {

      const data = {
        phoneNumber: userNumber.toString()
      };

        const onSuccess = (res) => {
          setLoder(false);
            openSnackbar("Otp resend successfully");
        };
        const onError = (msg) => {
          setLoder(false);
          openSnackbar(msg);
        };
  
          dispatch(TeacherForgotPassword(data,onSuccess,onError))
      }
  

  const SendOtp = (e) => {
    e.preventDefault();
    setLoder(true);
   
   
      const data = {
        OTP:otp,
        notificationid:"123456789",
        timezone:time_zone.timezone,
      };
      const onSuccess = () => {
        setLoder(false);
        openSnackbar("Login successfully");
        history.push("/setpassword");
      };
      const onError = (msg) => {
        openSnackbar(msg);
        setLoder(false);
      };
      dispatch(VerifyTeacherOtp(data, onSuccess, onError));

  };

      //RESEND OTP COUNTER
      useEffect(() => {
        if (resendOTP) {
          var timeLeft = 40;
          var elem = document.getElementById("some_div");
          var timerId = setInterval(countdown, 1000);
    
          function countdown() {
            if (timeLeft === -1) {
              clearTimeout(timerId);
              setResendOTP(false);
            } else {
              elem.innerHTML = `Resend OTP (${timeLeft})`;
              timeLeft--;
            }
          }
        }
      }, [resendOTP]);

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Verify Your Otp
          </Typography>
          <form className={classes.form} onSubmit={(e) => SendOtp(e)}>
          <OtpInput
        value={otp}
        onChange={handleChange}
        shouldAutoFocus={true}
        numInputs={6}
        isInputNum={true}
        inputStyle={"otp_input"}
        focusStyle={"otp_focus"}
        separator={<span>-</span>}
      />
      <div>
      {resendOTP ? (
              <p
                className="text-danger mr-5"
                id="some_div"
                style={{ textAlign:"right",color:"red" }}
              ></p>
            ) : (
              <p
                style={{ textAlign:"right", cursor: "pointer" }}
                onClick={() => {
                  setResendOTP(true)
                  reSendOtp()
                }
                }
                className="mr-5"
              >
                Resend OTP ?
              </p>
            )}
      </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loder?true:false}
            >
              {loder ? <CircularProgress /> : "Verify OTP"}
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}

