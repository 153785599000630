import { Box, Drawer, Typography } from "@material-ui/core";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@material-ui/core";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const CustomTableCell = styled(TableCell)({
  borderRight: "1px solid #D9E0DF",
});

const ReferralCriteria = ({
  openReferralDrawer,
  setOpenReferralDrawer,
  teacherDemoConversionPercentage,
  convProjectedEarnings,
  convReferralEarnings,
  isCommissionCriteriaPassed,
  isSalesPerson,
  arpu,
  avgOfferDiscount,
  isFluencyCounsellor,
  commissionCriteria,
  bookedDemosCount,
  demoAttended
}) => {
  let referralData = [];
  if (isSalesPerson)
    referralData = [
      {
        demoConversion: "20 - 21.99",
        referralPercentage: "5",
        referralAmount: `₹${Math.round(convProjectedEarnings * (5 / 100))}`,
        startFrom: "20",
        endTo: "21.99",
      },
      {
        demoConversion: "22 - 24.99",
        referralPercentage: "7",
        referralAmount: `₹${Math.round(convProjectedEarnings * (7 / 100))}`,
        startFrom: "22",
        endTo: "24.99",
      },
      {
        demoConversion: "25 - 29.99",
        referralPercentage: "10",
        referralAmount: `₹${Math.round(convProjectedEarnings * (10 / 100))}`,
        startFrom: "25",
        endTo: "29.99",
      },
      {
        demoConversion: "30+",
        referralPercentage: "13",
        referralAmount: `₹${Math.round(convProjectedEarnings * (13 / 100))}`,
        startFrom: "30",
        endTo: "100",
      },
    ];
  else if (isFluencyCounsellor)
    referralData = [
      {
        demoConversion: "5 - 9.99",
        referralPercentage: "2",
        referralAmount: `₹${Math.round(convProjectedEarnings * (2 / 100))}`,
        startFrom: "5",
        endTo: "9.99",
      },
      {
        demoConversion: "10 - 14.99",
        referralPercentage: "4",
        referralAmount: `₹${Math.round(convProjectedEarnings * (4 / 100))}`,
        startFrom: "10",
        endTo: "14.99",
      },
      {
        demoConversion: "15 - 19.99",
        referralPercentage: "7",
        referralAmount: `₹${Math.round(convProjectedEarnings * (7 / 100))}`,
        startFrom: "15",
        endTo: "19.99",
      },
      {
        demoConversion: "20+",
        referralPercentage: "10",
        referralAmount: `₹${Math.round(convProjectedEarnings * (10 / 100))}`,
        startFrom: "20",
        endTo: isCommissionCriteriaPassed ? "24.99" : "100",
      },
      {
        demoConversion: "25+",
        referralPercentage: "12",
        referralAmount: `₹${Math.round(convProjectedEarnings * (12 / 100))}`,
        startFrom: isCommissionCriteriaPassed ? "25" : "101",
        endTo: isCommissionCriteriaPassed ? "100" : "101",
      },
    ];
  else
    referralData = [
      {
        demoConversion: "5 - 9.99",
        referralPercentage: "2",
        referralAmount: `₹${Math.round(convProjectedEarnings * (2 / 100))}`,
        startFrom: "5",
        endTo: "9.99",
      },
      {
        demoConversion: "10 - 14.99",
        referralPercentage: "4",
        referralAmount: `₹${Math.round(convProjectedEarnings * (4 / 100))}`,
        startFrom: "10",
        endTo: "14.99",
      },
      {
        demoConversion: "15 - 19.99",
        referralPercentage: "7",
        referralAmount: `₹${Math.round(convProjectedEarnings * (7 / 100))}`,
        startFrom: "15",
        endTo: "19.99",
      },
      {
        demoConversion: "20+",
        referralPercentage: "10",
        referralAmount: `₹${Math.round(convProjectedEarnings * (10 / 100))}`,
        startFrom: "20",
        endTo: "100",
      },
    ];

  //   const referralAmount = referralData.find(
  //     (data) =>
  //       teacherDemoConversionPercentage >= data.startFrom &&
  //       teacherDemoConversionPercentage <= data.endTo
  //   )?.referralAmount;

  return (
    <Drawer
      sx={{ zIndex: 9 }}
      open={openReferralDrawer}
      anchor={"right"}
      onClose={() => setOpenReferralDrawer(false)}
    >
      <Box sx={{ width: "50vw" }}>
        <Box
          sx={{
            display: "flex",
            p: 2,
            borderBottom: "1px solid #E6EAE9",
            background: "#F2F5F4",
          }}
        >
          <KeyboardBackspaceIcon
            sx={{ cursor: "pointer", mr: 1 }}
            onClick={() => {
              setOpenReferralDrawer(false);
            }}
          />
          <Typography
            style={{
              color: "#082B28",
              fontFamily: "Inter",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "20px",
            }}
          >
            Referral Criteria
          </Typography>
        </Box>
        <Box sx={{ m: 2, mt: 0, p: 4, background: "#F9FBF7" }}>
          {isSalesPerson || isFluencyCounsellor ? (
            <div style={{ marginBottom: "10px", display: "flex", gap: "10px" }}>
              <div>
                <span style={{ fontWeight: 600 }}>Total Revenue:</span>{" "}
                {Math.round(convProjectedEarnings)}
              </div>
              <div>
                <span style={{ fontWeight: 600 }}>Conversion:</span>{" "}
                {Math.round(teacherDemoConversionPercentage)}%
              </div>
            </div>
          ) : null}
          <TableContainer>
            <Table
              aria-label="a dense table"
              style={{ border: "1px solid #D9E0DF" }}
            >
              <TableHead>
                <TableRow style={{ backgroundColor: "#F2F5F4" }}>
                  <CustomTableCell align="center">
                    Demo Conversion(%)
                  </CustomTableCell>
                  <CustomTableCell align="center">Referral(%)</CustomTableCell>
                  <TableCell align="center">
                    Projected Referral Amount
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {referralData.map((data, i) => (
                  <TableRow
                    key={i}
                    style={{
                      background:
                        teacherDemoConversionPercentage >= data.startFrom &&
                        teacherDemoConversionPercentage <= data.endTo
                          ? "#F1FCF8"
                          : "white",
                    }}
                  >
                    <CustomTableCell align="center">
                      {data.demoConversion}
                    </CustomTableCell>
                    <CustomTableCell align="center">
                      {data.referralPercentage}
                    </CustomTableCell>
                    <TableCell align="center">
                      {teacherDemoConversionPercentage >= data.startFrom &&
                      teacherDemoConversionPercentage <= data.endTo
                        ? `₹${convReferralEarnings}`
                        : data.referralAmount}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!isCommissionCriteriaPassed && isSalesPerson ? (
            <Box
              sx={{
                color: "#EF4444",
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "18px",
                mt: 2,
              }}
            >
              You are not eligible to get the referral amount.
            </Box>
          ) : !isCommissionCriteriaPassed && isFluencyCounsellor ? (
            <Box
              sx={{
                color: "#EF4444",
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "18px",
                mt: 2,
              }}
            >
              You are not eligible for the 25% conversion slab.
            </Box>
          ) : (
            <Box
              sx={{
                color: "#0E433A",
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "18px",
                mt: 2,
              }}
            >
              Your demo conversion is{" "}
              <span
                style={{
                  fontStyle: "italic",
                  fontWeight: 500,
                }}
              >
                {teacherDemoConversionPercentage}%
              </span>{" "}
              so your referral amount is{" "}
              <span
                style={{
                  fontStyle: "italic",
                  fontWeight: 500,
                }}
              >
                {`₹${convReferralEarnings}`}.
              </span>
            </Box>
          )}

          {isSalesPerson || isFluencyCounsellor ? (
            <>
              <Box
                sx={{
                  color: "#082B28",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20px",
                  mt: 2,
                }}
              >
                Criteria to become eligible for{" "}
                {isSalesPerson ? "referral" : "25% conversion slab"}
              </Box>
              <Box
                sx={{
                  color: "#082B28",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "20px",
                  mt: 2,
                }}
              >
                {/* <div style={{display: 'flex', gap: 5}}><div>Min. Avg. Arpu of 8500</div></div>
          <div style={{display: 'flex', gap: 5}}><div>Max. Avg. discount value of 25% </div></div>
          <div style={{display: 'flex', gap: 5}}><div>Min. 360 demos per month</div></div> */}

                <TableContainer>
                  <Table
                    aria-label="a dense table"
                    style={{ border: "1px solid #D9E0DF" }}
                  >
                    <TableHead>
                      <TableRow style={{ backgroundColor: "#F2F5F4" }}>
                        <CustomTableCell align="center">
                          Criteria
                        </CustomTableCell>
                        <CustomTableCell align="center">
                          Current Value
                        </CustomTableCell>
                        <TableCell align="center">Criteria Passed</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {commissionCriteria.minArpu ? (
                        <TableRow
                          style={{
                            background: "white",
                          }}
                        >
                          <CustomTableCell align="center">
                            Min. Avg. Arpu of ₹{commissionCriteria.minArpu}
                          </CustomTableCell>
                          <CustomTableCell align="center">
                            ₹ {Math.round(arpu)}
                          </CustomTableCell>
                          <TableCell align="center">
                            {Math.round(arpu) >= commissionCriteria.minArpu ? (
                              <CheckIcon sx={{ color: "#3EA175" }} />
                            ) : (
                              <CloseIcon sx={{ color: "#EF4444" }} />
                            )}
                          </TableCell>
                        </TableRow>
                      ) : null}
                      {commissionCriteria.maxDiscountPerc ? (
                        <TableRow
                          style={{
                            background: "white",
                          }}
                        >
                          <CustomTableCell align="center">
                            Max. Avg. discount value of{" "}
                            {commissionCriteria.maxDiscountPerc}%
                          </CustomTableCell>
                          <CustomTableCell align="center">
                            {Math.round(avgOfferDiscount)} %
                          </CustomTableCell>
                          <TableCell align="center">
                            {Math.round(avgOfferDiscount) <=
                            commissionCriteria.maxDiscountPerc ? (
                              <CheckIcon sx={{ color: "#3EA175" }} />
                            ) : (
                              <CloseIcon sx={{ color: "#EF4444" }} />
                            )}
                          </TableCell>
                        </TableRow>
                      ) : null}

                      <TableRow
                        style={{
                          background: "white",
                        }}
                      >
                        <CustomTableCell align="center">
                          Min. {commissionCriteria.minDemosPerMonth} demos per
                          month
                        </CustomTableCell>
                        <CustomTableCell align="center">
                          {isFluencyCounsellor? bookedDemosCount :demoAttended}
                        </CustomTableCell>
                        <TableCell align="center">
                          {(isFluencyCounsellor? bookedDemosCount :demoAttended) >=
                          commissionCriteria.minDemosPerMonth ? (
                            <CheckIcon sx={{ color: "#3EA175" }} />
                          ) : (
                            <CloseIcon sx={{ color: "#EF4444" }} />
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </>
          ) : null}
        </Box>
      </Box>
    </Drawer>
  );
};

export default ReferralCriteria;
