

import { Box, Button, Container, CircularProgress,Grid, Card } from "@material-ui/core";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0
  },
  card: {
    position: "relative",
    maxWidth: '95%',
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
  }
}))


const PdfViewerPage = () => {

  const classes = useStyles();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  const documentLink = query.get("doc_url");

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Container
      className={classes.root}
    >
      
      <Card className={classes.card}>
        <Document
          file={documentLink}
          //"https://clapngobucket.s3.amazonaws.com/documents/learner/Demo+Session+Document_compressed.pdf"
          onLoadSuccess={onDocumentLoadSuccess}
          renderAnnotationLayer={true}
          loading={<div className="m-5">
            <Grid container spacing={3} style={{padding:"10rem"}}>
              <Box mx="auto">
                <CircularProgress />
              </Box>
            </Grid>
          </div>}
          noData={<h1>No data</h1>}
          error={<h1>Failed to load PDF file.</h1>}
          onLoadError={<h1>Not able to load</h1>}
          pageIndex={pageNumber}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <Box
          style={{
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center'
          }}>
          <Button
            style={{
              margin: "0.7rem",
              padding: "0.5rem"
            }}
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => {
              if (pageNumber > 1) setPageNumber(pageNumber - 1);
            }}
          >
            <i className="fas fa-arrow-left"></i>
          </Button>
          <p style={{ textAlign: "center" }}>
            Page No<br />
            {pageNumber}/{numPages}
          </p>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            style={{
              margin: "0.7rem",
              padding: "0.5rem"
            }}
            onClick={() => {
              if (pageNumber < numPages) setPageNumber(pageNumber + 1);
            }}
          >
            <i className="fas fa-arrow-right"></i>
          </Button>
        </Box>

      </Card>

    </Container>
  );
};

export default PdfViewerPage
