import { Backdrop, Box, Fade, Modal, Typography } from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles';
import React from 'react'
import PDFViewer from './PdfViewer';

const FileModal = ({isOpen, handleModal, msg, type}) => {
    const useStyles = makeStyles((theme) => ({
        Box: {
            maxHeight: "500px",
            margin: "0 20px",
        }
    }));   
      
    const classes = useStyles();
  return (
    <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={isOpen}
    onClose={()=>handleModal(false)}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
    style={{zIndex: 2147483602, display: "flex", alignItems: "center", justifyContent: "center"}}
  >
    <Fade in={isOpen}>
      <Box className={classes.Box}>
      {type==="img" ? <img src={msg} alt="" width="100%" height="100%" style={{maxHeight: "inherit"}}/> : type==="pdf" ? <PDFViewer url={msg} /> : null}
      </Box>
    </Fade>
  </Modal>

  )
}

export default FileModal