import React, { useState } from 'react'
import { ListItemText } from "@mui/material";
import { formatTimeAMPM, formatTimeslotUTCtoLocalAMPM, utcToLocalDateConverter } from "../../helper/sessionHelper";
import moment from 'moment/moment';
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import FileModal from './FileModal';

const MsgBubble = ({message, handleSessionAction}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isLearner = message?.learner;
  const alignment = isLearner ? "left" : "right";
  const msg = message?.message;
  const msgTime = formatTimeAMPM(moment(message?.createdAt).format("HH:mm"));
  const isFile = message.type === "File";
  const type = isFile ? msg.split(".").pop() === "pdf" ? "pdf" : "img" : "text";

  const handleModal = (value) => {
    setIsOpen(value);
  }
  
  let formattedMsg = msg;
  if(message?.type==="Session")  {
    formattedMsg = msg?.replaceAll("\n", '<br/>');
    let sessionDate = moment(message.sessionDate).format("YYYY-MM-DD");
    let endTimeInUTC = message.sessionTime.split(" - ")[1];
    let localSessionDate = utcToLocalDateConverter(sessionDate, endTimeInUTC);
    formattedMsg = formattedMsg?.replace(/\d{4}-\d{2}-\d{2}/g, localSessionDate)
    let utcTimeSlot = message.sessionTime;
    let localTimeSlot = formatTimeslotUTCtoLocalAMPM(utcTimeSlot);
    localTimeSlot = localTimeSlot.replace("-", " - ");
    formattedMsg = formattedMsg?.replace(/\d{2}:\d{2} - \d{2}:\d{2}/g, localTimeSlot);
    if(message.status==="Requested"){
      let sessionStartTime = localTimeSlot.split(" - ")[0]
      let startTimeinUTC = message.sessionTime.split(" - ")[0];
      let acceptingTime = moment(`${sessionDate}T${startTimeinUTC}`).subtract(5, 'minutes');
      let currentTime = moment();
      if(currentTime.isAfter(acceptingTime)) message.status = "Expired";
    }

  }

  const statusColor = {
    Requested: "#4A6EE0",
    Booked: "#10B981",
    Rejected: "#EF4444",
    Expired: "#AABAB7"
  }

  return (
    <div key={message._id} className={`msg msg_${alignment}`}>
      <div className={`msg_bubble ${type==="img" ? "img_msg" : "" }`}>
          <div className="msg_bubble_data_box"  onClick={()=>{if(isFile)handleModal(true)}}>{type==="img" ? <img src={msg} alt="" className="msg_bubble_img" /> : type==="pdf" ? <PictureAsPdfIcon sx={{fontSize: 60}} /> : <ListItemText primary={<div dangerouslySetInnerHTML={{__html: formattedMsg}}></div>} className="msg_bubble_text"></ListItemText>}</div>
          {message?.type==="Session" && ((alignment==="left" && message?.status !== "Requested") || alignment==="right") ? <div style={{background: statusColor[message?.status], padding: "6px 10px", textAlign: "center", color: "white", borderRadius: "10px"}}>{message?.status}</div> : null}
          <ListItemText secondary={msgTime} className="msg_bubble_time"></ListItemText>
          {message?.type==="Session" && alignment==="left" && message?.status==="Requested"  ? 
          <div className="btn-container">
            <button
              className="book_btn"
              onClick={() => {
                handleSessionAction("Booked");
              }}
            >
              Book
            </button>

            <button
              className="reject_btn"
              onClick={()=>{
                handleSessionAction("Rejected");
              }}
            >
              Reject
            </button>
          </div> : null}
        </div>
        <FileModal isOpen={isOpen} handleModal={handleModal} msg={msg} type={type} />
    </div>
  )
}

export default MsgBubble