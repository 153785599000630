import { Box, Card, CircularProgress, Container, Typography } from '@material-ui/core'
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { localToUTC_YYYY_MM_DD } from "../helper/sessionHelper";
import { getTags, GetTeacherSlots, teachersConcern } from '../redux/actions/SessionAction';
import { Button } from '@material-ui/core';
import { Modal } from '@material-ui/core';
import { RaiseConcernTeacher} from '../redux/actions/SessionAction';
import { useSnackbar } from 'react-simple-snackbar';


const Schedule = () => {
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [teacher, setTeacher] = useState();
  const [splitedTimeArray, setSplittedTimeArray] = useState([]);
  const [bookedSessions, setBookedSessions] = useState([]);
  const [loader, setLoader] = useState(false);
  const timezone = JSON.parse(localStorage.getItem("TIMEZONE"));
  const localhostTeacher = JSON.parse(localStorage.getItem("TEACHER"));
  const [openSnackbar, closeSnackbar] = useSnackbar(); 
  const dispatch = useDispatch();
  const [description, setDescription] = React.useState("");
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [concernStatus, setConcernStatus] =React.useState("");
  const [resolveOpen, setResolveOpen] = React.useState(false);
  const [resolve, setResolveComment] = React.useState("");
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleResolveOpen = () => {
    setResolveOpen(true);
  };

  const handleResolveClose = () => {
    setResolveOpen(false);
  };


  function rand() {
    return Math.round(Math.random() * (-10));
  }
  function getModalStyle() {
    const top = 40 + rand();
    const left = 55 + rand();
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }


  const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(5),
    },
    card: {
      padding: "2rem",
    },
    slot: {
      padding: "5px",
      borderRadius: 5,
      textAlign: "center",
    },
    availableSlot: {
      border: "2px solid rgb(36, 167, 145)",
    },
    reservedSlot: {
      color: "#3C4A47",
      background: "#E6EAE9",
      border: "2px solid rgb(195, 207, 205)",
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      position: 'absolute',
      width: 450,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },

    modalHeading :{
      fontSize: '30px',
      color: '#2e4a66',
      marginBottom: '5px',
      fontFamily: "Raleway, sans-serif",
      fontWeight: "300",
      },
    
    modalInput :{
      fontFamily: "Raleway, sans-serif",
      fontWeight: "300",
      width: '100%',
      height: '150px',
      padding: theme.spacing(2,1),
      boxSizing: 'border-box',
      border: '2px solid #ccc',
      borderRadius: '4px',
      backgroundColor: '#f8f8f8',
      fontSize: '16px',
      resize: 'none',
      outline: 'none',
      letterSpacing : '2px',
    },

    modalButton : {
        padding: theme.spacing(0,1),
        border: 'none',
        borderRadius: '8px',
        background: '#00ad96a9',
        color: '#ffffff',
        height: '2.5rem',
        width: '100%',
        fontSize: '1rem',
        fontWeight: 'bold',
        alignSelf: 'center',
        cursor: 'pointer',
      
    },

    tag :{
      float: "right", 
      fontSize: "15px",
      fontWeight: "500",
      margin: theme.spacing(0.5,3,2,0),
    }

  }));

  // status check for raised concern
  useEffect(() => {
   const onSuccess = (res) => {
    setConcernStatus(res.getTeacherConcernDetails.status);
    setResolveComment(res.getTeacherConcernDetails.resolveComment);
   }
   const onError = (msg) => {
    console.log(msg);  
   }
   teachersConcern(onSuccess, onError);
    
  }, []);

  console.log("Concern status", concernStatus)

  // raiseConernByTeacher api
  const raiseConcern = (e) => {
    e.preventDefault();
    setOpen(false);
    setLoader(true)

    const data = {
      description
    }

    const onSuccess = (res) => {
      openSnackbar(res.data.message)
      console.log(res)
      setLoader(false)
    }

    const onError = (msg) => {
      setLoader(false)
      setOpen(true)
    }

    dispatch(RaiseConcernTeacher(data, onSuccess, onError))
    setDescription("") //clear textarea
  }


  const classes = useStyles();

  const handleDateChange = (e) => {
    setDate(moment(e.target.value).format("YYYY-MM-DD"));
  }

  var makeTimeIntervals = function (startTime, endTime, increment) {
    startTime = startTime.toString().split(':');
    endTime = endTime.toString().split(':');
    increment = parseInt(increment, 10);

    var pad = function (n) { return (n < 10) ? '0' + n.toString() : n; },
      startHr = parseInt(startTime[0], 10),
      startMin = parseInt(startTime[1], 10),
      endHr = parseInt(endTime[0], 10),
      endMin = parseInt(endTime[1], 10),
      currentHr = startHr,
      currentMin = startMin,
      previous = currentHr + ':' + pad(currentMin),
      current = '',
      r = [];

    if (currentHr > endHr) {
      return r;
    }

    do {
      currentMin += increment;
      if ((currentMin % 60) === 0 || currentMin > 60) {
        currentMin = (currentMin === 60) ? 0 : currentMin - 60;
        currentHr += 1;
      }
      current = currentHr + ':' + pad(currentMin);
      r.push(previous);
      previous = current;
    } while (currentHr !== endHr);

    return r;
  };

  const TimingSlot = (val, num) => {
    const time = val.split(":");
    const h = parseInt(time[0]);
    const m = parseInt(time[1]) + num;
    if (m === 60) {
      return parseInt(h) + 1 + ":" + "00"
    } else {
      return `${h}:${m}`;
    }
  };

  const timeSpliter = (startTime, endTime, duration) => {

    if (startTime == "N/A" || endTime == "N/A") {
      return ("NA")
    }
    var splited1 = endTime.split(":");

    const endtime = parseInt(splited1[0]) + 1 + ":" + splited1[1]

    var arr2 = makeTimeIntervals(startTime, endtime, duration)

    var userTime = []

    if (arr2.length <= 0) {
      return userTime;
    }

    if (duration === 15) {
      userTime.push({
        timeView: `${arr2[0]} - ${arr2[1]}`,
        start: `${arr2[0]}`,
        end: `${arr2[1]}`,
      });
      userTime.push({
        timeView: `${TimingSlot(arr2[1], 5)} - ${TimingSlot(arr2[2], 5)}`,
        start: `${TimingSlot(arr2[1], 5)}`,
        end: `${TimingSlot(arr2[2], 5)}`,
      });
      userTime.push({
        timeView: `${TimingSlot(arr2[2], 10)} - ${TimingSlot(arr2[3], 10)}`,
        start: `${TimingSlot(arr2[2], 10)}`,
        end: `${TimingSlot(arr2[3], 10)}`,
      });
    } else if (duration === 25) {
      userTime.push({
        timeView: `${arr2[0]} - ${arr2[1]}`,
        start: `${arr2[0]}`,
        end: `${arr2[1]}`,
      });
      userTime.push({
        timeView: `${TimingSlot(arr2[1], 5)} - ${TimingSlot(arr2[2], 5)}`,
        start: `${TimingSlot(arr2[1], 5)}`,
        end: `${TimingSlot(arr2[2], 5)}`,
      });
    } else if (duration === 25) {
      userTime.push({
        timeView: `${arr2[0]} - ${arr2[1]}`,
        start: `${arr2[0]}`,
        end: `${arr2[1]}`,
      });
    }
    return userTime;
  };

  useEffect(() => {
    const payload = {
      date: date,
      timezone: timezone.timezone,
      forKids: false,
      subject: "English"
    }
    const onSuccess = (res) => {
      setLoader(false);
      console.log("resdata", res);
      const teacherData = res.teachers.find((teacher) => teacher._id._id === localhostTeacher._id);
      if (teacherData) {
        const timings = teacherData.timings;
        console.log("teacherData", teacherData);
        setTeacher(teacherData);
        const combinedTime = []
        for (let i = 0; i < timings.length; i++) {
          console.log(timings[i].startTime, timings[i].endTime)
          const split = timeSpliter(timings[i].startTime, timings[i].endTime, 15)
          combinedTime.push(split);
        }
        setSplittedTimeArray(combinedTime);

        if (res.sessions?.length > 0) {
          let bookedSession = res.sessions.filter((session) => session.teacher._id == localhostTeacher._id);
          bookedSession = { [localhostTeacher._id]: bookedSession };
          console.log("BOOKED SESSIONS", bookedSession)
          setBookedSessions(bookedSession)
        }
      }
    }
    const onError = (err) => {
      console.log(err);
    }
    console.log("useEffect running");
    setLoader(true);
    setBookedSessions({});
    setTeacher(null);
    dispatch(GetTeacherSlots(onSuccess, onError, payload));
  }, [date])

  console.log("splitted", splitedTimeArray);

  const zeroPad = (num, places) => String(num).padStart(places, "0");

  const validator = (time) => {
    const splitedTime = time.split(":");
    return zeroPad(splitedTime[0], 2) + ":" + zeroPad(splitedTime[1], 2)
  };

  const validateTimeSlot = (splitedTime, availableTeacherTime) => {
    var slotArray = [];

    splitedTime.map((val1) => {
      availableTeacherTime.map((data, i) => {
        if (
          data.startTime <= validator(val1.start) &&
          validator(val1.start) <= data.endTime
        ) {
          if (
            data.startTime <= validator(val1.end) &&
            validator(val1.end) <= data.endTime
          ) {
            if (!slotArray.includes(val1.timeView)) {
              slotArray.push(val1);
            }
          } else {
            if (availableTeacherTime[i + 1]) {
              if (
                availableTeacherTime[i + 1].startTime <= validator(val1.end) &&
                validator(val1.end) <= availableTeacherTime[i + 1].endTime
              ) {
                if (!slotArray.includes(val1.timeView)) {
                  slotArray.push(val1);
                }
              }
            }
          }
        }
      });
    });

    return [...new Set(slotArray)];
  };

  const isOverlapping = (splittedStartTime, splittedEndTime, bookedSessionStartTime, bookedSessionEndTime) => {
    return splittedStartTime <= bookedSessionEndTime && bookedSessionStartTime <= splittedEndTime;
  };

  const checkSlotAvailableOrNot = (start, end, bookedSession, teacherData) => {
    console.log("book", start, end, bookedSession, bookedSession[teacherData._id], teacherData._id);

    var returnvalue;
    if (bookedSession && bookedSession[teacherData._id]) {
      // STEP-1
      bookedSession[teacherData._id].map((data) => {
        if (data.isTimeUtc) {
          //STEP-3
          if (
            isOverlapping(validator(start), validator(end), data.startTime, data.endTime)) {
            returnvalue = "Reserved";
          }
          console.log("book if", start, end, bookedSession, returnvalue, teacherData._id);

        } else {
          const startLocal = start;
          const endLocal = end;
          //STEP-3
          if (isOverlapping(startLocal, endLocal, data.startTime, data.endTime)) {
            returnvalue = "Reserved";
          }
          console.log("book else", start, end, bookedSession, returnvalue, teacherData._id);

        }
      });
    }
    return returnvalue;
  };

  const formatTimeslotUTCtoLocal = (sessiontime) => {
    const mytimezone = timezone.timezone
    const splited = sessiontime.split("-")
    moment.tz.setDefault('UTC');
    var time1 = moment(splited[0], "HH:mm").toDate()
    var convertedTime1 = moment(time1).tz(mytimezone).format('HH:mm');
    var time2 = moment(splited[1], "HH:mm").toDate()
    var convertedTime2 = moment(time2).tz(mytimezone).format('HH:mm');
    return convertedTime1 + '-' + convertedTime2;
  };

  const formatAmPmTime = (sessiontime) => {
    var splitedTime = sessiontime.split("-")
    moment.tz.setDefault('UTC');
    var convertedTime1 = moment(splitedTime[0], ["HH:mm"]).format("hh:mm A");
    var convertedTime2 = moment(splitedTime[1], ["HH:mm"]).format("hh:mm A");
    return `${convertedTime1}-${convertedTime2}`;
  };

  console.log("book", bookedSessions);
  console.log("splitted", splitedTimeArray);

  return (
    <Container className={classes.root}>
      <Card className={classes.card}>
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1 className="heading">Check Schedule</h1>
            <button className="search-btn" onClick={handleOpen}>
              Raise a concern
            </button>
          </div>
          <div style={{ display: "flex" ,float: "right" ,flexDirection: "column"}}>
          <i className={classes.tag}> *For low occupancy</i>
            <div>Status: {concernStatus}</div>
            <div style={{ cursor: "pointer" ,alignItems: "center"}}>{concernStatus == "RESOLVED" ?
            <div>
            <IconButton onClick={handleResolveOpen} size='small' color='info' style={{marginTop: "10px"}}>
                             <InfoIcon/><p style={{fontSize:"15px"}}>Resolved Comment</p>
                          </IconButton>
            <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={resolveOpen}
                            onClose={handleResolveClose}
                          >
                            <div style={modalStyle} className={classes.paper}>
                              <h2 className={classes.modalHeading}>Comment</h2>
                                            <Typography id="modal-modal-description" sx={{ mt: 4 }}>
                                              {resolve}
                                            </Typography>
                            </div>
                          </Modal>
                        </div>: null }
          <div>
            </div>
            </div>
          </div>
          <div>
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={open}
              onClose={handleClose}
            >
              <div style={modalStyle} className={classes.paper}>
                <h2 className={classes.modalHeading}>Comment</h2>
                <textarea placeholder='Enter your concern' className={classes.modalInput} id="description" value={description} required onChange={(e) => setDescription(e.target.value)}></textarea>
                {loader ? (
                <CircularProgress />): <button className={classes.modalButton}disabled={!description} onClick={raiseConcern}>Submit</button>}
              </div>
            </Modal>
          </div>
          <div style={{ display: "flex", gap: 10, alignItems: "center", margin: "16px 0 24px 0" }}>
            <p style={{ fontWeight: 600, color: "#616161" }}>Select Date : </p>
            <form className='date-picker'>
              <input type="date" value={date} onChange={handleDateChange} style={{ width: "100%", margin: 0 }} />
            </form>
          </div>

          <p style={{ fontWeight: 600, color: "#91A6A2", marginBottom: 16 }}>Timeslots : </p>


          {loader ? <Box style={{ marginLeft: '50%' }}>
            <CircularProgress />
          </Box> : !teacher ? <p>Not Available</p> : <div style={{ display: "flex", flexWrap: "wrap", gap: 20 }}>
            {splitedTimeArray.map((data, index) => {
              return (
                <div key={index} style={{ display: "flex", flexDirection: "column", gap: 16 }}>
                  {/* <h2>Name: {employee.name}</h2> */}

                  {Array.isArray(data) ?
                    data.map((splittedTimeData, subIndex) => {

                      return (
                        splittedTimeData && validateTimeSlot(splittedTimeData ? [splittedTimeData] : [], teacher.timings).length > 0 ?

                          validateTimeSlot(splittedTimeData ? [splittedTimeData] : [], teacher.timings).map((time, i) => {
                            const isReserved = checkSlotAvailableOrNot(time.start, time.end, bookedSessions, teacher._id)
                            const formatedTime = formatTimeslotUTCtoLocal(time.timeView)
                            console.log("mapping")
                            return (
                              <span
                                key={i}
                                className={`${classes.slot} ${isReserved === "Reserved" ? classes.reservedSlot : classes.availableSlot}`}
                              >
                                {formatAmPmTime(formatedTime)}
                              </span>
                            )
                          })
                          : "Not available"
                      );
                    }) : ""}
                  {/* comment */}
                  <hr />
                </div>
              );
            })}
          </div>}

        </div>
      </Card>
    </Container>
  )
}

export default Schedule