import * as constant from "../constants/TeacherConstants";

const initialState = {
  TotalPastSession: null,
  TotalUpcommingSession: null,
  teacher: null,
  pandingRequest: null,
  upcomingSession: null,
  pastSession: null,
  timing: null,
  kidsTiming: null,
  blockedLearners: null,
  unreadMessages:0,
  instantBookingRequestsCount: 0,
  instantBookingRequests: null,
  instantBookingSocket: null,
  conversionStats: null,
};

export const TeacherReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.LOAD_USER:
      return {
        ...state,
        teacher: action.payload,
      };
    //teacher panding req
    case constant.FETCH_TEACHER_PENDING_REQUEST:
      return {
        ...state,
        pandingRequest: action.payload.sessions,
      };
    //teacher upcoming session
    case (constant, constant.TEACHER_UPCOMING_SESSION):

      if (state.upcomingSession === null || action.payload.reload === "reload" ) {
        return {
          ...state,
          upcomingSession: action.payload.sessions,
          upcomingData:action.payload,
          TotalUpcommingSession: action.payload.totalSession,
        };
      } else {
        return {
          ...state,
          upcomingSession: [
            ...state.upcomingSession,
            ...action.payload.sessions,
          ],
          TotalUpcommingSession: action.payload.totalSession,
        };
      }
    //teacher past session
    case constant.TEACHER_PAST_SESSION:
      if (state.pastSession === null) {
        return {
          ...state,
          pastSession: action.payload.sessions,
          TotalPastSession: action.payload.totalSession,
        };
      } else {
        return {
          ...state,
          pastSession: [...state.pastSession, ...action.payload.sessions],
          TotalPastSession: action.payload.totalSession,
        };
      }
    //teacher past session
    case constant.TEACHER_DECLINE:
      console.log(action.payload);
      return {
        ...state,
        pandingRequest: state.pandingRequest.filter(
          (data) => data._id != action.payload
        ),
        // pastSession: action.payload.sessions
      };
    case constant.TEACHER_TIMING:
      // console.log(action.payload);
      return {
        ...state,
        timing: action.payload,
        // pastSession: action.payload.sessions
      };

    case constant.TEACHER_TIMING_KIDS:
      console.log(action.payload);
      return {
        ...state,
        kidsTiming: action.payload,
        // pastSession: action.payload.sessions
      };

    case constant.GET_BLOCK_LEARNER:
      console.log(action.payload);
      return {
        ...state,
        blockedLearners: action.payload === null ? [] : action.payload,
      };
    case "REMOVE_LEARNER_FROM_BLOCK_LIST":
      return {
        ...state,
        blockedLearners: state.blockedLearners.filter(
          (learner) => learner._id !== action.payload
        ),
      };
      case constant.CANCEL_AND_REASSIGN:
        return {
          ...state,
          upcomingSession: state.upcomingSession.filter((session) => session._id !== action.payload)
        };
      case constant.SET_UNREAD_MSG:
        return {
          ...state,
          unreadMessages: action.payload
        };
      case constant.ADD_FEEDBACK:
        // Remove updated session
        var removedSessionArr = state.pastSession.filter((session) => session._id !== action.payload.session)
        //Filter updated session
        var updatedSession = state.pastSession.filter((session) => session._id === action.payload.session)
        if(updatedSession.length > 0){
          // Add feedbackData in filtered session
          updatedSession[0].feedbackData = action.payload
          //Find index of filtered session
          const index = state.pastSession.findIndex((session) => session._id === action.payload.session);
          updatedSession.unshift(index, 0); 
          //Insert updated session
          removedSessionArr.splice.apply(removedSessionArr, updatedSession);         
        }
        return {
          ...state,
          pastSession: removedSessionArr
        };
        case constant.UPDATE_INSTANT_BOOKING:
          return {
            ...state,
            teacher: {...state.teacher,instantBooking:action.payload}
        };
        case constant.LOAD_INSTANT_BOOKING_SOCKET:
          return {
            ...state,
            instantBookingSocket: action.payload
        };
        case constant.LOAD_INSTANT_BOOKING_REQUESTS:
          let loadedRequests = action.payload.action==="load" ? 
          action.payload.requests : 
          action.payload.action==="update" ? 
          [...state.instantBookingRequests, ...action.payload.requests] : state.instantBookingRequests
          return {
            ...state,
            instantBookingRequests: loadedRequests,
            instantBookingRequestsCount: action.payload.count
        };
        case constant.UPDATE_INSTANT_BOOKING_REQUESTS:
          let newState = state.instantBookingRequests ? [action.payload, ...state.instantBookingRequests] : [action.payload];
          return {
            ...state,
            instantBookingRequests: newState,
        };
        case constant.DELETE_INSTANT_BOOKING_REQUESTS:
          let requests = state.instantBookingRequests;
          const filteredRequests = requests?.length ? requests.filter(req=>req.learner.id!==action.payload.learnerId) : [];
          return {
            ...state,
            instantBookingRequests: filteredRequests,
            instantBookingRequestsCount: action.payload.decCount ? state.instantBookingRequestsCount ? state.instantBookingRequestsCount-1 : 0 : state.instantBookingRequestsCount
        };
        case constant.UPDATE_INSTANT_BOOKING_REQUESTS_COUNT:
          return {
            ...state,
            instantBookingRequestsCount: action.payload
        };
        case constant.LOAD_CONVERSION_STATS:
          return {
            ...state,
            conversionStats: action.payload
        };
    //default
    default:
      return state;
  }
};