import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Box,
  Grid,
  CircularProgress
} from "@material-ui/core";
import PdfViewer from "../component/PdfViewer";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  GetBlocklearner,
  TecherPastSession
} from "../redux/actions/SessionAction";
import { PastSessionCard } from "../component/SessionCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { Adsense } from "@ctrl/react-adsense";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.grey[300],
    paddingTop: theme.spacing(3),
  },
  requirement: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  card: {
    position: "relative",
    maxWidth: 300,
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
  },

  ribbon: {
    backgroundColor: "#a31545",
    position: "absolute",
    color: "white",
    width: 150,
    zIndex: 3,
    textAlign: "center",
    textTransform: "uppercase",
    padding: 5,
    font: "Lato",
    "&::before": {
      position: "absolute",
      zIndex: -1,
      content: "",
      display: "block",
      border: "5px solid #2980b9",
    },
    "&::after": {
      position: "absolute",
      zIndex: -1,
      content: "",
      display: "block",
      border: "5px solid #2980b9",
    },
    transform: "rotate(-45deg)",
    top: 20,
    marginLeft: -40,
  },

  joinMeeting: {
    backgroundColor: "#e53935",
    color: "#fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#d32f2f",
    },
  },
  media: {
    paddingTop: "56.25%",
  },
  content: {
    textAlign: "left",
    padding: theme.spacing(2),
  },
  divider: {
    margin: `${theme.spacing(3)}px 0`,
  },
  heading: {
    fontWeight: "bold",
  },
  subheading: {
    lineHeight: 1.8,
  },
  cardDiv: {
    flexGrow: 1,
  },
  pagination: {
    marginTop: theme.spacing(5),
  },
}));

const PastSession = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const time_zone = JSON.parse(localStorage.getItem("TIMEZONE"));
  const [pages, setPages] = useState(2);
  const [loder, setLoder] = React.useState(null);
  const [pdfURL, setPdfUrl] = useState(null);
  const teacher = JSON.parse(localStorage.getItem("TEACHER"));
 
  const PastSessions = useSelector((state) => state.TeacherReducer.pastSession);
  useEffect(() => {
    dispatch(GetBlocklearner());
  }, []);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScrolll);
  //   return () => window.removeEventListener("scroll", handleScrolll);
  // }, []);

  // function handleScrolll() {
  //   const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
  //   if (scrollHeight - scrollTop === clientHeight) {
  //     console.log(page);
  //     setLoder(true);
  //     const data = {
  //       teacherID: teacher._id,
  //       page: page + 1,
  //       limit: 10,
  //       timezone: time_zone.timezone,
  //     };
  //     const onSuccess = (msg) => {
  //       // openSnackbar(msg);
  //       setLoder(false);
  //     };
  //     const onError = (msg) => {
  //       // openSnackbar(msg);
  //       setLoder(false);
  //     };
  //     dispatch(TecherPastSession(data, onSuccess, onError));
  //     page++;
  //   }
  // }

  const loadPastSessions = () => {
    setLoder(true)
    const data = {
      teacherID: teacher._id, page: pages, limit: 10, timezone: time_zone.timezone
    }
    const onSuccess = (msg) => {
      setLoder(false)
    };
    const onError = (msg) => {
      setLoder(false)
    };

    dispatch(TecherPastSession(data, onSuccess, onError));
    setPages(pages + 1);

  }

  if (pdfURL) {
    return <PdfViewer url={pdfURL} reset={setPdfUrl} />;
  } else {
    return (
      <div>
        <Container
          className={`${classes.root} pastsession`}
          onScroll={(e) => console.log("scroll")}
        >
        <InfiniteScroll dataLength={PastSessions ? PastSessions.length : 0}
        next={() => loadPastSessions()}
        hasMore={true}
        style={{ overflow: "hidden",width:"100%" }}
      >

          <Paper component={Box} p={2} mx="auto">
            <h1 className="heading">Past Session</h1>

            <Box mt={3} className={classes.cardDiv} mx="auto">
              <Grid container spacing={3}>
                {PastSessions ? (
                  PastSessions.length > 0 ? (
                    PastSessions.map((data, i) => {
                      return (
                        <Grid item xs={12} sm={4} key={data._id}>
                           <PastSessionCard sessionData={data} />
                        </Grid>
                      );
                    })
                  ) : (
                    <Box mx="auto">
                      <Box m={2} p={1} mx="auto">
                        <i
                          className="fas fa-parachute-box fa-5x"
                          style={{ color: "#D2D2D2" }}
                        ></i>
                      </Box>
                      <Box m={1} mx="auto">
                        Past session not available !
                      </Box>
                    </Box>
                  )
                ) : (
                  <Box mx="auto">
                    <CircularProgress />
                  </Box>
                )}
              </Grid>
            </Box>

            {loder ? (
              <Box style={{ marginLeft: "50%" }}>
                <CircularProgress />
              </Box>
            ) : (
              ""
            )}

          </Paper>
          </InfiniteScroll>
        </Container>
      </div>
    );
  }
};

export default PastSession;