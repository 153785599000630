import React, { useState, useEffect } from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useSnackbar } from "react-simple-snackbar";
import { useDispatch } from "react-redux";
import { Button, Dialog } from "@material-ui/core";
import { ReportTechIssue } from "../redux/actions/SessionAction";

const MarkTechFailureModal = ({SessionId, feedbackData, markedTechFailure, setMarkedTechFailure}) => {
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [issueType, setIssueType] = useState("");
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const dispatch = useDispatch()

  const tagColours = {
    Requested : "blue",
    InReview : "#CCB266",
    Approved: "green",
    Declined: "red"
  }

  const markTechFailure = () => {
    if(!issueType) {openSnackbar("Please select any issue type."); return;}
    if(issueType==="other" && !feedbackText?.trim()) {openSnackbar("Please enter your issue."); return;}
    const data = {
        sessionId: SessionId,
        reportTechIssue: true,
        status:"Requested",
        issueType,
    }
    if(issueType==="other") data["otherTypeOfIssue"] = feedbackText;
    const onSuccess = (msg) => {
      setOpen(false);
      setLoader(false);
      setMarkedTechFailure(true)
      openSnackbar(msg);
      setFeedbackText("");
    };
    const onError = (msg) => {
      setOpen(false);
      setLoader(false);
      openSnackbar(msg);
    };

    dispatch(ReportTechIssue(data, onSuccess, onError))
  };

  const handleClose = () => {
    setOpen(false);
  }
  return (
    <>
        <button className='feedback' style={{position: 'relative', width: !feedbackData && !markedTechFailure ? '48%' : '100%'}} onClick={()=>{if(!markedTechFailure && !feedbackData)setOpen(true)}} disabled={feedbackData || markedTechFailure}>
          {markedTechFailure? 
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}><div>Reported tech failure</div><div style={{background: tagColours[feedbackData?.teachIssuefeedBack?.status || "Requested"], padding: '0 5px', borderRadius: 10, position: 'absolute', bottom: '-5px'}}>{feedbackData?.teachIssuefeedBack?.status || "Requested"}</div></div> : 
          "Mark as tech failure"}
        </button>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="lg"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Mark as tech failure
          </DialogTitle>
          <DialogContent style={{ "min-width": "60vw" }}>
            <DialogContentText id="alert-dialog-description">
              <label>Select you issue : </label>
              <select
                className="time-picker"
                value={issueType}
                onChange={(e)=>setIssueType(e.target.value)}
              >
                  <option value="">Select Issue</option>
                  <option value="video issue">Video Issue</option>
                  <option value="audio issue">Audio Issue</option>
                  <option value="joining issue">Joining Issue</option>
                  <option value="other">Other Issue</option>
              </select>
              <br/><br/>
              {issueType==="other" ? 
              <>
              <label>Type your issue :</label>
              <div className="feedback-content">
                  <textarea
                    className="textarea"
                    placeholder="Enter some text..."
                    value={feedbackText}
                    onChange={(e) => setFeedbackText(e.target.value)}
                  ></textarea>
              </div> 
              </>
              : null}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              variant="outlined"
              style={{ width: "50%" }}
            >
              Cancel
            </Button>
            <Button
              onClick={markTechFailure}
              color="primary"
              variant="contained"
              style={{ width: "50%" }}
              autoFocus
              disabled={loader}
            >
              {loader ? "Submitting.." : "Submit"}
            </Button>
          </DialogActions>
        </Dialog>
    </>
  );
};

export default MarkTechFailureModal;
