import moment from 'moment';

const currentDate = moment();
let date = currentDate.date();

const makeTodayAppointment = (startDate, endDate) => {
  const days = moment(startDate).diff(endDate, 'days');

  
  const nextStartDate = moment(startDate)
    .year(currentDate.year())
    .month(currentDate.month())
    // .date(date);
  const nextEndDate = moment(endDate)
    .year(currentDate.year())
    .month(currentDate.month())
    // .date(date + days);

    console.log(nextStartDate.toDate())
    console.log(nextEndDate.toDate())
  return {
    startDate: nextStartDate.toDate(),
    endDate: nextEndDate.toDate(),
  };
};



export const  HistoryDate = (appointments) =>{
  var returnRes = []
   appointments.map(({ startDate, endDate, ...restArgs }) => {

    const result = {
      ...makeTodayAppointment(startDate, endDate),
      ...restArgs,
    };
    //date += 1;
    if (date > 31) date = 1;
    returnRes.push(result);
     
  });
  return returnRes;
}