import { AspectRatioSharp } from "@material-ui/icons";
import axios from "axios";
import {PROD_API,DEV_API,LOCAL_HOST,GLOBAL_API} from './ApiConst'

const token = JSON.parse(localStorage.getItem("TOKEN"))

if(token){
    axios.defaults.headers.common["x-access-token"] = token;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Chat(url = `${process.env.REACT_APP_CHAT_SERVER}`) {
        return {            
            createRoom: (data) => axios.post(url+"/room/v1/initiate",data),
            getAllRooms: (data) => axios.get(url+"/room/v1",data),
            getAllUnreadMessage: (teacherId) => axios.get(url+`/room/v1/messages/unread?teacher=${teacherId}`),
            getRoomMessage: (roomId) => axios.get(url+`/room/v1/${roomId}`),
            sendFile: (data) => axios.post(url+"/room/v1/sendFile", data),
            updateMessage: (data) => axios.put(url+"/room/v1/message", data)
        }
    }
}