import React, { useEffect, useState } from 'react'
import livedemo from "../assets/livedemo.svg";
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import DemoRequestCard from '../component/cards/DemoRequestCard';
import { updateInstantBookingOption } from '../redux/actions/AuthAction';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useSnackbar } from 'react-simple-snackbar';
import toast from 'react-hot-toast';
import { DeleteInstantBookingRequest, GetAllDemoRequests, UpdateInstantBookingRequests } from '../redux/actions/SessionAction';
import { formatTimeToUTC, getRemainingTime, localToUTCMomentObj } from '../helper/sessionHelper';
import takeNotificationPermission from '../helper/notificationHelper';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Modal, Typography } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import norequestssvg from "../assets/norequests.svg";
import NotificationRequestPopup from '../component/NotificationRequestPopup';

const Countdown = ({isChecked, setIsChecked}) => {
  const [countdown, setCountdown] = useState("");
  const dispatch = useDispatch();
  const teacher = useSelector((state) => state.TeacherReducer.teacher);


  useEffect(()=>{
    if(isChecked){
      let endTime = teacher?.instantBooking.endTime
      setCountdown(getRemainingTime(endTime));
      const timer = setInterval(()=>{
        let remainingTime = getRemainingTime(endTime);
        if(remainingTime==="00:00:00"){
          setIsChecked(false);
          dispatch({
            type: "UPDATE_INSTANT_BOOKING",
            payload: {enabled: false}
          })
          clearInterval(timer);
          return;
        }
        setCountdown(remainingTime);
        },1000);
      return () => clearInterval(timer);
    }
  },[isChecked])

  return <span>{countdown}</span>;
}

const InstantBooking = () => {
  const dispatch = useDispatch();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const teacher = useSelector((state) => state.TeacherReducer.teacher);
  const requests = useSelector((state) => state.TeacherReducer.instantBookingRequests);
  const instantBookingRequestsCount = useSelector((state) => state.TeacherReducer.instantBookingRequestsCount);
  const instantBookingSocket = useSelector((state) => state.TeacherReducer.instantBookingSocket);
  const [isChecked, setIsChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState(1);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [infoOpen, setInfoOpen] = useState(false);
  const [notificationReqPopup, setNotificationReqPopup] = useState(false);
  const [enableLoader, setEnableLoader] = useState(false);

  const getAllDemoRequests = () => {
    setLoader(true);
    let payload = {
      page: page,
      limit: 20
    };
    const onSuccess = (data) => {
      setLoader(false);
      setPage(page+1);
    }
    const onError = (err) => {
      setLoader(false);
      toast.error(err);
    }
    dispatch(GetAllDemoRequests(payload, onSuccess, onError));
  }

  useEffect(()=>{
    if(teacher?.instantBooking){
      let endTime = teacher?.instantBooking.endTime
      if(!teacher?.instantBooking.enabled) setIsChecked(false);
      else if(teacher?.instantBooking.enabled && moment(endTime).isAfter()) setIsChecked(true);
    }
  },[teacher?.instantBooking])

  useEffect(()=>{
    toast.dismiss();
    getAllDemoRequests();
    let audio = document.getElementById('ringtone');
    if(audio) audio.pause();
  },[])

  useEffect(()=>{
    if(requests?.length===0){
      let audio = document.getElementById('ringtone');
      if(audio) audio.pause();
    }
  }, [requests])

  useEffect(()=>{
    if(instantBookingSocket?.connected){
      const incomingRequestListener = (data) => {
        dispatch(UpdateInstantBookingRequests(data))
      }
      const expiredRequestListener = (data) => {
        dispatch(DeleteInstantBookingRequest({learnerId: data.learnerId, decCount: false}));
      }  
      instantBookingSocket.on("instant_booking_session", incomingRequestListener);    
      instantBookingSocket.on("update_live_demo_screen", expiredRequestListener);
      return () => {
        instantBookingSocket.off("instant_booking_session", incomingRequestListener);
        instantBookingSocket.off("update_live_demo_screen", expiredRequestListener);
      }
    }
  },[instantBookingSocket])

    const useStyles = makeStyles((theme) => ({
        page: {
            fontFamily: 'Inter',
        },
        header: {
            backgroundColor: 'white',
            padding: '10px 16px'
        },
        headingWrapper: {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',

            '& .fa-info-circle': {
              color: '#AABAB7',
              cursor: 'pointer'
            }
        },
        heading: {
            fontSize: '20px',
            fontWeight: 700,
            color: '#082B28'
        },
        instantBookingSwitchWrapper: {
          display: 'flex', 
          justifyContent: 'space-between', 
          gap: '8px',
          flexDirection: 'column',
          [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'flex-end',
          }
        },
        instantBooking: {
            width: 'fit-content',
            backgroundColor: '#F2F5F4',
            padding: '4px 8px 8px',
            borderRadius: '10px',
            marginTop: '16px',
            color: '#647B76'
        },
        instantBookingText: {
            fontSize: '13px',
            fontWeight: 500,
            fontFamily: 'Inter'
        },
        disableText: {
            fontSize: '11px',
            fontWeight: 400,
            fontFamily: 'Inter'
        },
        disableTimer: {
            fontSize: '11px',
            fontWeight: 500,
            fontFamily: 'Inter'
        },
        demoRequestsSection: {
            marginTop: '8px',
            backgroundColor: 'white',
            padding: '10px 16px',
            height: 'calc(100vh - 217px)'
        },
        demoRequestHeading: {
            color: '#082B28',
            fontWeight: 600
        },
        demoRequestsContainer: {
            marginTop: '16px',
            display: 'flex',
            // justifyContent: 'center',
            gap: '19px',
            flexWrap: 'wrap'
        }
    }));

    const classes = useStyles();

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          transition: 'left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.mode === 'dark' ? '#24A791' : '#24A791',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: theme.palette.mode === 'light' ? '#AABAB7' : '#39393D',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }));

      const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        outline: 0,
        border: 'none'
      };
      

  const updateInstantBooking = (payload) => {
    setEnableLoader(true);
    const onSuccess = (msg) => {
      openSnackbar(msg);
      setIsModalOpen(false);
      setIsChecked(payload.enabled);
      setEnableLoader(false);
    }
    const onError = (msg) => {
      openSnackbar(msg);
      setEnableLoader(false);
    }
    dispatch(updateInstantBookingOption(payload, onSuccess, onError))
  }

  const handleSwitchChange = (event) => {
    if(event.target.checked && Notification.permission==="default"){
      setNotificationReqPopup(true)
    }
    else if(Notification.permission==="denied") {
      toast("Notification permission is denied. To enable notifications, follow these steps:\n\n1. Click on the padlock icon in the address bar (on the left side).\n2. Look for 'Notifications' in the dropdown menu.\n3. Change the setting to 'Allow'.\n\nAfter updating the settings, refresh the page and enjoy timely updates!");      
    }
    else if(event.target.checked){
      setIsModalOpen(true);
    }
    else {
      updateInstantBooking({enabled: false})
    }
  }

  const handleTimeChange = (e) => {
    setEndTime(e.target.value)
  }

  const enableInstantBooking = () => {
    let utcTime = localToUTCMomentObj();
    let payload = {
      enabled: true,
      startTime: utcTime.toISOString(), 
      endTime: utcTime.clone().add(endTime, 'hours').toISOString()
    }
   updateInstantBooking(payload)
  }

  const onNotiReqLater = () => {
    toast.error("You must switch on instant booking to receive push notifications for instant demo requests.");
  }
  const onNotiReqGrant = () => {
    setIsModalOpen(true);
  }
      
  return (
    <div className={classes.page}>
        <div className={classes.header}>
            <div className={classes.headingWrapper}>
                <img src={livedemo} alt="live demo"/>
                <h1 className={classes.heading}>Live Demo</h1>
                <div onClick={()=>{setInfoOpen(true)}}><i className="fas fa-info-circle"></i></div>
                <Dialog onClose={()=>{setInfoOpen(false)}} maxWidth="md" aria-labelledby="customized-dialog-title" open={infoOpen}>
                  <DialogContent dividers style={{ maxWidth: "410px", padding: "15px" }}>
                    <p>Turn on the instant booking switch to receive notifications for instant demo requests even when you are offline.</p>
                  </DialogContent>
                  <DialogActions>
                    <Button autoFocus onClick={()=>{setInfoOpen(false)}} color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
            </div>
            <div className={classes.instantBookingSwitchWrapper}>
              <div className={classes.instantBooking}>
                  <div className={classes.instantBookingText}>Instant Booking</div>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                      <FormControlLabel
                          id="instant-booking-switch"
                          control={<IOSSwitch sx={{ m: 1 }} checked={isChecked} onChange={handleSwitchChange} inputProps={{'aria-label' : 'controlled'}} />}
                          label={null}
                          sx={{marginRight: 0}}
                      />
                      {teacher?.instantBooking?.enabled ? <div><span className={classes.disableText}>Disable in </span><span className={classes.disableTimer}><Countdown isChecked={isChecked} setIsChecked={setIsChecked} /></span></div> : null}
                  </div>
              </div>
              <div style={{fontSize: '14px', color: 'gray', fontWeight: 500}}>
              * Your session will start in 2 minutes after your acceptance
              </div>
            </div>
        </div>
        <div className={classes.demoRequestsSection}>
            <div className={classes.demoRequestHeading}>Demo Request ({instantBookingRequestsCount})</div>
                <InfiniteScroll dataLength={requests?.length || 0}
                  next={() => getAllDemoRequests()}
                  hasMore={true}
                  style={{ overflow: "hidden",width:"100%" }}
                ></InfiniteScroll>
            <div className={classes.demoRequestsContainer}>
                {requests?.map(request=><DemoRequestCard key={request.learner.id} requestData={request} />)}
            </div>
            {loader ? (
              <Box style={{ marginLeft: "50%" }}>
                <CircularProgress />
              </Box>
            ) : !requests?.length ? (
              <div style={{height: '100%', display: 'grid', placeItems: 'center'}}>
              <div>
                <img src={norequestssvg} />
                <div style={{textAlign: 'center', color: '#647B76', fontSize: '13px'}}>No requests available</div>
              </div>
              </div>
            ) : null}
        </div>
        <Modal
          open={isModalOpen}
          onClose={()=>setIsModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Select Time
            </Typography>
            <div style={{display: 'flex', gap: 20}}>
              <div style={{marginTop: 20}}>
              <label style={{marginRight: 10}} for="endTime" name="endTime">End in</label>
              <select name="endTime" value={endTime} onChange={(e)=>handleTimeChange(e)} style={{fontSize: '16px'}}>
                {Array(5).fill(1).map((_, i)=>(
                  <option value={i+1} id={i}>{i+1} hr</option>
                ))}
              </select></div>
            </div>
            <Button sx={{marginTop: '20px'}} onClick={enableInstantBooking} variant='contained' disabled={enableLoader}>{enableLoader ? 'Enabling' : 'Enable'}</Button>
          </Box>
        </Modal>
        <NotificationRequestPopup  notificationReqPopup={notificationReqPopup} setNotificationReqPopup={setNotificationReqPopup} onNotiReqLater={onNotiReqLater} onNotiReqGrant={onNotiReqGrant} />
    </div>
  )
}

export default InstantBooking