import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "react-simple-snackbar";
import { Container, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GetDemoRequests, AcceptDemoRequests, GetPseudoDemoRequest, bookSession } from "../redux/actions/SessionAction";
import { dateFormater, formatTimeslotUTCtoLocalAMPM, utcToLocalDateConverter } from "../helper/sessionHelper";
import moment from "moment-timezone";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  acceptBtn: {
    backgroundColor: "#208F70",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#43a047",
    },
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  noData: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
}));

const DemoRequests = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openSnackbar] = useSnackbar();
  const [demoRequests, setDemoRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(null);

  const teacherData = useSelector((state) => state.TeacherReducer.teacher);


  const fetchDemoReq = (formattedRes) => {
    const onSuccess = (res) => {
      if (formattedRes.length > 0) {
        setDemoRequests(formattedRes.concat(res.availableSession));
      } else {
        setDemoRequests(res.availableSession);
      }
      setLoading(false);
    };
    const onError = (err) => {
      openSnackbar("Failed to fetch demo requests");
      setLoading(false);
    };
    dispatch(GetDemoRequests(onSuccess, onError));
  };
  
  const fetchPsedoDemoReq = () => {
    const onSuccessPseudoRequest = (res) => {
      const formattedRes = res?.activeSlots.map((data) => {
        return {
          "_id": data._id,
          "learner": data?.learner?.id,
          "startTime": data.startTime?.split("T")[1].split(".")[0].split(":").slice(0, 2).join(":"),
          "endTime": data.endTime?.split("T")[1].split(".")[0].split(":").slice(0, 2).join(":"),
          "sessionDate": data.sessionDateTime?.split("T")[0],
          "type": "Demo",
          "subjects": "English",
          "pseudoRequest": true,
        };
      });
      setDemoRequests(formattedRes);
      fetchDemoReq(formattedRes);
    };
    const onError = (err) => {
      openSnackbar("Failed to fetch demo requests");
      setLoading(false);
    };
    dispatch(GetPseudoDemoRequest(onSuccessPseudoRequest, onError));
  };

  useEffect(() => {
    fetchPsedoDemoReq()
  }, [dispatch]);

  const handleAccept = (id) => {
    setActionLoading(id);
    const data = { sessionId: id };
    const onSuccess = (res) => {
      if (res.message === "Demo successfully accepted") {
        openSnackbar(res.message); // Display success message
        setDemoRequests((prev) => prev.filter((request) => request._id !== id)); // Remove accepted request from list
      } else {
        openSnackbar("Unexpected response from the server");
      }
      setActionLoading(null);
    };
    const onError = (err) => {
      openSnackbar("Failed to accept demo request");
      setActionLoading(null);
    };
    dispatch(AcceptDemoRequests(data, onSuccess, onError));
  };

  const bookPseudoSession = (req) => {
 
      if(window.confirm("Are you sure you want to book this session?\nSelect 'OK' to confirm or 'Cancel' to go back.")){
        setActionLoading(req._id);

      const payload = {
        startTime:req.startTime,
        endTime:req.endTime,
        date:req.sessionDate,
        duration:15,
        subject:"English",
        type:"Demo",
        learnerId:req.learner,
        teacherId:teacherData._id,
        timezone:teacherData.timezone,
        psuedoSlotId:req._id
      }
  
      const onSuccess = (res) =>{
        setActionLoading(null);
        setDemoRequests((prev) => prev.filter((request) => request._id !== req._id)); // Remove accepted request from list
        openSnackbar("Session successfully booked :)")
      }
  
      const onError = (err) =>{
        setActionLoading(null);
        openSnackbar(err)
      }
  
      bookSession(payload,onSuccess,onError)
      
    }
  }
  

  return (
    <Container className={classes.root}>
      <h1 className="heading">
        Demo Requests
      </h1>
      {loading ? (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      ) : demoRequests.length === 0 ? (
        <Typography variant="h6" className={classes.noData}>
          No demo requests available
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Subject</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Type</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {demoRequests.map((request) => (
                <TableRow key={request._id}>
                  <TableCell>{request.subjects}</TableCell>
                  <TableCell>{utcToLocalDateConverter(request.sessionDate, request.endTime)}</TableCell> {/* Update here */}
                  <TableCell>{formatTimeslotUTCtoLocalAMPM(`${request.startTime}-${request.endTime}`)}</TableCell> {/* Update here */}
                  <TableCell>{request.type}</TableCell>
                  <TableCell align="right">
                    <Button
                      className={classes.acceptBtn}
                      onClick={() => {
                        if(request?.pseudoRequest)
                          bookPseudoSession(request)
                        else
                          handleAccept(request._id)
                      }}
                      disabled={actionLoading === request._id}
                    >
                      {actionLoading === request._id ? <CircularProgress size={24} /> : "Accept"}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default DemoRequests;
