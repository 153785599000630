import React, { Fragment, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Alert from "@material-ui/lab/Alert";
import { useSnackbar } from "react-simple-snackbar";
import { useDispatch } from "react-redux";
import { TeacherfeedbackV2 } from "../redux/actions/SessionAction";
import StarRating from "./StarRating";

const CurriculumFeedbackModal = ({
  SessionId,
  feedbackData,
  feedbackFormat,
  subject,
  sessionType,
  feedbackModalId,
  forKids,
}) => {
  const dispatch = useDispatch();

  const [points, setPoints] = useState([]);
  const [feedbackInfo, setFeedbackInfo] = useState({});
  const [selectedPoints, setSelectedPoints] = useState([]);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [loder, setLoder] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [feedbackText, setFeedbackText] = useState(""); //custom feedback text  

  useEffect(() => {
    if (!feedbackData && SessionId === feedbackModalId && feedbackFormat) {
      setOpen(true);
    }
  }, [feedbackData, feedbackFormat, feedbackModalId]);

  useEffect(()=>{
    if(open && feedbackFormat){
      let intialFeedbackInfo = JSON.parse(JSON.stringify(feedbackFormat));
      intialFeedbackInfo.forEach(category=>{
        category.subCategories.forEach(subCategory=>{
          subCategory.rating = 0;
        })
      })
      setFeedbackInfo(intialFeedbackInfo);
      let pointsData = feedbackFormat.map(category=>({categoryName: category.categoryName, categoryIdentifier: category.categoryIdentifier}));
      pointsData.push({categoryName: "other"});
      setPoints(pointsData);
    }
  }, [open])

  const handleClose = () => {
    setOpen(false);
  };

  function validateFeedback(feedback) {
    let isValid = true;
    let totalRatingByFeedback = {};

    feedback.forEach(category=>{
      totalRatingByFeedback[category.categoryIdentifier] = 0;
      category.subCategories.forEach(subCategory=>{
        if(subCategory.rating > 0) totalRatingByFeedback[category.categoryIdentifier]++;
      })
    })

    let isUnratedCategory = Object.values(totalRatingByFeedback).find(val=>val===0);

    if (isUnratedCategory!==undefined) {
      setError("Please rate atleast one sub attribute in every attribute");
      isValid = false;
    }
    if (isValid) {
      submitFeedback(feedback);
    }
  }

  function formatString(input) {
    return input
      .split("_") // Split the string by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(" "); // Join the words with spaces
  }

  const transformFeedbackData = (feedbackInfo) => {
    validateFeedback(feedbackInfo);
  };

  const submitFeedback = (feedback) => {
    setLoder(true);
    let data = {
      sessionId: SessionId,
      feedbackDetails: feedback
    };
    if (feedbackText) {
      data["others"] = feedbackText;
    }
    data["isNewFeedback"] = true;
    const onSuccess = (msg) => {
      setOpen(false);
      setLoder(false);
      openSnackbar(msg);
      setFeedbackText("");
    };
    const onError = (msg) => {  
      setOpen(false);
      setLoder(false);
      openSnackbar(msg);
    };
    dispatch(TeacherfeedbackV2(data, onSuccess, onError));
  };

  return (
    <>
      <button
        className="feedback"
        style={{ width: !feedbackData ? "48%" : "100%" }}
        onClick={() => setOpen(true)}
        disabled={feedbackData || forKids}
      >
        {feedbackData !== null ? (
          <>
            <i className="fa fa-check "></i> Feedback
          </>
        ) : (
          "Feedback"
        )}
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="feedback-container">
          <div className="feedback-heading">
            Tell us a bit about your learners English proficiency
          </div>
          <div className="feedback-subheading">
            Please click on the available category to give detailed feedback for
            each
          </div>
          <div className="feedback-attributes-container">
            {points?.map((data, index) => {
              const isSelected = selectedPoints.find((point) => {
                if (point.categoryName === data.categoryName) return true;
              });
              return (
                <div
                  key={index}
                  className="feedback-points"
                  style={{
                    background: isSelected ? "#52C5B6" : "#DBDBDB",
                  }}
                  onClick={() => {
                    if (isSelected) {
                      setSelectedPoints(
                        selectedPoints.filter((point) => point.categoryName !== data.categoryName)
                      );
                    } else {
                      setSelectedPoints([...selectedPoints, data]);
                    }
                  }}
                >
                  <span>{data.categoryName}</span>
                  {isSelected ? (
                    <img
                      src="/assets/Feedback/Minus.svg"
                      alt="plus"
                      height="20"
                      width="20"
                    />
                  ) : (
                    <img
                      src="/assets/Feedback/Plus.svg"
                      alt="plus"
                      height="20"
                      width="20"
                    />
                  )}
                </div>
              );
            })}
          </div>
          {selectedPoints.length === 0 && (
            <div className="feedback-middle-empty-container"></div>
          )}
          <div className="feedback-points-details-container">
            {selectedPoints
              .map((data, index) => (
                data.categoryName==="other" ?
                  <Fragment key={index}>
                    <div className="other-feedback-heading">Other Feedback</div>
                    <div className="other-feedback-subheading">
                      Give a constructive feedback
                    </div>
                    <div className="feedback-textarea">
                      <textarea
                        id="outlined-multiline-static"
                        name="multiline-textarea"
                        rows="4"
                        placeholder="Type Here..."
                        value={feedbackText}
                        onChange={(e) => setFeedbackText(e.target.value)}
                      ></textarea>
                    </div>
                  </Fragment>
                :
                <Fragment key={index}>
                  <div className="topic-heading">{data.categoryName}</div>
                  <div className="feedback-options-container">
                    {feedbackInfo.find(e=>e.categoryName===data.categoryName)?.subCategories?.map((subData, subIndex) => {
                      return (
                        <div
                          className="feedback-subAttribute-container"
                          key={subIndex}
                        >
                          <div className="feedback-subAttribute-title">
                            {subData.subCategoryName}
                          </div>
                          <StarRating
                            feedbackInfo={feedbackInfo}
                            setFeedbackInfo={setFeedbackInfo}
                            data={data}
                            subIndex={subIndex}
                            subData={subData}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div
                    className="feedback-subheading"
                    style={{ margin: "10px", textAlign: "left" }}
                  >
                    *Please click on the Star to finalize your rating
                  </div>
                </Fragment>
              ))}
          </div>

          <div className="feedback-points-details-container">
            {selectedPoints.includes("other") && (
              <>
                <div className="other-feedback-heading">Other Feedback</div>
                <div className="other-feedback-subheading">
                  Give a constructive feedback
                </div>
                <div className="feedback-textarea">
                  <textarea
                    id="outlined-multiline-static"
                    name="multiline-textarea"
                    rows="4"
                    placeholder="Type Here..."
                    value={feedbackText}
                    onChange={(e) => setFeedbackText(e.target.value)}
                  ></textarea>
                </div>
              </>
            )}
            {error ? <Alert severity="error">{error}</Alert> : ""}
            <div className="feedback-note">
              Note: For every feedback attribute, please rate atleast one sub
              attribute.
            </div>
            <button
              onClick={() => transformFeedbackData(feedbackInfo)}
              className="feedback-submit-btn"
              disabled={loder}
            >
              {loder ? "Submitting..." : "Submit"}
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default CurriculumFeedbackModal;
