import React from "react";
import { useSnackbar } from "react-simple-snackbar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
// import Link from "@material-ui/core/Link";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

//api call
// import Toster from "../component/Toster";
import { CircularProgress } from "@material-ui/core";
import { UpdatePassword } from "../../redux/actions/AuthAction";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://clapingo.com/">
        Clapingo
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function PasswordForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [conformPass, setConformPass] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loder, setLoder] = React.useState(false);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const user = JSON.parse(localStorage.getItem("USER"));

  const LoginTeacher = (e) => {
    e.preventDefault();

    if(password !== conformPass){
      setLoder(false);
      openSnackbar("Password Doesn't match");
      return
    }else{
    setLoder(true);
      const data = {
        phoneNumber_: user.phoneNumber,
        newpassword_: password,
      };

      //changeTeacherPassword
      const onSuccess = () => {
        setLoder(false);
        openSnackbar("Password updated successfully ");
        history.push("/");
      };
      const onError = (msg) => {
        openSnackbar(msg);
        setLoder(false);
      };
      dispatch(UpdatePassword(data, onSuccess, onError));
    }
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Set Password
          </Typography>
          <form className={classes.form} onSubmit={(e) => LoginTeacher(e)}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="Password"
              label="Password "
              name="Password"
              type="password"
              autoComplete="Password"
              autoFocus
              value={conformPass}
              onChange={(e) => setConformPass(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="condormpassword"
              label="Confirm Password"
              type="password"
              id="condormpassword"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loder ? true : false}
            >
              {loder ? <CircularProgress /> : "Sign In"}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link
                  to={{
                    pathname: "/verifynumber",
                    state: {
                      Text: "Forgot Password",
                    },
                  }}
                  variant="body2"
                >
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link
                  to={{
                    pathname: "/verifynumber",
                    state: {
                      Text: "Verify Your Number",
                    },
                  }}
                  variant="body2"
                >
                  {"Account not verified? verify"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}
