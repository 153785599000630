import React from "react";
import tutorDashboard from "../assets/tutorDashboard.PNG";
import "../layouts/layout.scss";

const DeleteAccount = () => {

  return (
      <div className="main-content flex flex-col justify-between">

        <div className="main-heading">
        <h1>Can I delete my account ?</h1>
        <div>You can submit an account deletion request from customer support chat option which appears on the homepage or dashboard page of the Clapingo website or app and your account deletion request will be processed within <b>30 days.</b></div>
        </div>

        <div className="image-content">
        <img src={tutorDashboard} width="650px" alt="chat"/>
        <div>You need to be able to log in to your account to request a deletion only if you are using android or ios app.</div>
        </div>

        <div className="list-header">Here are some important things to know before you delete your account:</div>

        <div className="lists-content">
        <ul>
            <li><span>You can only submit an account deletion request when you are logged on to the Clapingo app.</span></li>
            <li><span>You will immediately lose access to your Clapingo account. You will no longer be able to log in to your current Clapingo or Clapingo Kids account, access your past sessions or messages.</span></li>
            <li><span>The deletion of your account is irreversible.</span></li>
            <li><span>All of your account information is deleted <i>including messages.</i></span> </li>
            <li><span>Any Clapingo kids accounts linked to your account will also be deleted and will no longer be accessible.</span></li>
            <li className="text-bold"><span>You will immediately lose wallet balance of your account with no way to refund or retrieve them.</span></li>
        </ul>
        </div>
      </div>
  );
};

export default DeleteAccount;