import React, { useEffect, useState } from "react";
import S3FileUpload from "react-s3";
import {
  makeStyles,
  Container,
  Card,
  Typography,
  CardContent,
  Grid,
  Paper,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CardMedia,
  Box,
  TextField,
  Button,
  LinearProgress,
  IconButton,
  Chip,
  Dialog,
} from "@material-ui/core";
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useSnackbar } from "react-simple-snackbar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import {
  changeTeacherPassword,
  UploadProfile,
  TeacherQuery,
  AddBankDetails,
  GetBankDetails,
  VerifyBankDetails,
  AskForVerification,
} from "../redux/actions/AuthAction";
import { updateLocalStorageObject } from "../helper/sessionHelper";
import DialogTitle from "@material-ui/core/DialogTitle";
import VerifiedIcon from '@mui/icons-material/Verified';
import { getTags } from "../redux/actions/SessionAction";
import moment from "moment";
import useWindowSize from "use-window-size-v2";
import Confetti from 'react-confetti'
import birthdaycap from "../assets/birthdaycap.png";
import { CameraAlt } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "16px",
    marginBottom: "16px",
  },
  input: {
    display: "none",
  },
  image_container: {
    maxWidth: "10rem",
    border: "none",
    margin: "auto",
  },
  media: {
    height: 0,
    paddingTop: "100%",
    borderRadius: "100%",
    cursor: "pointer",
    transition: 'opacity 0.3s',
    '&:hover': {
      opacity: 0.7,
    },
    '& .cameraIcon': {
      opacity: 1
    }

  },

  cameraIcon : {
    position:"absolute",
    top: "50%", 
    left: "50%", 
    transform: "translate(-50%, -50%)",
    opacity: 0.8,
  },
  
  margin: {
    margin: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  PasswordForm: {
    "& > *": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
  ProfileForm: {
    "& > *": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
  chipWrap: {
    display: "flex",
    justifyContent: "start",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const Profile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [oldpassword, setOldPassword] = useState(null);
  const [newpassword, setNewPassword] = useState(null);
  const [conformpassword, setConformPassword] = useState(null);
  const [passwordLoder, setPasswordLoder] = useState(false);
  const [fileUploadLoder, setFileUploaddLoder] = useState(false);
  const [profileUpdateLoader, setProfileUpdateLoader] = useState(false);
  const [skillUpdateLoader, setSkillUpdateLoader] = useState(false);
  const [isBankDetailsPresent, setIsBankDetailsPresent] = useState(false);
  const [isBankAccountVerified, setIsBankAccountVerified] = useState(false);
  const [bankDetailsLoadError, setBankDetailsLoadError] = useState(false);
  const [holderName, setHolderName] = useState(null);
  const [ifscCode, setIfscCode] = useState(null);
  const [accountNo, setAccountNo] = useState(null);
  const [confirmAccountNo, setConfirmAccountNo] = useState(null);
  const [addBankdetailsLoader, setAddBankdetailsLoader] = useState(false);
  const [confirmbank, setConfirmbank] = useState(false);
  const [withdraw, setWithdraw] = useState(0);
  const time_zone = JSON.parse(localStorage.getItem("TIMEZONE"));
  const localhostTeacher = JSON.parse(localStorage.getItem("TEACHER"));

  const teacher = useSelector((state) => state.TeacherReducer.teacher);

  const teacherReducer = useSelector((state) => state.TeacherReducer);
  const [birthday, setBirthday] = useState(localhostTeacher ? localhostTeacher.dateOfBirth : "");
  const [showReminder, setShowReminder] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const { width, height } = useWindowSize(100)
  const theme = useTheme();
  const [showCameraIcon, setShowCameraIcon] = useState(false);
  const [selectedTags, setSelectedTags] = useState(
    localhostTeacher ? localhostTeacher.tags : []
  );
  const [peerLearningEnabled, setPeerLearningEnabled] = useState(false);

  // console.log("teachrbirth",localhostTeacher.dateOfBirth)

  const handleTags = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedTags(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [expanded, setExpanded] = useState(false);
  const [desc, setDesc] = useState(
    localhostTeacher ? localhostTeacher.description : ""
  );
  const [img, setImg] = useState(
    localhostTeacher.image
      ? localhostTeacher.image
      : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
  );
  const [email, setEmail] = useState(
    localhostTeacher ? localhostTeacher.email : ""
  );
  const [query, setQuery] = useState(null);
  const [skillTags, setSkillTags] = useState(
    localhostTeacher ? localhostTeacher.skills : []
  );
  const [teacherTags, setTeacherTags] = useState([]);

  useEffect(() => {
    if(teacher){
    const peerLearningSubject = teacher?.subjects?.filter(data => data?.peerLearning === true)
    if(peerLearningSubject?.length > 0){
      setPeerLearningEnabled(true)
    }
  }
  }, [teacher])

  useEffect(() => {
    if (withdraw === 1) {
      verifyWithdraw1();
    }
  }, [withdraw]);

  useEffect(() => {
    const onSuccess = (data) => {
      var accountData = data?.account?.beneId;
      if (accountData) {
        setIsBankDetailsPresent(true);
        setHolderName(accountData.name);
        setIfscCode(accountData.ifsc);
        setAccountNo(accountData.account_number);
      }
      let verificationStatus = data?.verificationStatus;
      if (verificationStatus === "Verified"){
        setIsBankAccountVerified(true);
      }
      else{
        setExpanded("panel6");
      }
    };
    const onError = (err) => {
      setBankDetailsLoadError(true);
      setExpanded("panel6");
      console.log(err);
    };

    GetBankDetails(onSuccess, onError);
  }, []);

  //GET TAGS
  useEffect(() => {
    // setLoader(true)
    console.log("use effect called")
     const onSuccess = (data) =>{
        //  setPayout(data.payoutsData)
        //  setLoader(false)
        console.log(data)
        setTeacherTags(data.tags)
        // let modifiedArray = data.tags.map(item => ({value: item}));
        // setSkillTags(modifiedArray)
     }
  
     const onError = (msg) =>{
        //  message.error(msg)
        //  setLoader(false)
        console.log(msg)
     }
     dispatch(getTags(onSuccess,onError))
  }, [])

  const fileUpload = (event) => {
    setFileUploaddLoder(true);
    var file = event.target.files[0];
    var url = URL.createObjectURL(event.target.files[0]);
    setImg(url);

    const config = {
      bucketName: `${process.env.REACT_APP_BUCKET_NAME}`,
      dirName: "Images",
      region: "us-east-1",
      accessKeyId: `${process.env.REACT_APP_ACCESS_KEY_ID}`,
      secretAccessKey: `${process.env.REACT_APP_SECRET_ACCESS_KEY}`,
    };
    
    S3FileUpload.uploadFile(file, config)
      .then((data) => {
        setImg(data.location);
        setFileUploaddLoder(false);
        const onSuccess = (msg) => {
          openSnackbar(msg);
          updateLocalStorageObject("TEACHER", "image", data.location);
        };
        const onError = (msg) => {
          openSnackbar(msg);
        };
        dispatch(UploadProfile({ image: data.location }, onSuccess, onError));
      })
      .catch((err) => {
        openSnackbar("Something went wrong !");
        setFileUploaddLoder(false);
      });
  };

  const updateProfile = (e) => {
    e.preventDefault();
    setProfileUpdateLoader(true);
    const SetupProfile = {
      description: desc,
      location: time_zone.timezone,
      email: email,
      dateOfBirth: birthday,
    };
    console.log(SetupProfile);
    const onSuccess = (msg) => {
      openSnackbar(msg);
      setProfileUpdateLoader(false);
      updateLocalStorageObject("TEACHER", "description", desc);
      updateLocalStorageObject("TEACHER", "email", email);
      updateLocalStorageObject("TEACHER", "dateOfBirth", birthday);
    };
    const onError = (msg) => {
      openSnackbar(msg);
      setProfileUpdateLoader(false);
    };
    dispatch(UploadProfile(SetupProfile, onSuccess, onError));
  };

  const PasswordChange = (e) => {
    e.preventDefault();

    setPasswordLoder(true);
    if (newpassword !== conformpassword) {
      openSnackbar("Conform password not match");
      setPasswordLoder(false);
      return;
    }
    const data = {
      phoneNumber: teacher.phoneNumber,
      oldpassword: oldpassword,
      newpassword: newpassword,
    };
    const onSuccess = (msg) => {
      openSnackbar(msg);
      setOldPassword("");
      setNewPassword("");
      setConformPassword("");
      setPasswordLoder(false);
    };
    const onError = (msg) => {
      openSnackbar(msg);
      setPasswordLoder(false);
    };
    dispatch(changeTeacherPassword(data, onSuccess, onError));
  };

  const PostQuery = (e) => {
    e.preventDefault();
    setPasswordLoder(true);
    const data = {
      userID: teacher._id,
      userquery: query,
    };
    const onSuccess = (msg) => {
      openSnackbar(msg);
      setQuery("");
      setPasswordLoder(false);
    };
    const onError = (msg) => {
      openSnackbar(msg);
      setPasswordLoder(false);
    };
    dispatch(TeacherQuery(data, onSuccess, onError));
  };

  const selectSkillTags = (val) => {
    var skills;
    const exist = skillTags.includes(val);
    if (!exist) {
      skills = [...skillTags, val];
      setSkillTags(skills);
    } else {
      skills = skillTags.filter((tag) => tag !== val);
      setSkillTags(skills);
    }
  };

  const isSelected = (val) => {
    return skillTags.includes(val);
  };

  const updateSkills = (e) => {
    e.preventDefault();
    setSkillUpdateLoader(true);
    const SetupProfile = {
      // _id:teacher._id,
      tags: selectedTags,
    };
    const onSuccess = (msg) => {
      openSnackbar(msg);
      setSkillUpdateLoader(false);
      updateLocalStorageObject("TEACHER", "tags", selectedTags);
    };
    const onError = (msg) => {
      openSnackbar(msg);
      setSkillUpdateLoader(false);
    };
    dispatch(UploadProfile(SetupProfile, onSuccess, onError));
  };

  const addBankDetails = (e) => {
    e.preventDefault();
    if (bankDetailsLoadError) {
      openSnackbar("we are unable to add your bank details at this time due to a network error");
      return;
    }
    if (isBankDetailsPresent) {
      openSnackbar("Your bank details are already added");
      return;
    }
    var data = {
      ifscCode: ifscCode,
      holderName: holderName,
    };

    if (accountNo?.trim() === confirmAccountNo?.trim())
      data["accountNo"] = accountNo;
    else {
      openSnackbar("Account Number doesn't match");
      return;
    }


    const onSuccess = (res) => {
      setAddBankdetailsLoader(false);
      setWithdraw(1);
      setIsBankDetailsPresent(true);
      console.log(res);
    };
    const onError = (msg) => {
      setAddBankdetailsLoader(false);
      openSnackbar(msg);
    };
    setAddBankdetailsLoader(true);
    AddBankDetails(data, onSuccess, onError);
  };
  const verifyWithdraw1 = () => {
    var data = {
      teacherId: JSON.parse(localStorage.getItem("TEACHER"))._id,
    };
    const onSuccess = (res) => {
      setConfirmbank(true);
      setWithdraw(0);
    };
    const onError = (msg) => {
      setAddBankdetailsLoader(false);
      openSnackbar(msg);
    };
    VerifyBankDetails(data, onSuccess, onError);
  };

  const askToverify = (response) => {
    if (!isBankDetailsPresent) {
      openSnackbar("Please complete step1 before verifying");
      return;
    }
    if (teacher?.bankVerificationStatus === "Verified") {
      openSnackbar("Your bank account details are already verified");
      return;
    }
    var data = {
      teacherId: JSON.parse(localStorage.getItem("TEACHER"))._id,
      isBankDetailsVerified: response,
    };
    const onSuccess = (res) => {
      console.log(res);
      setAddBankdetailsLoader(false);
      openSnackbar(res.message);
      setIsBankAccountVerified(true);
    };
    const onError = (msg) => {
      setAddBankdetailsLoader(false);
      openSnackbar(msg);
    };
    AskForVerification(data, onSuccess, onError);
  };

  const preventDefaultFn = (e) => {
    e.preventDefault();
  } 

// birthday reminder check
  useEffect(() => {
    const checkBirthdayReminder = () => {
    var date_today = new Date();
    console.log("datee", date_today)
    var now_utc = Date.UTC(date_today.getUTCFullYear(), date_today.getUTCMonth(),
    date_today.getUTCDate());
    var var_utc_today = moment(now_utc).format('YYYY-MM-DD');
    // console.log("date",var_utc_today)

    var convertedBirth = moment(birthday).year(moment().year()).format('YYYY-MM-DD');
    // console.log("date2",convertedBirth )
      if (convertedBirth === var_utc_today) {
        setShowReminder(true);
        setShowConfetti(true);
      }

    }

  if (birthday) {
    checkBirthdayReminder();
  }
  const timer = setTimeout(() => {
    setShowConfetti(false);
  }, 8000);

  return () => clearTimeout(timer);
  },[birthday])

  // console.log("birth", birthday)

  return (
    <>
      <Dialog
        open={confirmbank}
        onClose={() => setConfirmbank(false)}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle>Please confirm your bank details</DialogTitle> */}
        <DialogTitle>
          You will receive an SMS from your bank within the next 30 minutes. Once you have received this message, you may proceed to verify your bank details.
        </DialogTitle>
        <Button
          variant="contained"
          color={"primary"}
          onClick={() => setConfirmbank(false)}>Ok</Button>

        {/* <DialogActions className="buttons">
              <Button
                color={"primary"}
                variant="contained"
                onClick={() => {askToverify(true);setConfirmbank(false)}}
              >
                Verify
              </Button>
              <Button
                color={"secondary"}
                variant="contained"
                onClick={() => {askToverify(false);setConfirmbank(false)}}
              >
                Not verified
              </Button>
            </DialogActions> */}
      </Dialog>

      <Container className={classes.root} maxWidth="xl">
        <Card>
          <CardContent>
          {showReminder && (
        <>
          <h2 className="birthday-wish">Happy Birthday!</h2>
         { showConfetti && ( 
          <Confetti
          width={width}
          height={height}
        />)}
        </>
      )}
            <Grid container spacing={3} p={4}>
              <Grid item xs={12} sm={3}>
              {showReminder && (
              <div className="birthday-cap">
              <img src={birthdaycap} alt="apple store" width={100} height={100}/>
              </div>
              )}
              <div className="profile-pic">
                <Paper variant="outlined" className={classes.image_container}>
                  <label htmlFor="contained-button-file">
                    <CardMedia
                      className={classes.media}
                      image={img}
                      title="teacher img"
                      onMouseEnter={() => setShowCameraIcon(true)} // Show camera icon on hover
                      onMouseLeave={() => setShowCameraIcon(false)}
                    />
                    {showCameraIcon && (
                      <CameraAlt
                      onClick={(e) => fileUpload(e)}
                      className={classes.cameraIcon}
                     />
                  )}
                  </label>
                  <input
                    accept="image/*"
                    multiple
                    type="file"
                    style={{ display: "none" }}
                    id="contained-button-file"
                    onChange={(e) => fileUpload(e)}
                  />
                  {fileUploadLoder ? (
                    <LinearProgress style={{ marginTop: "1rem" }} />
                  ) : (
                    ""
                  )}
                </Paper>
                </div>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Paper
                  variant="outlined"
                  style={{ border: "none", margin: "auto" }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-evenly"
                    style={{ marginTop: "5%" }}
                  >
                    {/* <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-evenly"
                      alignItems="center"
                    >
                      <p>
                        {teacher
                          ? teacher.earnings
                            ? teacher.earnings
                            : "0"
                          : "Loading.."}
                      </p>
                      <strong>Your Earning</strong>
                    </Box> */}
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-evenly"
                      alignItems="center"
                    >
                      <p>
                        {" "}
                        {teacherReducer
                          ? teacherReducer.TotalPastSession
                            ? teacherReducer.TotalPastSession
                            : 0
                          : "Loading.."}
                      </p>
                      <strong>Class Taken</strong>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-evenly"
                      alignItems="center"
                    >
                      <p>
                        {teacherReducer
                          ? teacherReducer.TotalUpcommingSession
                            ? teacherReducer.TotalUpcommingSession
                            : 0
                          : "Loading..."}
                      </p>
                      <strong>Upcoming Session</strong>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-evenly"
                      alignItems="center"
                    >
                      <p>
                        {teacher
                          ? teacher.noOfLikes
                            ? teacher.noOfLikes
                            : 0
                          : "Loading.."}
                      </p>
                      <strong>No. of Likes</strong>
                    </Box>

                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {/* UPDATE PROFILE */}
        <Accordion
          expanded={expanded === "panel0"}
          onChange={handleChange("panel0")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel0bh-content"
            id="panel0bh-header"
          >
            <Typography className={classes.heading}>
              {" "}
              <i className="fas fa-id-card-alt"></i> Update profile
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <form
              style={{ width: "100%" }}
              className={classes.ProfileForm}
              id="profileform"
              onSubmit={(e) => updateProfile(e)}
            >
              <TextField
                id="outlined-basic"
                label="Update Email"
                variant="outlined"
                type="email"
                name="email"
                style={{ width: "100%" }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />

              <TextField
                id="outlined-basic"
                label="Update birthday"
                variant="outlined"
                type="date"
                name="birthday"
                style={{ width: "100%" }}
                value={moment(birthday).format("YYYY-MM-DD")}
                onChange={(e) => setBirthday(e.target.value)}
                required
              />
              <TextField
                 id="outlined-multiline-static"
                label="Add description"
                multiline
                rows={4}
                variant="outlined"
                style={{ width: "100%" }}
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
              />
              <Button
                type="submit"
                variant="contained"
                style={{ width: "100%" }}
                disabled={profileUpdateLoader}
                color="primary"
              >
                {profileUpdateLoader ? "Updating ..." : "Update"}
              </Button>
            </form>
          </AccordionDetails>
        </Accordion>

        {/* ADD SKILLS */}
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel0bh-header"
          >
            <Typography className={classes.heading}>
              <i className="fas fa-pencil-ruler"></i> Add tags
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <form
              style={{ width: "100%" }}
              className={classes.ProfileForm}
              id="profileform"
              onSubmit={(e) => updateSkills(e)}
            >

              <InputLabel id="demo-multiple-chip-label">Select tags</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={selectedTags}
                onChange={handleTags}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {teacherTags?.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, selectedTags, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>

              <Button
                type="submit"
                variant="contained"
                style={{ width: "100%" }}
                disabled={skillUpdateLoader}
                color="primary"
              >
                {skillUpdateLoader ? "Updating ..." : "Update"}
              </Button>
            </form>
          </AccordionDetails>
        </Accordion>

        {/* CHANGE PASSWORD */}
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>
              <i className="fas fa-user-lock"></i> Change Password
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <form
              style={{ width: "100%" }}
              className={classes.PasswordForm}
              id="Passwordform"
              onSubmit={(e) => PasswordChange(e)}
            >
              <TextField
                id="outlined-basic"
                label="Old password"
                type="password"
                variant="outlined"
                name="oldPassword"
                style={{ width: "100%" }}
                value={oldpassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required
              />
              <TextField
                id="outlined-basic"
                label="New Password"
                type="password"
                variant="outlined"
                style={{ width: "100%" }}
                name="nePassword"
                value={newpassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
              <TextField
                id="outlined-basic"
                label="Conform New Password"
                variant="outlined"
                type="password"
                name="conformPassword"
                style={{ width: "100%" }}
                value={conformpassword}
                onChange={(e) => setConformPassword(e.target.value)}
                required
              />
              <Button
                type="submit"
                variant="contained"
                style={{ width: "100%" }}
                disabled={passwordLoder ? true : false}
                color="primary"
              >
                {passwordLoder ? "Updating ..." : "Update"}
              </Button>
            </form>
          </AccordionDetails>
        </Accordion>

        {/* ADD BANK DETAILS */}
        {
          !peerLearningEnabled && 
          <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>
              <i className="fas fa-money-check-alt"></i> Add & Verify bank details
            </Typography>
          </AccordionSummary>
          <Typography className={classes.heading} style={{ padding: "0px 15px" }}>
            Add bank details - Step 1
          </Typography>
          <AccordionDetails>
            <form
              style={{ width: "100%" }}
              className={classes.ProfileForm}
              id="profileform"
              onSubmit={(e) => addBankDetails(e)}
            >

              <TextField
                id="outlined-basic"
                label="Account holder name"
                type="text"
                variant="outlined"
                name="holderName"
                style={{ width: "100%" }}
                value={holderName || ''}
                onChange={(e) => setHolderName(e.target.value)}
                disabled={isBankDetailsPresent}
                autoComplete="off"
                required
                onPaste={preventDefaultFn}
                onCopy={preventDefaultFn}
              />
              <TextField
                id="outlined-basic"
                label="IFSC Code"
                type="text"
                variant="outlined"
                style={{ width: "100%" }}
                name="ifscCode"
                value={ifscCode || ''}
                onChange={(e) => setIfscCode(e.target.value)}
                disabled={isBankDetailsPresent}
                autoComplete="off"
                required
                onPaste={preventDefaultFn}
                onCopy={preventDefaultFn}
              />
              <TextField
                id="outlined-basic"
                label="Account Number"
                variant="outlined"
                type="text"
                name="accountNo"
                style={{ width: "100%" }}
                value={accountNo || ''}
                onChange={(e) => setAccountNo(e.target.value)}
                disabled={isBankDetailsPresent}
                autoComplete="off"
                required
                onPaste={preventDefaultFn}
                onCopy={preventDefaultFn}
              />
              {!isBankDetailsPresent && <><TextField
                id="outlined-basic"
                label="Confirm Account Number"
                variant="outlined"
                type="text"
                name="confirmAccountNo"
                style={{ width: "100%" }}
                value={confirmAccountNo || ''}
                onChange={(e) => setConfirmAccountNo(e.target.value)}
                disabled={isBankDetailsPresent}
                autoComplete="off"
                required
                onPaste={preventDefaultFn}
                onCopy={preventDefaultFn}
              />
                <Typography style={{ color: "red", fontSize: 14 }}>
                  * Please ensure that bank details are correct. It cannot be updated later.
                </Typography>

                <Button
                  type="submit"
                  variant="contained"
                  style={{ width: "auto" }}
                  disabled={addBankdetailsLoader ? true : false}
                  color="primary"
                >
                  {addBankdetailsLoader ? "Adding ..." : "Add bank details"}
                </Button></>}
            </form>
          </AccordionDetails>
          {isBankDetailsPresent ?
            <>
              <Typography className={classes.heading} style={{ padding: "0px 15px" }}>
                Verify bank details - Step 2
              </Typography>
              <AccordionDetails>
                <Box className={classes.ProfileForm}>
                  {isBankAccountVerified ?
                    <div style={{ display: "flex", color: "#3ea175", gap: 10 }}>
                      <VerifiedIcon />
                      <Typography>Your bank account is verified</Typography>
                    </div>
                    : <><Typography style={{ color: "red", fontSize: 14 }}>
                      * IMPORTANT- Please ensure that you click the button (VERIFY) only after receiving the SMS from your bank about Re.1 being credited
                    </Typography>
                      <Button
                        color={"primary"}
                        variant="contained"
                        style={{ width: "179px" }}
                        onClick={() => { askToverify(true) }}
                      >
                        Verify
                      </Button></>}
                </Box>
              </AccordionDetails></> : null}
        </Accordion>
        }

        {/* SUBJECT RELATED QUERY */}
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography className={classes.heading}>
              <i className="fas fa-question"></i> Subject Related Query
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <form style={{ width: "100%" }} onSubmit={(e) => PostQuery(e)}>
              <textarea
                className="query_input"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                rows={4}
                cols="10"
              />
              <Button
                variant="contained"
                type="submit"
                disabled={passwordLoder ? true : false}
                style={{ width: "90%", marginTop: "0.6rem" }}
                color="primary"
              >
                {passwordLoder ? "Loding..." : "Submit"}
              </Button>
            </form>
          </AccordionDetails>
        </Accordion>
          {/* <div style={{height: '90px', width: '100%', maxWidth: '970px', margin: '30px auto 0px'}}>
            <Adsense
              client="ca-pub-3715562101512710"
              slot="JustBaat_Clapingo\/Clapingo_728x90"
              style={{width: "100%", height: "90px", display: "block", maxWidth: "970px"}}
              format="fluid"
            />
        </div> */}
      </Container>
    </>
  );
};

export default Profile;