import React from "react";
import { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Box, Container } from '@material-ui/core';
import DummyImage from "../../src/assets/dummy.png";
import { ResourcesUpload } from "../redux/actions/AuthAction";
import { useSnackbar } from "react-simple-snackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 8,
  },
}));

const HelpfulPDF = () => {
  const classes = useStyles();
  const [resourceList, setResourceList] = useState([]);
  const [openSnackbar] = useSnackbar();

  useEffect(() => {
    const data = {
      page: 1,
      limit: 10,
      documentType: "application/pdf",
    };
    const onSuccess = (res) => {
      setResourceList(res?.getAllResourceDetails);
      if(res.statusCode == 500){
        openSnackbar(res.message)
      }
    };
    const onError = (msg) => {
      openSnackbar(msg);
    };
    ResourcesUpload(data, onSuccess, onError);
  }, []);

  const openPdfInNewTab = (pdfLink) => {
    window.open(pdfLink, '_blank');
  };

  return (
    <Container className={classes.root}>
      <Paper component={Box} p={2} mx="auto">
        <h2 style={{ marginBottom: "1rem" }}>Helpful PDF</h2>
        {resourceList?.map((pdf, index) => (
          <div key={pdf._id} className="resource-content">
            <img
              src={DummyImage}
              width="100"
              height="50"
              style={{ objectFit: "cover", borderRadius: "5px", cursor: "pointer" }}
              onClick={() => openPdfInNewTab(pdf.documentAwsLink)}
            />
            <div className="content-side">
              <div className="resource-name">{pdf.documentName}</div>
            </div>
          </div>
        ))}
      </Paper>
    </Container>
  );
};

export default HelpfulPDF;
