import React, { useEffect, useState } from "react";
import { bookSession } from "../../redux/actions/SessionAction";
import { useSnackbar } from "react-simple-snackbar";
import { converter, localTimeToUTCConverter, sessionBookingDate, sessionBookingDateV2 } from "../../helper/sessionHelper";
import { useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { Button } from "@mui/material";

const moment = require('moment-timezone');

const DateTimePicker = ({ onClose, subscriptions, sessionData, learner, bookingMsg, setBookingMsg, handleSuggest, selectedDate, setSelectedDate, selectedTime, setSelectedTime, message}) => {
  let subs =  subscriptions?.filter((sub)=>sub.forKids===sessionData.forKids && sub.subjects===sessionData.subject)?.[0];
  let duration = sessionData?.forKids ? 25 : Number(subs?.plan?.classDuration?.replace("min", ""));
  const time_zone = JSON.parse(localStorage.getItem("TIMEZONE"));
  const [bookingLoader, setBookingLoader] = useState(false);
  const timeSlots = generateTimeSlotsForDate(selectedDate, duration, 5,time_zone.timezone);

  const [openSnackbar, closeSnackbar] = useSnackbar();

  const teacherData = useSelector((state) => state.TeacherReducer.teacher);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    setSelectedTime(""); // Reset selected time when date changes
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  function generateTimeSlotsForDate(targetDate, slotDuration, breakDuration, timeZone){
    const timeSlots = [];
    const currentDate = moment().tz(timeZone);
    const selectedDateMoment = moment.tz(targetDate, timeZone);

    if (selectedDateMoment.isSame(currentDate, "date")) {
    let currentTimePlusTenMin = currentDate.clone().add(10, 'minutes');
    selectedDateMoment.set({
      hour: currentTimePlusTenMin.hour(),
      minute: currentTimePlusTenMin.minute(),
      second: currentTimePlusTenMin.second(),
    })
  }

    if (selectedDateMoment.isSameOrAfter(currentDate, "day")) {
      const currentTime = selectedDateMoment.clone();
      const endOfDay = currentTime.clone().endOf('day');

      while (currentTime <= endOfDay) {
        const startTimeString = currentTime.format('HH:mm');

        currentTime.add(slotDuration, 'minutes');
        const endTimeString = currentTime.format('HH:mm');

        if (selectedDateMoment.isSame(currentDate, 'day')) {
          // If it's today, only add time slots that are in the future
          const currentMoment = moment().tz(timeZone);
          if (currentTime.isSameOrAfter(currentMoment)) {
            timeSlots.push(`${startTimeString} - ${endTimeString}`);
          }
        } else {
          timeSlots.push(`${startTimeString} - ${endTimeString}`);
        }

        // Add break
        currentTime.add(breakDuration,'minutes');
      }
    }

    return timeSlots;
  }

  const isFormValid = selectedDate && selectedTime;

  const createSession = () =>{
    if(window.confirm("Are you sure you want to book session!\nEither OK or Cancel.")){
    setBookingLoader(true)
    const splitTime = selectedTime.split(" - ")
    console.log(selectedDate,splitTime[0])
    const payload = {
      startTime:localTimeToUTCConverter(splitTime[0]),
      endTime:localTimeToUTCConverter(splitTime[1]),
      date:converter(`${selectedDate}T${splitTime[0]}:00`,time_zone.timezone),
      duration:duration,
      subject:"English",
      type:"Regular",
      learnerId:learner,
      teacherId:teacherData._id,
      timezone:time_zone.timezone
    }

    console.log(payload)

    const onSuccess = (res) =>{
      setBookingLoader(false)
      setSelectedTime("")
      setSelectedTime("")
      openSnackbar("Session successfully booked :)")
    }

    const onError = (err) =>{
      setBookingLoader(false)
      openSnackbar(err)
    }

    bookSession(payload,onSuccess,onError)
    }
  }

  useEffect(() => {
    if(selectedDate && selectedTime){
      const date = moment(selectedDate).clone().format("YYYY-MM-DD")
      console.log("DateTimePicker -> message", message)
     if(message?.topic?._id){
        const topicMsg = `Thank you for reaching out! But Unfortunately I am not available at your suggested time. I am sending an open slot. \n \nProposed Date: ${date} \nProposed Time: ${selectedTime} \nTopic Name: ${message?.topic.name} \nTopic Level: ${message?.topic.level} \n\nAccept if this works for you or request an alternate time!\nThanks`
        setBookingMsg(topicMsg)
     }else if(message?.topic?.certificateId){
        const certificateMsg = `Thank you for reaching out! But Unfortunately I am not available at your suggested time. I am sending an open slot. \n \nProposed Date: ${date} \nProposed Time: ${selectedTime} \nCertificate Name: ${message?.topic.name} \nCertificate Level: ${message?.topic.level} \n\nAccept if this works for you or request an alternate time!\nThanks`
        setBookingMsg(certificateMsg)
     }else{
        const msg = `Thank you for reaching out! But Unfortunately I am not available at your suggested time. I am sending an open slot. \n \nProposed Date: ${date} \nProposed Time: ${selectedTime} \nSubject: ${sessionData?.subject} \n\nAccept if this works for you or request an alternate time!\nThanks`
        setBookingMsg(msg)
      }
    }
  }, [selectedDate,selectedTime])
  

  return (
    <div className="picker-container slide-up">
      <div className="heading">Select Date and Time</div>
      <div className="inner-container">
        <form className="picker">
          <input
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
            className="date-picker"
            min={moment().tz(time_zone.timezone).format("YYYY-MM-DD")}
            max={moment().add(6, 'days').tz(time_zone.timezone).format("YYYY-MM-DD")}
          />
        </form>

        <select
          className="time-picker"
          value={selectedTime}
          onChange={handleTimeChange}
        >
            <option value="">Select Time</option>
            {timeSlots.map((time, index) => {
                return (
                    <option key={index} value={time}>
                        {time}
                    </option>
                );
            })}
        </select>
      </div>
      {sessionData && bookingMsg ? <div className="picker_msg" style={{padding: "10px 0px", fontSize: 14}} dangerouslySetInnerHTML={{__html: bookingMsg}} >{}</div> : null}

      <div className="btn-container">
        <Button
          variant="contained"
          sx={{minWidth: "auto"}}
          className="submitRequest_btn"
          onClick={() => {
            handleSuggest()
          }}
        >
          Suggest
        </Button>

        <Button
          variant="contained"
          sx={{minWidth: "auto"}}
          className="cancel_btn"
          onClick={onClose}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default DateTimePicker;
