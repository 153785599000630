import React, { useState } from 'react';
import Collapse from 'react-collapse';
import HelpIcon from '@mui/icons-material/Help';
import dispute from "../assets/raiseDispute.png";
import addBio from "../assets/addbio.png";
import blocklearners from "../assets/blocklearners.png";
import profileImage from "../assets/profileimage.png"
const Collapsible = ({ id, activeId, setActiveId, title, content, image}) => {
  const isOpen = activeId === id;

  const toggleCollapsible = () => {
    setActiveId(isOpen ? null : id);
  };

  return (
    <div className="collapsible-container faq-questions-container">
      <div className="faq-bullets" onClick={toggleCollapsible}>
      <HelpIcon sx={{fontSize: 40, color:"#24A791"}}/> 
        <p>{title}</p>
        </div>
      <Collapse isOpened={isOpen} theme={{ collapse: 'collapsible-content' }}>
        <div className='faq-text'>
          {content}
          <img src={image} className="faq-image" alt="" />
        </div>
      </Collapse>
    </div>
  );
};

const FAQ = () => {
  const [activeId, setActiveId] = useState(null);

  const collapsibles = [
    { id: 1, 
      title: 'Missed Session?', 
      content: 'Missing session penalties will be charged according to the present tutor missing policy.A tutor will be blocked permanently  from the platform if they miss 5 sessions.' 
    },
    { id: 2, 
      title: 'Late Joining?', 
      content: 'Late joining penalty will be charged if a tutor joins the session 2 minutes after the scheduled time. In case of any tech issue, please share the screenshot of the issue immediately on the chat support and the team will look into it and remove the penalty if required.' 
    },
    { id: 3, 
      title: 'Cancellation?', 
      content: ' Cancellation penalties will be imposed according to the present cancellation policy.Sessions can be cancelled 2 hours before the scheduled time.No request for cancellation beyond this time will be accepted.The tutors can cancel sessions through tutor website or tutor application. ' 
    },
    { id: 4, 
      title: 'Why TDS?', 
      content: 'Clapingo will deduct 10% TDS (tax deduction at source) on a Speaker’s monthly pay, as per government taxation laws. Income earnings from Clapingo is to be considered as professional income not salary income therefore TDS is to be deducted at 10% under section 194J as per the Income Tax Act. You shall receive a TDS certificate for every financial quarter at the end of the year. You will be eligible for a refund for the difference in case the tax deducted is in excess of the tax amount. You will automatically receive a refund at the time of filing your tax returns.' 
    },
    { id: 5, 
      title: 'Late Join Policy?', 
      content: '  For every scheduled session that you do not join within the first minute of the session, you will incur a penalty of Rs. 10.If you continue to join sessions late, we will be required to take strict action.' 
    },
    { id: 6, 
      title: 'Cancellation Policy?', 
      content: `In case of emergencies, you will be permitted to cancel sessions in accordance with the following penalties:
      1. Cancellation of 0 -10 sessions in a month: No Penalty.
      2. Cancellation of 11 - 20 sessions in a month: Penalty of Rs. 20 per cancelled session.
      3. Cancellation of 21 - 30 sessions in a month: Penalty of Rs. 30 per cancelled session.
      4. Cancellation of 31 or more sessions in a month: Penalty of Rs. 50 per cancelled session.`,
    },
    { id: 7 , 
      title: 'Quality Concerns?', 
      content: `To ensure that the clapingo  provides top-quality educational services, we have developed several rules and regulations for tutors.The violation of these rules will result in hiding and/or blocking the tutor’s profile from search results. For multiple or severe violations of these rules, the profile will be blacklisted and removed from the platform.
      1. Missing sessions without cancelling and closing time slots.
      2. Keeping the camera/microphone off during the sessions.For tutors it is mandatory to keep their camera and mic on unless there the learner requests them to turn it off in case of network issues.
      3.Having poor network during the sessions.Tutors are requested to have a strong, stable internet connection during the session. Also, do have a backup connection ready in case of network issues.
      4.Violation of social norms and rules including inappropriate behavior. Each complaint will be investigated individually by the clapingo team, and strict actions can be initiated.`
    },
    { id: 8, 
      title: 'How the tutor referral earnings are counted?', 
      content: 'If a speaker refers another speaker to the platform, the referral is counted after the new speaker have been trained and complete 20 sessions on the platform. The  Referral amount is then added to the next monthly pay out.' 
    },
    { id: 9, 
      title: 'How to raise a dispute?', 
      content: 'Go to Session Attendance -> Click on the RAISE button under Raise Dispute -> You will then be updated about the status of your raised dispute under the same tab in a few hours.',
      image: dispute
    },
    { id: 10, 
      title: 'How to block a learner?', 
      content: 'Go to Past Sessions. -> Click on block learner -> You can find them in the Block Learner tab.' ,
      image: blocklearners
    },
    { id: 11, 
      title: 'How to add bank details?', 
      content: ' Log in to the Clapingo Website Dashboard. (If you are already logged in, please log out and log back in to ensure that you are using the updated version of the dashboard) -> Click on the Profile tab -> Select the "Add & Verify Bank Details" option -> Please add your details correctly. Do check before submitting as they cannot be edited later -> Once submitted, please wait to receive the credited amount notification/message from your bank. (The test amount would be Rs.1/-) -> If received, please go ahead and click on "Verify Banking Details" (If not, please check the details you added or reach out to Chat Support for assistance).It will only be updated once you have followed all the steps and successfully verified your bank details.'
    },
    { id: 12, 
      title: 'How to add profile picture?', 
      content: 'Go to Profile -> Click on the picture section -> Select a picture from your device ->You will then get a pop-up “Profile updated successfully". ',
      image: profileImage
    },
    { id: 13, 
      title: 'How to add bio?', 
      content: ' Go to Profile -> Update profile -> Add your email and bio -> Click on UPDATE -> Your bio will be saved ' ,
      image: addBio
    },
    { id: 14, 
      title: 'Learner not present?', 
      content: 'Please wait for the whole session even if the learner is not present during the session.' 
    },
    { id: 15, 
      title: 'What to do when you face a technical issue?', 
      content: 'We request you to share the screenshot of the issue to the chat support. We will look into the matter and update you soon.' 
    },
    { id: 16, 
      title: 'Farewell Session?', 
      content: 'A "Farewell Session" banner will be seen if it is the last session of a learner’s subscription.The plan for this session is to evaluate the learner’s progress and outline a course of action for the future. We would like to urge the learner to resubscribe and effectively achieve their Spoken English Goals.'
    },
    
  ];

  return (
    <div className="faq-container">
    <div className="faq">Have a question?<br />
     Find the answers in our <span className="faq-heading">FAQ</span>:</div>
      {collapsibles.map((collapsible) => (
        <Collapsible
          key={collapsible.id}
          id={collapsible.id}
          activeId={activeId}
          setActiveId={setActiveId}
          title={collapsible.title}
          content={collapsible.content}
          image={collapsible.image}
        />
      ))}
    </div>
  );
};

export default FAQ;
