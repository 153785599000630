import React, { useEffect, useState } from "react";

import {
  Container,
  Paper,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GetAllActiveCoupons, GetAllUpdates } from "../redux/actions/SessionAction";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import toast from "react-hot-toast";
import { CopyOutlined } from "@ant-design/icons";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.grey[300],
    paddingTop: theme.spacing(3),
  },
  card: {
    maxWidth: 300,
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
  },

  joinMeeting: {
    backgroundColor: "#e53935",
    color: "#fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#d32f2f",
    },
  },
  media: {
    paddingTop: "56.25%",
  },
  content: {
    textAlign: "left",
  },
  heading: {
    fontWeight: "bold",
  },
  subheading: {
    lineHeight: 1.8,
  },
  cardDiv: {
    flexGrow: 1,
  },
  pagination: {
    marginTop: theme.spacing(5),
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  scrollableDiv: {
    maxHeight: "calc(75vh - 2rem)", 
    overflowY: "auto",
    cursor:"pointer"
  },
}));

const ActiveCoupons = () => {
  const classes = useStyles();
  const[coupons, setCoupons] = useState(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [updates, setUpdates] = useState([]);

  useEffect(() => {
    const onSuccess = (res) => {
      setUpdates(res.releaseNotes)
      console.log("updates", updates)
    }
    const onError = (err) => {
      toast.error(err);
    }
   GetAllUpdates(onSuccess, onError);
},[])


//  useEffect(() => {
//     var data = {
//       forKids: false,
//       couponValidFor: ["Subscription", "Course"],
//       subject: ["IELTS" , "English"]
//     }

//     const onSuccess = (res) => {
//       console.log(res)
//       setCoupons(res)
//       setLoading(false);
//     }

//     const onError = (msg) => {
//       console.log(msg)
//       setLoading(false);
//     }

//    dispatch(GetAllActiveCoupons(data, onSuccess, onError))
// },[])
// console.log("coip", coupons)

// const copyToClipboard = (text) => {
//   navigator.clipboard.writeText(text)
//     .then(() => {
//       toast.success("Coupon code copied to clipboard!");
//     })
//     .catch((error) => {
//       console.error("Unable to copy coupon code: ", error);
//     });
// };

  return (
    <Container className={classes.root} style={{display:"flex", flex:"column", gap:"2rem"}}>
      <Paper mx="auto" style={{width:"100%"}}>
      <h1 className="heading" style={{textAlign:"center",padding:"1rem"}}>Updates & Releases</h1>
      <hr/>
      <div className={classes.scrollableDiv} style={{display: 'flex', flexDirection:"row", width:"100%", paddingBottom:"1rem", gap:"2rem", justifyContent:"space-between"}}>

      <div style={{display: 'flex', flexDirection:"column" , gap:"3rem", padding:"1rem", width:"60%"}}>
      <div style={{fontWeight:"bold"}}>Title</div>
      {updates.length !==0 ? updates.map((data) => 
      <div style={{fontSize:"0.8rem"}}><a href={data.pdfLink} target="_blank" style={{color:"black"}}>{data?.title}</a></div>
      ): "N/A"}
      </div>

      <div style={{display: 'flex', flexDirection:"column" , gap:"3rem", paddingTop:"1rem", width:"20%"}}>
      <div style={{fontWeight:"bold"}}>Date</div>
      {updates.length !==0 ? updates.map((data) => 
      <div style={{fontSize:"0.8rem"}}>{moment(data.createdAt).format("DD-MM-YYYY")}</div>
      ):"N/A"}
      </div>


      <div style={{display: 'flex', flexDirection:"column" , gap:"2.3rem", paddingTop:"1rem", paddingRight:"1rem", width:"20%"}}>
      <div style={{fontWeight:"bold"}}>Platform</div>
      {updates.length !==0 ? updates.map((data) => 
      <div style={{fontSize:"0.8rem"}}>{data.platform.join(", ")}</div>
       ): "N/A"}
      </div>
      </div>
    </Paper>
    </Container>
  );
};

export default ActiveCoupons;