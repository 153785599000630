import React, { useEffect } from "react";

import {
  Container,
  Paper,
  Box,
  Typography,
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CardContent,
  CardMedia,
  Button,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  GetBlocklearner,
  UnBlocklearner,
} from "../redux/actions/SessionAction";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.grey[300],
    paddingTop: theme.spacing(3),
  },
  card: {
    maxWidth: 300,
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
  },

  joinMeeting: {
    backgroundColor: "#e53935",
    color: "#fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#d32f2f",
    },
  },
  media: {
    paddingTop: "56.25%",
  },
  content: {
    textAlign: "left",
    padding: `${theme.spacing.unit * 2}px`,
  },
  divider: {
    margin: `${theme.spacing.unit * 3}px 0`,
  },
  heading: {
    fontWeight: "bold",
  },
  subheading: {
    lineHeight: 1.8,
  },
  cardDiv: {
    flexGrow: 1,
  },
  pagination: {
    marginTop: theme.spacing(5),
  },
}));

const BlockedLearner = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openSnackbar, closeSnackbar] = useSnackbar();

  useEffect(() => {
    dispatch(GetBlocklearner());
  }, []);
  const BlockLearners = useSelector(
    (state) => state.TeacherReducer.blockedLearners
  );

  const unblockLearner = (id) => {
    var data = {
      learnerId: id,
    };
    var onSuccess = (msg) => {
      openSnackbar(msg);
    };
    var onError = (msg) => {
      openSnackbar(msg);
    };
    dispatch(UnBlocklearner(data, onSuccess, onError));
  };

  return (
    <Container className={classes.root} onScroll={(e) => console.log("scroll")}>
      <Paper component={Box} p={2} mx="auto">
        <h1 className="heading">Blocked Learner</h1>
        <Box mt={3} className={classes.cardDiv} mx="auto">
          <Grid container spacing={3}>
            {BlockLearners ? (
              BlockLearners.length > 0 ? (
                BlockLearners.map((data) => (
                  <Grid item xs={12} sm={4} key={data._id}>
                    <Card className={classes.card}>
                      <CardMedia
                        className={classes.media}
                        image={
                          "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                        }
                      />
                      <CardContent className={classes.content}>
                        <Typography
                          className={"MuiTypography--heading"}
                          variant={"h6"}
                          gutterBottom
                        >
                          {data.name ? data.name : "Not available"}
                        </Typography>
                      </CardContent>
                      <Button
                        variant="contained"
                        fullWidth={true}
                        color="primary"
                        onClick={() => unblockLearner(data._id)}
                      >
                        Unblock Learner
                      </Button>
                    </Card>
                  </Grid>
                ))
              ) : (
               
                  <h1 className="not-available">Blocked learners not found</h1>
                
              )
            ) : (
              <Box style={{ marginLeft: "50%" }}>
                <CircularProgress />
              </Box>
            )}
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
};

export default BlockedLearner;
