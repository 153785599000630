import { io } from "socket.io-client";
import { FilterTimeSlotWithoutSplit, getDevice, teacherTimeArr } from "../../helper/sessionHelper";
import api from "../apis/SessionApi";
import zoomapi from "../apis/ZoomApis";
import * as constant from "../constants/TeacherConstants";

export const SessionAttendance = (data,onSuccess,onError) => (dispatch) => {
  api
    .Session()
    .sessionAttendance(data)
    .then((res) => {
      console.log(res);
      onSuccess()
    })
    .catch((err) => {
      console.log(err);
      onError(err.response.data.message)
    });
};
export const TeacherFeedback = (data, onSuccess, onError) => (dispatch) => {
  api
    .Session()
    .teacherFeedback(data)
    .then((res) => {
      console.log(res);
      onSuccess();
    })
    .catch((err) => {
      console.log(err);
      onError();
    });
};

export const GetDemoRequests = ( onSuccess, onError) => (dispatch) => {
  api
    .Session()
    .getDemoRequests()
    .then((res) => {
      console.log(res);
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err);
      onError();
    });
};

export const GetPseudoDemoRequest = ( onSuccess, onError) => (dispatch) => {
  api
    .Session()
    .getPseudoDemoSlots()
    .then((res) => {
      console.log(res);
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err);
      onError();
    });
};

export const AcceptDemoRequests = (data, onSuccess, onError) => (dispatch) => {
  api
    .Session()
    .acceptDemoRequests(data)
    .then((res) => {
      console.log(res);
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err);
      onError();
    });
};

export const syncTeacer = (data) => (dispatch) => {
  console.log(data);
  api
    .Session()
    .SyncTeacer(data)
    .then((res) => {
      console.log(res.data);
    })
    .catch((err) => {
      console.log(err.response);
    });
};

export const TecherPandingSession = (data) => (dispatch) => {
  api
    .Session()
    .teacherPandingRequest(data)
    .then((res) => {
      dispatch({
        type: constant.FETCH_TEACHER_PENDING_REQUEST,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const AcceptOrDecline = (data, onSuccess, onError) => (dispatch) => {
  api
    .Session()
    .acceptORdecline(data)
    .then((res) => {
      console.log(res);
      dispatch({
        type: constant.TEACHER_DECLINE,
        payload: data.sessionID,
      });
      onSuccess();
    })
    .catch((err) => {
      console.log(err);
      onError();
    });
};

export const TecherUpcomingSession = (data,reload, onSuccess, onError) => (dispatch) => {
    api
      .Session()
      .teacherUpcomingSession(data)
      .then((res) => {
     
        if (onSuccess) {
          onSuccess();
        }
        var response;
        if(reload === "reload"){
        response = {...res.data,reload:reload}
        }else{
          response = {...res.data}
        }
        dispatch({
          type: constant.TEACHER_UPCOMING_SESSION,
          payload: response,
        });
      })
      .catch((err) => {
        if (onError) {
          onError();
        }
        console.log(err);
      });
  };

export const TecherPastSession = (data, onSuccess, onError,isMenteeSession) => (dispatch) => {
  api
    .Session()
    .teacherPastSession(data)
    .then((res) => {
      // console.log(res);
      if (onSuccess) {
        onSuccess(res);
      }

      if(!isMenteeSession){
        dispatch({
          type: constant.TEACHER_PAST_SESSION,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      if (onError) {
        onError();
      }
      // console.log(err.response.data);
    });
};

export const TeacherWeeklyTiming = (data, onSuccess, onError) => (dispatch) => {
  console.log(data);
  api
    .Session()
    .teacherWeeklyTiming(data)
    .then((res) => {
      console.log(res.data);
      onSuccess(res.data.message);
      dispatch({
        type: constant.TEACHER_TIMING,
        payload: res.data.InsertedSchedule,
      });
    })
    .catch((err) => {
      onError(err.response.data.message);
      console.log(err.response);
    });
};

export const TeacherWeeklyTimingKids = (data, onSuccess, onError) => (dispatch) => {
  console.log(data);
  api
    .Session()
    .teacherWeeklyKidsTiming(data)
    .then((res) => {
      console.log(res.data);
      onSuccess(res.data.message);
      dispatch({
        type: constant.TEACHER_TIMING,
        payload: res.data.InsertedSchedule,
      });
    })
    .catch((err) => {
      onError(err.response.data.message);
      console.log(err.response);
    });
};

export const GetTeacherTiming = (data,onSuccess,onError) => (dispatch) => {

  api
    .Session()
    .getTeacherTiming(data)
    .then((res) => {
      if(onSuccess){
        onSuccess([teacherTimeArr(res.data.teacherSchedule)])
      }else{
      dispatch({
        type: constant.TEACHER_TIMING,
        payload: [teacherTimeArr(res.data.teacherSchedule)],
      });
     }
    })
    .catch((err) => {
      if(onError){
        onError(err)
      }
      console.log(err);
    });
};

export const GetTeacherBreaks = (data,onSuccess,onError) => (dispatch) => {

  api
    .Session()
    .getTeacherBreaks(data)
    .then((res) => {
      if(onSuccess){
        onSuccess([FilterTimeSlotWithoutSplit(res.data.breaks)])
      }
      dispatch({
        type: constant.TEACHER_TIMING,
        payload: [FilterTimeSlotWithoutSplit(res.data.breaks)],
      });
    })
    .catch((err) => {
      if(onError){
        onError(err)
      }
      console.log(err);
    });
};

export const GetTeacherTimingKids = (data) => (dispatch) => {
  api
    .Session()
    .getTeacherTiming(data)
    .then((res) => {
      console.log(res.data.teacherSchedule);

      dispatch({
        type: constant.TEACHER_TIMING_KIDS,
        payload: [teacherTimeArr(res.data.teacherSchedule)],
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const UpdateTeacherTiming = (data, onSuccess, onError) => (dispatch) => {
  api
    .Session()
    .updateTeacherTiming(data)
    .then((res) => {
      console.log(res);
      onSuccess(res.data.message);
      dispatch({
        type: constant.TEACHER_TIMING,
        payload: [data],
      });
    })
    .catch((err) => {
      onError(err.response.data.message);
      console.log(err.response);
    });
};

export const AddOrUpdateBreaks = (data, onSuccess, onError) => (dispatch) => {
  api
    .Session()
    .addOrUpdateBreaks(data)
    .then((res) => {
      console.log(res);
      onSuccess(res.data.message);
      dispatch({
        type: constant.TEACHER_TIMING,
        payload: [data],
      });
    })
    .catch((err) => {
      onError(err.response.data.message);
      console.log(err.response);
    });
};

export const ZoomMeetingRecording = (data, onSuccess, onError) => (dispatch) => {
    //SIGNUP LEARNER
    console.log(data);
    zoomapi
      .Zoom()
      .getMeetingrecording(data)
      .then((res) => {
        console.log(res);
        onSuccess(res.data.meeting);
      })
      .catch((err) => {
        console.log(err);
        onError();
      });
  };

export const TeacherNotesBySessionCount = (data, onSuccess, onError) => (dispatch) => {
    //SIGNUP LEARNER
    console.log(data);
    api
      .Session()
      .TeacherNotes(data)
      .then((res) => {
        console.log(res.data);
        onSuccess(res.data.note);
      })
      .catch((err) => {
        console.log(err);
        onError("Document not found");
      });
  };

  export const TeacherEarnings = (data, onSuccess, onError) => (dispatch) => {
    //SIGNUP LEARNER
    api
      .Session()
      .teacherEarning(data)
      .then((res) => {
        console.log(res.data);
        onSuccess(res.data);
      })
      .catch((err) => {
        console.log(err);
        onError(err.response);
      });
  };

export const IsSessionAttended = (data, onSuccess, onError) => (dispatch) => {
  console.log(data);
  api
    .Session()
    .MarkAttended(data)
    .then((res) => {
      console.log(res);
      // console.log(res.data);
      onSuccess(res.data.message);
    })
    .catch((err) => {
      console.log(err.response.data.message);
      onError(err.response.data.message);
    });
};

export const MarkCertificate = (data, onSuccess, onError) => (dispatch) => {
  console.log(data);
  api
    .Session()
    .MarkCertification(data)
    .then((res) => {
      console.log(res);
      // console.log(res.data);
      onSuccess(res.data.message);
    })
    .catch((err) => {
      console.log(err.response.data.message);
      onError(err.response.data.message);
    });
};

export const Teacherfeedback = (data, onSuccess, onError) => (dispatch) => {
 
  api
    .Session()
    .TeacherFeedback(data)
    .then((res) => {
      console.log(res);
      dispatch({
        type:constant.ADD_FEEDBACK,
        payload:res.data.ratingData
      })
      onSuccess(res.data.message);

    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message);
    });
};

export const TeacherfeedbackV2 = (data, onSuccess, onError) => (dispatch) => {
 
  api
    .Session()
    .TeacherFeedbackV2(data)
    .then((res) => {
      console.log(res);
      dispatch({
        type:constant.ADD_FEEDBACK,
        payload:res.data.ratingData
      })
      onSuccess(res.data.message);

    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message);
    });
};

export const Blocklearner = (data, onSuccess, onError) => (dispatch) => {
  console.log(data);
  api
    .Session()
    .BlockLearner(data)
    .then((res) => {
      console.log(res);
      onSuccess(res.data.message);
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message);
    });
};

export const UnBlocklearner = (data, onSuccess, onError) => (dispatch) => {
  api
    .Session()
    .UnBlockLearner(data)
    .then((res) => {
      console.log(res);
      onSuccess(res.data.message);
      dispatch({
        type: "REMOVE_LEARNER_FROM_BLOCK_LIST",
        payload: data.learnerId,
      });
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message);
    });
};

export const GetBlocklearner = () => (dispatch) => {
  api
    .Session()
    .GetBlockLearner()
    .then((res) => {
      dispatch({
        type: constant.GET_BLOCK_LEARNER,
        payload: res.data.blockedLearners,
      });
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: constant.GET_BLOCK_LEARNER,
        payload: []
      });
    });
};

export const GetHomeworkSolution = (data,onSuccess,onError) => dispatch => {

  api.Session().getHomeworkSolution(data)
      .then(res => {
        onSuccess(res.data)
        //   dispatch({
        //     type: constant.GET_HOMEWORKS,
        //     payload: res.data.assignedHomeworks
        // })
      })
      .catch(err => {
          console.log(err.response)
          onError(err.response.data.message)
        })
}

export const GetTimingSubjectWise = (subj,onSuccess,onError) => dispatch => {

  api.Session().getTimingSubjectWise(subj)
      .then(res => {
        onSuccess(res.data.subjectwiseSlots)
        //   dispatch({
        //     type: constant.GET_HOMEWORKS,
        //     payload: res.data.assignedHomeworks
        // })
      })
      .catch(err => {
          console.log(err.response)
          onError(err.response.data.message)
        })
}


export const AddSessionScore = (score,onSuccess,onError) => dispatch => {

  api.Session().addSessionScore(score)
      .then(res => {
        onSuccess(res.data.message)
      })
      .catch(err => {
          console.log(err.response)
          onError(err.response.data.message)
        })
}


export const HotLead = (subj,hotLeadSub,onSuccess,onError) =>  {

  api.Session().hotLead(subj,hotLeadSub)
      .then(res => {
        console.log("success")
        onSuccess()
      })
      .catch(err => {
        console.log(err.response)
          onError()
        })
}

export const TeacherAttendance = (data,params,onSuccess,onError) => dispatch =>  {

  api.Session().teacherAttendance(data,params)
      .then(res => {
        onSuccess(res.data.sessionReport)
      })
      .catch(err => {
        console.log(err.response)
          onError(err.response.data.message)
        })
}

export const GetFeedbackAndRating = (data,onSuccess,onError) => {
  api.Session().getFeedbacks(data)
      .then(res => {
        onSuccess(res.data)
      })
      .catch(err => {
        console.log(err.response)
          onError(err.response.data.message)
        })
}

export const GetTeacherLeaderboard = (onSuccess,onError) => {
  api.Session().getTeacherLeaderboard()
      .then(res => {
        onSuccess(res.data)
      })
      .catch(err => {
        console.log(err.response)
          onError(err.response.data.message)
        })
}

export const MakeCall = (data,onSuccess,onError) => {
  api.Session().makeCall(data)
      .then(res => {
        onSuccess(res.data)
      })
      .catch(err => {
        console.log(err.response)
          onError(err.response.data.message)
        })
}

export const FilterFeedbackByRatingType = (data,onSuccess,onError) => {
  api.Session().getFeedbacksByRatingType(data)
      .then(res => {
        onSuccess(res.data)
      })
      .catch(err => {
        console.log(err.response)
          onError(err.response.data.message)
        })
}

export const CancelAndReassign = (data,onSuccess,onError) => dispatch =>  {
  api.Session().cancelAndReassign(data)
    .then(res => {
      dispatch({
        type:constant.CANCEL_AND_REASSIGN,
        payload:data.sessionId
      })
      onSuccess(res)
    })
    .catch(err => {
      console.log(err.response)
      onError(err.response.data.message)
  })
}


export const GetPayouts = (data,onSuccess,onError) =>  {
  api.Session().getPayouts(data)
    .then(res => {
      onSuccess(res.data)
    })
    .catch(err => {
      console.log(err.response)
      onError(err.response.data.message)
  })
}

export const WithdrawAmount = (data,onSuccess,onError) =>  {
  api.Session().withdrawAmount(data)
    .then(res => {
      onSuccess(res.data)
    })
    .catch(err => {
      console.log(err.response)
      onError(err.response.data.message)
  })
}

export const GetFeedBackForNextTutor = (data,onSuccess,onError) =>  {
  api.Session().getFeedBackForNextTutor(data)
    .then(res => {
      onSuccess(res.data)
    })
    .catch(err => {
      console.log(err.response)
      onError(err.response.data.message)
  })
}

export const GetAssignedLearner = async(teacherId,onSuccess,onError) => {
  try {
    const res = await api.Session().getAssignedLearner({teacherId})
    onSuccess(res.data.allAssignedLearners)
  } catch (err) {
    console.log(err.response)
    onError(err.response.data.message)
  }
}


export const bookSession = async(data,onSuccess,onError) =>{
  try {

    let {startTime,endTime,date,duration,subject,type,learnerId,teacherId,timezone, topicId, certificateId, level, psuedoSlotId} = data;

        let meetingData = {
          learner: learnerId, 
          teacher_: teacherId, 
          startTime: startTime,
          endTime: endTime,
          sessionDate: date,
          isAdmin: false,
          isWebsite: true,
          timezone: timezone,
          subject:subject,
          source: getDevice(),
          type:type,
          sessionDuration:`${duration}min`,
          forKids: false,
          sdkType: "ZOOM",
          isChatBooking: data?.isChatBooking || false,
        };

        if(topicId){
          meetingData["topicId"] = topicId;
        }
        if(psuedoSlotId){
          meetingData["psuedoSlotId"] = psuedoSlotId;
        }
        if(certificateId && level){
          meetingData["certificateId"] = certificateId;
          meetingData["level"] = level;
        }
        if(data.instantBookingRequestId) meetingData["instantBookingRequestId"] = data.instantBookingRequestId

        const createSessionRes = await api.Session().createSession(meetingData);

        if(createSessionRes.status === 201){
          onSuccess(createSessionRes.data.teacherData)
        }

    } catch (err) {
    console.log(err)
    onError(err.response.data.message)
  }
}

export const RaiseDispute = (data, onSuccess, onError) => (dispatch) => {
  api
    .Session()
    .raiseDispute(data)
    .then((res) => {
      console.log("dispute", res);
      onSuccess();
    })
    .catch((err) => {
      console.log(err);
      onError();
    });
};

export const getTags = (onSuccess,onError) => (dispatch) => {
  api
    .Session().tags()
    .then((res) => {
        onSuccess(res.data)
    })
    .catch((err) => {
      console.log(err.response);
      onError(err)
    });
};

export const GetTeacherSlots = (onSuccess,onError,data) => (dispatch) => {
  api
    .Session().getTeacherSlots(data)
    .then((res) => {
        onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      onError(err)
    });
};

export const RaiseConcernTeacher = (data,onSuccess, onError) => (dispatch) => {
  api 
  .Session().raiseConcernByTeacher(data)
  .then((res) => {
    console.log(res);
    onSuccess(res);
})
.catch((err) => {
  console.log(err)
  onError(err.response.data.message)
});
}

export const teachersConcern = (onSuccess, onError) => {
  api
    .Session()
    .teacherConcern()
    .then((res) => {
      console.log(res)
     onSuccess(res.data)
  })
  .catch((err) => {
    console.log(err);
    onError(err)
  });
};


export const GetTeacherConvStats = (data, onSuccess, onError) => (dispatch) => {
  api 
  .Session().getTeacherConvStats(data)
  .then((res) => {
    dispatch({
      type: constant.LOAD_CONVERSION_STATS,
      payload: res.data
    })
    onSuccess(res);
})
.catch((err) => {
  console.log(err)
  onError(err.response.data.message)
});
}

export const ReportTechIssue = (data, onSuccess, onError) => (dispatch) => {
  api 
  .Session().reportTechIssue(data)
  .then((res) => {
    onSuccess(res.data.message);
})
.catch((err) => {
  console.log(err)
  onError(err.response.data.message)
});
}

export const InitializeInstantBookingSocket = (teacherid, onInit, onConnect) => (dispatch) => {
  const localStorageToken = JSON.parse(localStorage.getItem("TOKEN"));
  const socket = io(process.env.REACT_APP_INSTANT_BOOKING_SERVER, {
    auth: { token: localStorageToken },
    withCredentials: true,
    reconnection: false,
    requestTimeout: 10000,
    extraHeaders:{
      teacherid
    }
  });
  onInit(socket);
  socket.on("connect", ()=>{
    dispatch({
        type: constant.LOAD_INSTANT_BOOKING_SOCKET,
        payload: socket
    })
    onConnect();
  })
}

export const GetAllDemoRequests = (data, onSuccess, onError) => (dispatch) => {
  api 
  .Session().getAllDemoRequests(data)
  .then((res) => {
    onSuccess(res.data);
    let action;
    if(data.page===1 && data.limit) action="load";
    else if(data.page>1 && data.limit) action="update";

    dispatch({
      type: constant.LOAD_INSTANT_BOOKING_REQUESTS,
      payload: {action, requests: res.data.activeInstantBookingRequest, count: res.data.totalInstantRequest}
    })
})
.catch((err) => {
  console.log(err)
  onError(err.response.data.message)
});
}

export const UpdateInstantBookingRequests = (data) => (dispatch) => {
  dispatch({
    type: constant.UPDATE_INSTANT_BOOKING_REQUESTS,
    payload: data
  })
}

export const DeleteInstantBookingRequest = (data) => (dispatch) => {
  dispatch({
    type: constant.DELETE_INSTANT_BOOKING_REQUESTS,
    payload: data
  })
}

export const DeclineDemoRequest = (data, onSuccess, onError) => (dispatch) => {
  api 
  .Session().declineDemoRequest(data)
  .then((res) => {
    dispatch(DeleteInstantBookingRequest({learnerId: data.learnerId, decCount: true}));
  })
  .catch((err) => {
    console.log(err)
    onError(err.response.data.message)
  });
}

export const UpdateInstantBookingRequestsCount = () => (dispatch) => {
  api 
  .Session().getAllDemoRequests({page: 1, limit: 0})
  .then((res) => {
    dispatch({
      type: constant.UPDATE_INSTANT_BOOKING_REQUESTS_COUNT,
      payload: res.data.totalInstantRequest
    })
  })
  .catch((err) => {
    console.log(err)
  });
}

export const GetAllActiveCoupons = (data,onSuccess, onError) => (dispatch) => {
  api 
  .Session().getAllCoupon(data)
  .then((res) => {
    console.log(res.data);
    onSuccess(res.data);
})
.catch((err) => {
  console.log(err)
  onError(err.response.data.message)
});
}


export const salesPersonLearnerDetails = (data,onSuccess, onError) => (dispatch) => {
  api 
  .Session().salesTeamlearnerDetails(data)
  .then((res) => {
    onSuccess(res.data);
})
.catch((err) => {
  console.log(err)
  onError(err.response.data.message)
});
}

export const GetAllUpdates = (onSuccess, onError) => {
  api 
  .Session().getAllUpdates()
  .then((res) => {
    onSuccess(res.data);
})
.catch((err) => {
  console.log(err)
  onError(err.response.data.message)
});
}


export const GetAllAIFeedback = (userId,onSuccess,onError) => {

  api.Session().getAllAIFeedback(userId)
      .then(res => {
        onSuccess(res.data)
      })
      .catch(err => {
          console.log(err.response)
          onError(err.response.data.message)
        })
}