export const appointments = [
    {
      title: 'Clapingo Session 1',
      startDate: new Date(2021, 2, 4, 10, 0),
      endDate: new Date(2021, 2, 4, 10, 15),
      id: 0,
      location: 'Room 1',
    },
    {
      title: 'Clapingo Session2',
      startDate: new Date(2021, 1, 20, 10, 20),
      endDate: new Date(2021, 1, 20, 10, 45),
      id: 1,
      location: 'Room 2',
    },
    {
      title: 'Clapingo Session3',
      startDate: new Date(2021, 1, 20, 12),
      endDate: new Date(2021, 1, 20, 12, 25),
      id: 2,
      location: 'Room 3',
    },
    {
      title: 'Clapingo Session 4',
      startDate: new Date(2021, 1, 21, 12,25),
      endDate: new Date(2021, 1, 21, 12, 30),
      id: 3,
      members: [1, 2],
      location: 'Room 1',
    },
    {
      title: 'Clapingo Session 4',
      startDate: new Date(2021, 1, 17, 12,25),
      endDate: new Date(2021, 1, 17, 12, 30),
      id: 4,
      location: 'Room 2',
    }
  ]

