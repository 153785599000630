import amplitude from "amplitude-js";
const teacher = JSON.parse(localStorage.getItem("TEACHER"));

export const initAmplitude = () =>{
    const options = {
        saveEvents: true,
        includeUtm: true,
        includeReferrer: true
    }
    amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_KEY,null,options);
    console.log("Amplitude triggered")
    
    if (teacher) {
        amplitude.getInstance().setUserId(teacher?._id);
    }
}

export const setUserId = (teacherId) =>{
    amplitude.getInstance().setUserId(teacherId);
}

export const loginSuccessEvent = (eventProps) => {
    console.log("amplitude: login_success", eventProps);
    setUserId(eventProps.tutor_id);
    amplitude.getInstance().setUserProperties(eventProps);
    amplitude.getInstance().logEvent("login_success", eventProps);
}

export const loginFailEvent = (eventProps) => {
    console.log("amplitude: login_fail", eventProps);
    amplitude.getInstance().logEvent("login_fail", eventProps);
}

// export const demoBookedEvent = (eventProps) => {
//     console.log("amplitude: demo_booked", eventProps);
//     amplitude.getInstance().logEvent("demo_booked", eventProps);
// }

// export const demoAttendedEvent = (eventProps) => {
//     console.log("amplitude: demo_attended", eventProps);
//     amplitude.getInstance().logEvent("demo_attended", eventProps);
// }

export const feedbackSentEvent = (eventProps) => {
    console.log("amplitude: feedback_sent", eventProps);
    amplitude.getInstance().logEvent("feedback_sent", eventProps);
}