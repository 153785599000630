import React, { useState, useEffect } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Container,
  Paper,
  Box,
  Grid,
  CircularProgress,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  TecherUpcomingSession,
  GetHomeworkSolution
} from "../redux/actions/SessionAction";
import PdfViewer from "../component/PdfViewer";
import { UpcomingSessionCard } from "../component/SessionCard";
import {useLocation} from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  requirement: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  card: {
    position: "relative",
    maxWidth: 300,
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
  },

  ribbon: {
    backgroundColor: '#a31545',
    position: "absolute",
    color: 'white',
    width: 150,
    zIndex: 3,
    textAlign: 'center',
    textTransform: 'uppercase',
    padding: 5,
    font: 'Lato',
    '&::before': {
        position: "absolute",
        zIndex: -1,
        content: '',
        display: 'block',
        border: '5px solid #2980b9',
    },
    '&::after': {
        position: "absolute",
        zIndex: -1,
        content: '',
        display: 'block',
        border: '5px solid #2980b9',
    },
    transform: 'rotate(-45deg)',
    top: 20,
    marginLeft: -40,
},

  joinMeeting: {
    backgroundColor: "#e53935",
    color: "#fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#d32f2f",
    },
  },
  media: {
    paddingTop: "56.25%",
  },
  content: {
    textAlign: "left",
    padding: `${theme.spacing.unit * 2}px`,
  },
  divider: {
    margin: `${theme.spacing.unit * 3}px 0`,
  },
  heading: {
    fontWeight: "bold",
  },
  subheading: {
    lineHeight: 1.8,
  },
  cardDiv: {
    flexGrow: 1
  },
  pagination: {
    marginTop: theme.spacing(5),
  },
}));

const UpcommingSession = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const time_zone = JSON.parse(localStorage.getItem("TIMEZONE"));
  var page_no = 1;
  const [pdfURL, setPdfUrl] = useState(null);
  const teacher = JSON.parse(localStorage.getItem("TEACHER"));
  const [pages, setPages] = useState(2);
  const [loder, setLoder] = React.useState(false)

  const UpcommingSession = useSelector(
    (state) => state.TeacherReducer.upcomingSession
  );

  const {search} = useLocation();
  const requestid = (new URLSearchParams(search)).get("requestid");

  useEffect(() => {
    if(teacher){
    dispatch(TecherUpcomingSession({teacherID: teacher._id,page:1, limit:10,timezone:time_zone.timezone},"reload"))
    }
  }, [])

 
  const upcoming = () => {
    setLoder(true)
    const data = {
      teacherID: teacher._id, page: pages, limit: 10, timezone: time_zone.timezone
    }
    const onSuccess = (msg) => {
      setLoder(false)
    };
    const onError = (msg) => {
      setLoder(false)
    };

    dispatch(TecherUpcomingSession(data, onSuccess, onError));
    setPages(pages + 1);

  }

  // const homework = (sessionId) => {
    
  //   var data = {
  //     sessionId: sessionId,
  //   };
  //   var onSuccess = (responses) => {
  //     console.log(responses)
  //   };
  //   var onError = (error) => {
  //     console.log("error")
  //   };
  //   dispatch(GetHomeworkSolution(data, onSuccess, onError));
  // }

  if (pdfURL) {
    return <PdfViewer url={pdfURL} reset={setPdfUrl} />;
  } else {
    return (
      <Container className={classes.root} >
        <InfiniteScroll dataLength={UpcommingSession ? UpcommingSession.length : 0}
        next={() => upcoming()}
        hasMore={true}
        style={{ overflow: "hidden",width:"100%" }}
      >
        <Paper mt={3} style={{padding:"1rem"}} >
          <h1 className="heading">Upcoming Sessions</h1>
          <Box mt={3} className={classes.cardDiv} mx="auto" >
              <Grid container spacing={3}>

                {UpcommingSession ? (
                  UpcommingSession.length > 0 ? (
                    UpcommingSession.map((data, i) => {
                      return (
                        <>
                          <Grid item xs={12} sm={4} key={data._id}>
                             <UpcomingSessionCard sessionData={data} highlight={data?.instantBookingDetails?.id===requestid} />
                          </Grid>
                        </>

                      );
                    })
                  ) : (
                    <Box mx="auto">
                      <Box m={2} p={1} mx="auto">
                        <i
                          className="fas fa-parachute-box fa-5x"
                          style={{ color: "#D2D2D2" }}
                        ></i>
                      </Box>
                      <Box m={1} mx="auto">
                        No session found !
                    </Box>
                    </Box>
                  )
                ) : (
                  <Box mx="auto">
                    <CircularProgress />
                  </Box>
                )}

              </Grid>
          </Box>

          {loder ? 
                <Box style={{marginLeft: '50%'}}>
                <CircularProgress />
              </Box>
              : ""}

          <Box
            className={classes.pagination}
            display="flex"
            alignItems="center"
            justifyContent="center"
          ></Box>
        </Paper>
      </InfiniteScroll>

      </Container>
    );
  }
};

export default UpcommingSession;
