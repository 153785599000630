import React, {useRef} from 'react'
import toast from 'react-hot-toast';
import notificationSound from "../../assets/ringtone.mp3";
import logo from "../../assets/logo.png";
import BookingRequestToast from './BookingRequestToast';
import {useLocation, useHistory} from "react-router-dom"
import { useEffect } from 'react';

const useBookingRequestToast = () => {
  // const [toasts, setToasts] = useState([]);
  const toasts = useRef([]);
  const location = useLocation();
  const history = useHistory();
  const pathnameRef = useRef(location.pathname);
  const lastToastStyle = useRef({zIndex: 10000, right: 0, bottom: 0});
  const audioRef = useRef();

  useEffect(()=>{
    pathnameRef.current=location.pathname;
  },[location.pathname])

  const dismissToast = (id) => {
    toasts.current.shift();
    lastToastStyle.current={
      zIndex: lastToastStyle.current.zIndex-1,
      right: lastToastStyle.current.right-8,
      bottom: lastToastStyle.current.bottom-8
    }
    toast.dismiss(id);
  }

  const dismissToastFromBottom = (id) => {
    let toastExists = toasts.current.findIndex(eid=>eid===id) > -1;
    if(toastExists) toasts.current.pop();
    toast.dismiss(id);
    if(toastExists && !toasts.current.length && audioRef.current) audioRef.current.pause();
  }

  const openBookingRequestToast = ({id, name}) => {
    if(document.hidden) {
      if(Notification.permission==="granted"){
        let notification = new Notification("💥Instant Demo Request", 
        { body : `You've got demo request from ${name}. click to see request`, icon : logo, });
        notification.onclick=function(){
          window.parent.focus();
          history.push(`/instantbooking?requestid=${id}`)
          this.close();
        }
      }
    }
    console.log("mylogsocket", pathnameRef.current)
    if(!audioRef.current) {
      let audio = document.createElement('audio');
      audio.src=notificationSound;
      // audio.style.display = 'none';
      audio.autoplay = false;
      audio.loop = true;
      audio.id= 'ringtone';
      document.body.appendChild(audio);
      // let audio = new Audio(notificationSound);
      audioRef.current = audio;
    }
    audioRef.current.play();
    if(pathnameRef.current === "/instantbooking") return;
    // let toastId = Math.random().toString();
    if(toasts.current.length===4){let removeid = toasts.current.pop(); toast.dismiss(removeid);}
    console.log("toast adding", id, lastToastStyle.current)
    toast((
      <BookingRequestToast toasts={toasts} toastId={id} name={name} key={id} dismissToast={()=>{dismissToast(id); audioRef.current.pause();}}/>
    ), {
      duration: Infinity,
      position: 'bottom-right',
      className: "mytoast",
      id: id,
      style:{right: 80+lastToastStyle.current.right, bottom: 16+lastToastStyle.current.right, zIndex: lastToastStyle.current.zIndex+1, position: 'absolute', opacity: 1, borderRadius: '20px'},
      enterAnimation: "custom-enter 1s cubic-bezier(0.4, 0, 0.2, 1)",
      exitAnimation: "custom-exit 1s cubic-bezier(0.4, 0, 0.2, 1) forwards"
    });
    lastToastStyle.current = {
      zIndex: lastToastStyle.current.zIndex+1,
      right: lastToastStyle.current.right+8,
      bottom: lastToastStyle.current.bottom+8
    }

    // setTimeout(()=>{toast.dismiss(toastId)}, 5000)
    // setToasts([...toasts, toastId]);
    toasts.current = [id, ...toasts.current];
  }

  return {openBookingRequestToast, dismissToastFromBottom};
}

export default useBookingRequestToast;
