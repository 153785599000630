import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import Box from "@material-ui/core/Box";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Alert } from "@material-ui/lab";
import { Card } from "@material-ui/core";
import { Link } from "react-router-dom";

// const url =
//   "https://clapngobucket.s3.amazonaws.com/documents/learner/1)+Learners+document+(TRAVEL)10-compressed.pdf";

const useStyles = makeStyles((theme) => ({
  breadCrump: {
    padding: "0.6rem",
  },
  breadCrumpText: {
    fontSize: "1.3rem",
    fontWeight: "bold",
  },
}));

const PdfViewer = ({url,reset}) => {
  const classes = useStyles();
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  /*To Prevent right click on screen*/
  //   document.addEventListener("contextmenu", (event) => {
  //     event.preventDefault();
  //   });

  /*When document gets loaded successfully*/
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const handleChange = (event, value) => {
    setPageNumber(value);
  };

  return (
    <>
      <Box justifyContent="center" m={2}>
        <Card className={classes.breadCrump}>
          <p className={classes.breadCrumpText}> <Link to="#" onClick={()=>reset(null)}><i className="fas fa-arrow-left"></i></Link> Back</p>
        </Card>
      </Box>

      <div className="main">
        <Document
          file={url}
          className="pdf"
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <div style={{ width: "100%" }}>
          <Alert variant="outlined" severity="success">
            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
          </Alert>

          <Box justifyContent="center" m={3}>
            <Pagination
              count={numPages}
              shape="rounded"
              page={pageNumber}
              onChange={handleChange}
              color="primary"
            />
          </Box>

        </div>
      </div>
    </>
  );
};

export default PdfViewer;
