import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { GetAssignedLearner } from '../redux/actions/SessionAction';
import {
  Container,
  Paper,
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BookSessionModal from '../component/BookSessionModal';
import { CreateRoomID } from '../redux/actions/ChatAction';
import { useHistory } from 'react-router-dom';
import { HistorySharp } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.grey[300],
    paddingTop: theme.spacing(3),
  },
  card: {
    maxWidth: 310,
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
  },

  joinMeeting: {
    backgroundColor: "#e53935",
    color: "#fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#d32f2f",
    },
  },
  media: {
    paddingTop: "56.25%",
  },
  content: {
    textAlign: "left",
    padding: `${theme.spacing.unit * 2}px`,
  },
  divider: {
    margin: `${theme.spacing.unit * 3}px 0`,
  },
  heading: {
    fontWeight: "bold",
  },
  subheading: {
    lineHeight: 1.8,
  },
  cardDiv: {
    flexGrow: 1,
  },
  pagination: {
    marginTop: theme.spacing(5),
  },
}));

const BookSession = () => {
  const classes = useStyles();

  const [learners, setLearners] = useState()

  const teacherData = useSelector((state) => state.TeacherReducer.teacher);

  const history = useHistory();

  useEffect(() => {
    if (teacherData) {
      const onSuccess = (res) => {
        setLearners(res)
      }

      const onError = (err) => {
        console.log(err)
      }

      GetAssignedLearner(teacherData._id, onSuccess, onError)
    }
  }, [teacherData])


  const createRoom = (learnerId) => {

    const data = {
      "teacher": teacherData._id,
      "learner": learnerId
    }
    const onSuccess = (res) => {
      console.log(res)
      history.push(`/chatpage/${res.data.roomId}`)
      // history.push(`/chatpage?roomId=123`)
    }

    const onError = (err) => {
      console.log(err)
    }

    CreateRoomID(data, onSuccess, onError)
  }

  console.log(learners)
  return (
    <Container className={classes.root} onScroll={(e) => console.log("scroll")}>
      <Paper component={Box} p={2} mx="auto">
        <h1 className="heading">Book with assigned learners</h1>
        <Box mt={3} className={classes.cardDiv} mx="auto">
          <Grid container spacing={3}>
            {learners ? (
              learners.length > 0 ? (
                learners.map((data) => (
                  <Grid item xs={12} sm={4} key={data._id}>
                    <Card className={classes.card}>
                      <CardMedia
                        className={classes.media}
                        image={
                          "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                        }
                      />
                      <CardContent className={classes.content}>
                        <Typography
                          className={"MuiTypography--heading"}
                          variant={"h6"}
                          gutterBottom
                        >
                          {data.name ? data.name : "Not available"}
                        </Typography>
                        <Box sx={{display: "flex", gap: 10}}>
                        <Button variant="contained" color="primary" onClick={()=>createRoom(data._id)} >Message</Button>
                        <BookSessionModal learner={data} />
                        </Box>
                      </CardContent>
                      
                    </Card>
                  </Grid>
                ))
              ) : (

                <h1 className="not-available">You don't have any assigned learner</h1>

              )
            ) : (
              <Box style={{ marginLeft: "50%" }}>
                <CircularProgress />
              </Box>
            )}
          </Grid>
        </Box>
      </Paper>
    </Container>
  )
}

export default BookSession