import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { AddSessionScore } from "../redux/actions/SessionAction";
import { useSnackbar } from "react-simple-snackbar";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  MuiTextFieldroot: {
    margin: theme.spacing(1),
    width: "25ch",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const SessionScore = ({ sessionData }) => {

    const dispatch = useDispatch()

    const [openSnackbar, closeSnackbar] = useSnackbar();
    const [open, setOpen] = React.useState(false);
    const [loader, setLoader] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [error, setError] = useState({})

  const submitScore = (e)=>{
      e.preventDefault();

      var error = {}

      if(e.target.attentiveness.value === null || e.target.attentiveness.value >5 || e.target.attentiveness.value < 1){
        error["attentiveness"]={isErr:true,helperText:"score must be more than 1 & less than 5"}
      }
      if(e.target.participation.value === null || e.target.participation.value >5 || e.target.participation.value < 1){
        error["participation"]={isErr:true,helperText:"score must be more than 1 & less than 5"}
      }
      if(e.target.clarity.value === null || e.target.clarity.value >5 || e.target.clarity.value < 1){
        error["clarity"]={isErr:true,helperText:"score must be more than 1 & less than 5"}
      }
      if(e.target.confidence.value === null || e.target.confidence.value >5 || e.target.confidence.value < 1){
        error["confidence"]={isErr:true,helperText:"score must be more than 1 & less than 5"}
      }
      if(e.target.grammar.value === null || e.target.grammar.value >5 || e.target.grammar.value < 1){
        error["grammar"]={isErr:true,helperText:"score must be more than 1 & less than 5"}
      }
      if(e.target.sentence.value === null || e.target.sentence.value >5 || e.target.sentence.value < 1){
        error["sentence"]={isErr:true,helperText:"score must be more than 1 & less than 5"}
      }
      if(e.target.vocabulary.value === null || e.target.vocabulary.value >5 || e.target.vocabulary.value < 1){
        error["vocabulary"]={isErr:true,helperText:"score must be more than 1 & less than 5"}
      }
      if(e.target.speech.value === null || e.target.speech.value >5 || e.target.speech.value < 1){
        error["speech"]={isErr:true,helperText:"score must be more than 1 & less than 5"}
      }
      if(e.target.correction.value === null || e.target.correction.value >5 || e.target.correction.value < 1){
        error["correction"]={isErr:true,helperText:"score must be more than 1 & less than 5"}
      }
      setError(error)

      const isEmpty = Object.keys(error).length === 0;

      if(isEmpty){
        setLoader(sessionData._id)
        const onSuccess = (message) =>{
          openSnackbar(message)
          setLoader(false)
          handleClose()
          e.target.reset()
        }

        const onError = (message)=>{
            openSnackbar(message)
            setLoader(false)
        }

          var scorePayload = {
            "sessionId": sessionData._id,
            "score": [
                {
                    "evaluationPoint": "Attentiveness & Focus",
                    "rating": e.target.attentiveness.value
                },
                {
                    "evaluationPoint": "Class Participation",
                    "rating": e.target.participation.value
                },
                {
                    "evaluationPoint": "Clarity of Thought",
                    "rating": e.target.clarity.value
                },
                {
                    "evaluationPoint": "Confidence",
                    "rating": e.target.confidence.value
                },
                {
                    "evaluationPoint": "Sentence Formation",
                    "rating": e.target.sentence.value
                },
                {
                    "evaluationPoint": "Grammar",
                    "rating": e.target.grammar.value
                },
                {
                    "evaluationPoint": "Clarity of Speech",
                    "rating": e.target.speech.value
                },
                {
                    "evaluationPoint": "Vocabulary",
                    "rating": e.target.vocabulary.value
                },
                        {
                    "evaluationPoint": "Response to Correction",
                    "rating": e.target.correction.value
                }
            ]
        }
      
        dispatch(AddSessionScore(scorePayload,onSuccess,onError))

      }
      
  }

  return (
    <>
      <button className='score' disabled={sessionData.forKids?false:true} onClick={handleClickOpen}> Session score </button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Add session scores out of 5
        </DialogTitle>
        <DialogContent dividers>
        <form noValidate autoComplete="off" onSubmit={(e)=>submitScore(e)}>
          <div>
            <TextField
              error={error.attentiveness?.isErr}
              id="outlined-number"
              style={{ margin: "0.5rem" }}
              label="Attentiveness & Focus"
              type="number"
              name="attentiveness"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              helperText={error.attentiveness?.helperText}
              required
            />
            <TextField
              style={{ margin: "0.5rem" }}
              id="outlined-number"
              label="Class Participation"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="participation"
              error={error.participation?.isErr}
              helperText={error.participation?.helperText}
              required
            />
          </div>
          <div>
            <TextField
              id="outlined-number"
              style={{ margin: "0.5rem" }}
              label="Clarity of Thought"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="clarity"
              error={error.clarity?.isErr}
              helperText={error.clarity?.helperText}
            />
            <TextField
              style={{ margin: "0.5rem" }}
              id="outlined-number"
              label="Confidence"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="confidence"
              error={error.confidence?.isErr}
              helperText={error.confidence?.helperText}
            />
          </div>
          <div>
            <TextField
              id="outlined-number"
              style={{ margin: "0.5rem" }}
              label="Sentence Formation"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="sentence"
              error={error.sentence?.isErr}
              helperText={error.sentence?.helperText}
            />
            <TextField
              style={{ margin: "0.5rem" }}
              id="outlined-number"
              label="Grammar"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="grammar"
              error={error.grammar?.isErr}
              helperText={error.grammar?.helperText}
            />
          </div>
          <div>
            <TextField
              id="outlined-number"
              style={{ margin: "0.5rem" }}
              label="Clarity of Speech"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="speech"
              error={error.speech?.isErr}
              helperText={error.speech?.helperText}
            />
            <TextField
              style={{ margin: "0.5rem" }}
              id="outlined-number"
              label="Vocabulary"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="vocabulary"
              error={error.vocabulary?.isErr}
              helperText={error.vocabulary?.helperText}
            />
          </div>
          <div>
            <TextField
              id="outlined-number"
              style={{ margin: "0.5rem" }}
              label="Response to Correction"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="correction"
              error={error.correction?.isErr}
              helperText={error.correction?.helperText}
            />

          </div>
          <DialogActions>
          <Button autoFocus type="submit" color="primary" disabled={sessionData._id === loader}>
            {sessionData._id === loader?"Score adding...":"Add score"}
          </Button>
        </DialogActions>
          </form>
        </DialogContent>

      </Dialog>
    </>
  );
};

export default SessionScore;
