import React from 'react'
import {
    Avatar,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Box,
    Tabs,
    Tab,
  } from "@mui/material";
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { formatAMPM } from '../../helper/sessionHelper';
const ChatList = ({learners, chatters, setSelectedLearner,setSelectedLearnerId}) => {
 console.log(learners)
const {pathname} = useLocation();
 
 useEffect(()=>{
  getSelectedLearner();
 },[chatters]);

 const getSelectedLearner = () => {
  if(chatters)
  chatters.forEach((chatter)=>{
    if(pathname===`/chatpage/${chatter?._id}`){
       setSelectedLearner(chatter.learner)
       setSelectedLearnerId(chatter?.learnerId)
      };
  });
 }
  return (
    <Grid className="learners" item xs={12} sm={5} md={3} >
          <Box sx={{padding: "12px 16px", borderBottom: 3.5, borderColor: "#00cdac" }}>
            <Typography variant="h6" sx={{fontWeight: 600, color: "#00000099", fontSize: 18}}>Chat</Typography>
          </Box>
          <List className="learners_list" sx={{ padding: 0 }}>
            {chatters?.map((room) => (
                <NavLink to={`/chatpage/${room?._id}`} key={room?._id} onClick={()=>{
                  setSelectedLearner(room?.learner)
                  setSelectedLearnerId(room?.learnerId)
                  }}>
              <ListItem
                className="item"
                button
                key={room?._id}
                selected={pathname===`/chatpage/${room?._id}`}
                sx={{ gap: 2 }}
              >
                <ListItemIcon>
                  <Avatar
                    alt={room?.learner}
                    src={`https://material-ui.com/static/images/avatar/${room?._id}.jpg`}
                    sx={{ width: 50, height: 50 }}
                  />
                </ListItemIcon>
                <Box sx={{ flex: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <ListItemText className="learner_name" primary={room?.learner} />
                    <Typography variant="subtitle2" color="#00000099">
                      {formatAMPM(new Date(room.recentMessage?.createdAt))}
                    </Typography>
                  </Box>
                  <Box
                  sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <ListItemText
                      className="last_msg"
                      secondary={room.recentMessage?.message}
                    />
                    {room.teacherUnread ? <div className="badge">{room.teacherUnread}</div> : null}
                  </Box>
                </Box>
              </ListItem>
              </NavLink>
            ))}
          </List>
        </Grid>
  )
}

export default ChatList