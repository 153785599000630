import { Box, CircularProgress } from "@material-ui/core";
import moment from "moment";
import React, { memo, useState } from "react";
import MsgBubble from "./MsgBubble";
import { UpdateMessage } from "../../redux/actions/ChatAction";
import { useSnackbar } from "react-simple-snackbar";
import { bookSession } from "../../redux/actions/SessionAction";
import { useSelector } from "react-redux";

const Messages = ({ messages, setMessages, setSessionData, subscriptions, purchasedSessions, selectedLearner, setMessage }) => {
  const dates = new Set();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [bookingLoader, setBookingLoader] = useState(false);
  const teacher = useSelector((state) => state.TeacherReducer.teacher);
  const {timezone} = JSON.parse(localStorage.getItem("TIMEZONE"));


  if (!messages)
    return (
      <Box mx="auto" my="auto">
        <CircularProgress />
      </Box>
    );

  const renderDate = (timestamp, dateNum) => {
    if (dates.has(dateNum)) return <></>;
    let timestampDate = moment(timestamp).format("LL");

    if(timestampDate === moment().format("LL")) timestampDate = "Today";
    else if (timestampDate === moment().subtract(1, 'day').format("LL")) timestampDate = "Yesterday";

    // Add to Set so it does not render again
    dates.add(dateNum);

    return (
      <div className="msg_date">
        {timestampDate}
      </div>
    );
  };

  const createSession = (message, forKids, subject, learner, sessionDate, sessionTime, updateMessageStatus) =>{
    setBookingLoader(true)
    let splittedTime = sessionTime.split(" - ");
    let subs =  subscriptions?.filter((sub)=>sub.forKids===forKids && sub.subjects===subject)?.[0];

    console.log("subs", subs);
    if(forKids){
      if((!subs || !subs?.availableSession) && !purchasedSessions?.sessionsAvailable){ 
        openSnackbar(`${selectedLearner} does't have an active subscription or purchased sessions`);
        return;
      }
    }
    else{
      if(!subs || (subs?.availableSession===0 && subs.subscriptionType === "quick_learner_mode")){ openSnackbar(`${selectedLearner} does't have an active subscription`);return;}
      if(subs.subscriptionType !== "quick_learner_mode"){ 
        let totalAvailableSession = (subs.availableWeeklySession || 0)+subs.backlogSession
        if(totalAvailableSession===0){
        openSnackbar(`${selectedLearner}'s weekly limit reached`);
        return;
        }
      }
    }
    let splitValue = forKids ? 25 : subject==="IELTS" ? 55 : Number(subs?.plan?.classDuration?.replace("min", ""));

    const payload = {
      startTime: splittedTime[0],
      endTime: splittedTime[1],
      date: moment(sessionDate).format("YYYY-MM-DD"),
      duration: splitValue,
      subject: subject,
      type: "Regular",
      learnerId: learner,
      teacherId: teacher._id,
      timezone: timezone,
      isChatBooking: true,
      topicId: message?.topic?._id,
      certificateId: message?.topic?.certificateId,
      level: message?.topic?.level,
    }

    console.log("mylogpayload", payload);

    const onSuccess = (res) =>{
      setBookingLoader(false)
      openSnackbar("Session successfully booked :)")
      updateMessageStatus();
    }

    const onError = (err) =>{
      setBookingLoader(false)
      openSnackbar(err)
    }

    bookSession(payload,onSuccess,onError)

  }

  

  const handleSessionAction = (message, action) => {
    const {_id, subject, learner, sessionDate, sessionTime, forKids, topic} = message
    if(action==="Rejected"){
      setSessionData({_id, subject, forKids, topic});
      setMessage(message);
      return;
    }

    const onSuccess = () => {
      let msg = action==="Booked" ? "Session is Booked" : "Session is Rejected";
      openSnackbar(msg);
      let updatedMessages = messages.map((msg)=>{
        if(msg._id===_id) msg.status="Booked"
        return msg;
      })
      setMessages(updatedMessages)
    }

    const onError = (err) => {
      openSnackbar(err);
    }

    const data = {
      messageId: _id,
      status: action
    }

    console.log("mylogmsg", message)

    createSession(message, forKids, subject, learner, sessionDate, sessionTime, ()=> {
      UpdateMessage(onSuccess, onError, data)
    })
  }



  return messages?.map((message) => {
    const dateNum = moment(message.createdAt).format("DDMMYYYY");
    
    return <React.Fragment key={message._id}>
    {!dates.has(dateNum) ? renderDate(message.createdAt, dateNum) : null}
           {/* {!dates.has(dateNum) ? <RenderDate dates={dates} timestamp={message.createdAt} dateNum={dateNum} text={message.message} addDate={addDate} /> : null} */}
      <MsgBubble message={message} handleSessionAction={(action)=>handleSessionAction(message, action)} />
    </React.Fragment>;
  })       
};

export default memo(Messages);
