import { Box, Drawer, Typography } from "@material-ui/core";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@material-ui/core";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const CustomTableCell = styled(TableCell)({
  borderRight: "1px solid #D9E0DF",
});

const commissionBracket = (conversion) => {
  if (conversion > 15 && conversion < 18.99) {
    return 1;
  } else if (conversion > 19 && conversion < 21.99) {
    return 1.5;
  } else if (conversion > 22) {
    return 2;
  } else {
    return 0;
  }
};

const MentorshipCommissionCriteria = ({
  openMentorshipCommissionDrawer,
  setOpenMentorshipCommissionDrawer,
  avgMenteesConversion,
  commissionAmount,
  mentorConversion,
  avgArpu,
  avgAttendedDemoSessions,
}) => {
  const bonusData = [
    {
      sessionHours: "15 - 18.99",
      bonusAmount: "1",
    },
    {
      sessionHours: "19 - 21.99",
      bonusAmount: "1.5",
    },
    {
      sessionHours: "> 22",
      bonusAmount: "2",
    },
  ];
  return (
    <Drawer
      sx={{ zIndex: 9 }}
      open={openMentorshipCommissionDrawer}
      anchor={"right"}
      onClose={() => setOpenMentorshipCommissionDrawer(false)}
    >
      <Box sx={{ width: 400 }}>
        <Box
          sx={{
            display: "flex",
            p: 2,
            borderBottom: "1px solid #E6EAE9",
            background: "#F2F5F4",
          }}
        >
          <KeyboardBackspaceIcon
            sx={{ cursor: "pointer", mr: 1 }}
            onClick={() => {
              setOpenMentorshipCommissionDrawer(false);
            }}
          />
          <Typography
            style={{
              color: "#082B28",
              fontFamily: "Inter",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "20px",
            }}
          >
            Commission Criteria
          </Typography>
        </Box>
        <Box sx={{ m: 2, p: 4, background: "#F9FBF7" }}>
          <TableContainer>
            <Table
              aria-label="a dense table"
              style={{ border: "1px solid #D9E0DF" }}
            >
              <TableHead>
                <TableRow style={{ backgroundColor: "#F2F5F4" }}>
                  <CustomTableCell align="center">
                    Mentees Conversion %
                  </CustomTableCell>
                  <TableCell align="center">Commission %</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bonusData.map((data, i) => (
                  <TableRow key={i} style={{ background: "white" }}>
                    <CustomTableCell align="center">
                      {data.sessionHours}
                    </CustomTableCell>
                    <TableCell align="center">{data.bonusAmount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              color: "#0E433A",
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "18px",
              mt: 2,
            }}
          >
            {mentorConversion > 0 ? (
              <>
                Your mentees have avg{" "}
                <span
                  style={{
                    fontStyle: "italic",
                    fontWeight: 500,
                  }}
                >
                  {avgMenteesConversion}% conversion
                </span>{" "}
                so you fall into the{" "}
                <span
                  style={{
                    fontStyle: "italic",
                    fontWeight: 500,
                  }}
                >
                  {mentorConversion}%
                  {/* {commissionBracket(avgMenteesConversion)}%  */}
                </span>{" "}
                commission bracket and your commission amount is{" "}
                <span
                  style={{
                    fontStyle: "italic",
                    fontWeight: 500,
                  }}
                >
                  ₹{commissionAmount}.
                </span>
              </>
            ) : (
              <>
                Sorry, you are not qualify for commission - 
                your average mentees conversion rate is <strong style={{color:"#EF4444"}}>{avgMenteesConversion}%</strong>, ARPU is <strong style={{color:"#EF4444"}}>₹{Math.round(avgArpu)}</strong> & Mentees attendance rate is <strong style={{color:"#EF4444"}}>{avgAttendedDemoSessions}%</strong>
              </>
            )}
          </Box>

          <TableContainer>
            <Table
              aria-label="a dense table"
              style={{ border: "1px solid #D9E0DF", marginTop:"15px" }}
            >
              <TableHead>
                <TableRow style={{ backgroundColor: "#F2F5F4" }}>
                  <CustomTableCell align="center">Criteria</CustomTableCell>
                  <CustomTableCell align="center">
                    Current Value
                  </CustomTableCell>
                  <TableCell align="center">Criteria Passed</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  style={{
                    background: "white",
                  }}
                >
                  <CustomTableCell align="center">
                  Min. ₹ 7500 avg. ARPU from mentees
                  </CustomTableCell>
                  <CustomTableCell align="center">₹ {Math.round(avgArpu)}</CustomTableCell>
                  <TableCell align="center">
                    {Math.round(avgArpu) >= 7500 ? (
                      <CheckIcon sx={{ color: "#3EA175" }} />
                    ) : (
                      <CloseIcon sx={{ color: "#EF4444" }} />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{
                    background: "white",
                  }}
                >
                  <CustomTableCell align="center">
                  Min. 150 avg. attended demo session from mentees
                  </CustomTableCell>
                  <CustomTableCell align="center">{Math.round(avgAttendedDemoSessions)}</CustomTableCell>
                  <TableCell align="center">
                    {Math.round(avgAttendedDemoSessions) >= 150 ? (
                      <CheckIcon sx={{ color: "#3EA175" }} />
                    ) : (
                      <CloseIcon sx={{ color: "#EF4444" }} />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Drawer>
  );
};

export default MentorshipCommissionCriteria;
