import { Button,Paper,Tabs,Tab, CircularProgress,Box,AppBar, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { bookSession, GetTeacherTiming } from '../redux/actions/SessionAction';
import { useDispatch, useSelector } from 'react-redux';
import { availableDate, converter, dayToDate, hideTimeSlot, localTimeToUTCConverter, sessionBookingDate, timeSpliter } from '../helper/sessionHelper';
import { useSnackbar } from "react-simple-snackbar";
import moment from 'moment';

function getModalStyle() {
    const top = 50 ;
    const left = 53 ;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
}

const isOverlapping = (splittedStartTime, splittedEndTime, bookedSessionStartTime, bookedSessionEndTime) => {
  return splittedStartTime <= bookedSessionEndTime && bookedSessionStartTime <= splittedEndTime;
};

const checkSlotAvailableOrNot = (startTime,endTime,selectedDate,bookedSession) => {
  startTime = localTimeToUTCConverter(startTime)
  endTime = localTimeToUTCConverter(endTime)

  const date = sessionBookingDate(selectedDate, startTime)

  var returnvalue;
  if (bookedSession) {
    for(var i=0;i<bookedSession.length;i++){
      const session = bookedSession[i];
      const bookedSessionDate = moment(session.sessionDate).format("YYYY-MM-DD");

      if(date===bookedSessionDate && isOverlapping(startTime,endTime,session?.startTime,session?.endTime)) {
            returnvalue = "Reserved";
      }
    }
  }
  return returnvalue;
};
  
const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: '80vw',
      backgroundColor: theme.palette.background.paper,
      borderRadius:'10px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    tabContainer: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    slotContainer:{
      display:"flex",
      flexWrap:"wrap",
      flexDirection: "row"
    },
    selectSlot:{
      padding:"0.6rem",
      margin:"0.3rem",
      border:"1px solid purple",
      borderRadius:"10px",
      cursor:"pointer"
    },
    activeSlot:{
      padding:"0.6rem",
      margin:"0.3rem",
      border:"1px solid purple",
      borderRadius:"10px",
      background:"purple",
      color:"white",
      cursor:"pointer"
    },
    disabledSlot:{
      background:"gray",
      cursor:"not-allowed",
      padding:"0.6rem",
      margin:"0.3rem",
      border:"1px solid purple",
      borderRadius:"10px"
    }
})); 

const BookSessionModal = ({learner}) => {

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

    const [modalStyle] = useState(getModalStyle);
    const classes = useStyles();
    const [openModal, setOpenModal] = useState(false)
    const [value, setValue] = useState(1);
    const [teacherTimings, setTeacherTimings] = useState()
    const [selectedSlot, setSelectedSlot] = useState()
    const [selectedDate, setSelectedDate] = useState()
    const [bookingLoader, setBookingLoader] = useState(false)

    const [openSnackbar, closeSnackbar] = useSnackbar();

    const time_zone = JSON.parse(localStorage.getItem("TIMEZONE"));

    const dispatch = useDispatch();
    const teacherData = useSelector((state) => state.TeacherReducer.teacher);
    const sessionData = useSelector((state) => state.TeacherReducer.upcomingSession);

    const handleChange = (event, newValue) => {
      setValue(newValue);
      setSelectedDate(availableDate(newValue,0));
      setSelectedSlot("");
    };
    
    const openTimeSlotModal = () =>{

      setOpenModal(true)
      const onSuccess = (res) =>{
        setTeacherTimings(res)
      }
      const onError = (err) =>{
        console.log(err)
      }

      const payload = {
          teacherId: teacherData._id,
          forKids: false,
          subject: "IELTS",
      }

      dispatch(GetTeacherTiming(payload,onSuccess,onError))

    }

    const [weekDate, setWeekDate] = useState([])

    useEffect(() => {
      setSelectedDate(availableDate(1,0));
      var dateArr = [];
      Array(7).fill(1).map((_,i) => {
        dateArr.push(availableDate(i,0));
      });
      setWeekDate(dateArr);
    }, [])

    const createSession = () =>{
      setBookingLoader(true)
      if(!selectedSlot) openSnackbar("Please select time slot to book session");
      const splitTime = selectedSlot.split(" - ")
      const startTimeUTC = localTimeToUTCConverter(splitTime[0]);
      const endTimeUTC = localTimeToUTCConverter(splitTime[1]);
      const payload = {
        startTime: startTimeUTC,
        endTime: endTimeUTC,
        date:sessionBookingDate(selectedDate.date, startTimeUTC),
        duration:55,
        subject:"IELTS",
        type:"Regular",
        learnerId:learner._id,
        teacherId:teacherData._id,
        timezone:time_zone.timezone
      }

      console.log("mylogpayload", payload);

      const onSuccess = (res) =>{
        setBookingLoader(false)
        setOpenModal(false)
        openSnackbar("Session successfully booked :)")
      }

      const onError = (err) =>{
        setBookingLoader(false)
        openSnackbar(err)
      }

      bookSession(payload,onSuccess,onError)

    }

  return (
    <>
    <Button variant="contained" color="primary" onClick={() => openTimeSlotModal()}>
      Book a session
    </Button>

    <Modal
        open={openModal}
        onClose={()=>setOpenModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Book a session for {learner?.name?learner?.name:"User"}</h2>
      <Paper square>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
      >
        {
          teacherTimings?
          teacherTimings.length > 0 ? 
          <div className={classes.tabContainer}>
              <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                  {
                    weekDate.map((data,i)=>(
                      <Tab label={data.weekDay} {...a11yProps(i)} />
                    ))
                  }
                </Tabs>
              </AppBar>

              {
                weekDate.map((data,i)=>(
                  <TabPanel value={value} index={i}>
                    <div className={classes.slotContainer}>
                    {
                      teacherTimings[0][data.weekDay].map((timing,key)=>{
                        
                        if(timing !== "N/A"){
                        const splitedTime = timing.split(" - ")
                        let bookingLag = 1;
                        let isHidden = hideTimeSlot(timing.split(" - ").join("-"), bookingLag, time_zone.timezone, selectedDate.date)

                        if(splitedTime[0] === "24"){
                          splitedTime[0] = "00"
                        }
                        if(splitedTime[1] === "00"){
                          splitedTime[1] = "24"
                        }

                        const sessionTime = timeSpliter(`${splitedTime[0]}:00`,`${splitedTime[1]}:00`,55)

                        const isReserved = sessionTime.length >0 ?checkSlotAvailableOrNot(sessionTime[0].start,sessionTime[0].end,selectedDate.date,sessionData):false

                        return isHidden==="hidden" ? null : (<p className={sessionTime[0]?.timeView === selectedSlot?classes.activeSlot : isReserved?classes.disabledSlot: classes.selectSlot} key={key} onClick={()=>{
                            if(!isReserved){
                              setSelectedSlot(sessionTime[0]?.timeView)
                            }
                          }}>{sessionTime[0]?.timeView}</p>);
                        
                        }else{
                          return <p className={classes.selectSlot} key={key}>{timing}</p>
                        }
                           
                        })
                    }
                    </div>
                    <Button variant="contained" color="primary" style={{width:"100%"}} onClick={()=>createSession()} disabled={bookingLoader}>BOOK SESSION</Button>
                  </TabPanel>
                ))
              }
          </div>
          : (<h1 >Timings not available</h1>)
        : (
            <Box style={{ marginLeft: "50%" }}>
              <CircularProgress />
            </Box>
          )
        }
       
      </Tabs>
    </Paper>
    </div>
      </Modal>
    </>
  )
}

export default BookSessionModal