import axios from "axios";
import {PROD_API,DEV_API,LOCAL_HOST,GLOBAL_API} from './ApiConst'

const token = JSON.parse(localStorage.getItem("TOKEN"))

if(token){
    axios.defaults.headers.common["x-access-token"] = token;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Authontication(url = `${process.env.REACT_APP_BASE_URL}/api/`) {
        return {            
            Teacherlogin: (data) => axios.post(url+"teacher/signInTeacher",data),
            askteacherpassword: (data) => axios.post(url+"teacher/askTeacherForOtpOrPassword",data),
            verifyteacherOtp: (data) => axios.put(url+"teacher/verifyOtp",data),
            updatePassword: (data) => axios.put(url+"teacher/updatePassword",data),
            changeTeacherPassword: (data) => axios.put(url+"teacher/changeteacherpassword",data),
            teacherForfotPassword: (data) => axios.post(url+"teacher/forgotpassword",data),
            relodeTeacher: (data) => axios.post(url+"teacher/currentteacher",data),
            // uploadProfile: (data) => axios.post(url+"images/getPreSignedURL",data),
            uploadProfile: (data) => axios.put(url+"teacher/profilesetup",data),
            Query: (data) => axios.post(url+"query/insert",data),
            referralProfile: (id) => axios.get(url+`teacher/getTeacherPublicProfile?referral=${id}`),
            magicLogin: (token) => axios.get(url+`teacher/magicLogin`,{headers: {'x-access-token': token}}),
            addBankDetails:(data) => axios.post(url+`teacher/addBankDetails`,data),
            getBankDetails:() => axios.get(url+`teacher/getTeacherBankDetails`),
            verifyBankAccount:(data) =>axios.post(url+`payouts/verifyBankAccount`,data),
            askForVerification:(data)=>axios.put(url+ `payouts/askForVerification`, data),
            updateTeacherRestoreId:(data)=>axios.put(url+ `teacher/updateTeacherRestoreId`, data),
            resourceUpload: (data) => axios.post(url + `adminauth/getAllResource`, data),
            teacherSignUp: (data) => axios.post(url + "teacher/signupteacher", data),
            tags:() => axios.get(url+`tags`),
            getTeacherApplicationData:(id) => axios.get(url+`teacher/getTeacherApplication/${id}`),
            updateInstantBooking:(data) => axios.post(url+`teacher/updateInstantBooking`, data),
            CuponCode: (data) => axios.post(url+"referral/getReferralOrOffer",data),
            generatePaymentLink: (data) => axios.post(url+"razorpay/generate-payment-link",data),
            getplan: () => axios.get(url+"learner/getFrequenciesAndDurationpublic"),
            getPlans: (phoneNumber) => axios.get(url +`learner/getPlans?phoneNumber=${phoneNumber}`),
        }
    }
}