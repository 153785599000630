import api from "../apis/ChatApis";
import * as constant from "../constants/TeacherConstants";

export const  GetAllMessagesByRoomID = (roomId,onSuccess,onError) => {
    api
      .Chat()
      .getRoomMessage(roomId)
      .then((res) => {
        console.log(res);
        onSuccess(res)
      })
      .catch((err) => {
        console.log(err);
        onError(err.response.data.message)
      });
  };


  export const  CreateRoomID = (data,onSuccess,onError) => {
    api
      .Chat()
      .createRoom(data)
      .then((res) => {
        console.log(res);
        onSuccess(res)
      })
      .catch((err) => {
        console.log(err);
        onError(err.response.data.message)
      });
  };

  export const  GetAllRooms = (onSuccess,onError) => {
    api
      .Chat()
      .getAllRooms()
      .then((res) => {
        console.log(res);
        onSuccess(res)
      })
      .catch((err) => {
        console.log(err);
        onError(err.response.data.message)
      });
  };

  export const  GetAllUnreadMessage = (teacherId,dispatch) => {
    api
      .Chat()
      .getAllUnreadMessage(teacherId)
      .then((res) => {
        dispatch({
              type: constant.SET_UNREAD_MSG,
              payload: res.data.teacherUnreadMessage
        })
      })
      .catch((err) => {
        console.log(err);
      });
  };

  export const SendFile = async(file, chatroomId, onError)=>{
      const formData = new FormData();
      formData.append('chatFile', file);
      formData.append('chatroomId', chatroomId);
      api
      .Chat()
      .sendFile(formData)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        onError(err.response.data.message)
      });
}

export const UpdateMessage = async(onSuccess, onError, data)=>{
  api
  .Chat()
  .updateMessage(data)
  .then((res) => {
    onSuccess(res);
  })
  .catch((err) => {
    console.log(err);
    onError(err.response.data.message)
  });
}