import React,{useEffect} from "react";
import { Route, Redirect } from "react-router-dom";
import BaseLayout from "../layouts/BaseLayout";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MagicLogin } from "../redux/actions/AuthAction";
import { useSelector } from "react-redux";


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
  }));

const AuthRouter = ({ component: Component, ...rest }) => {
  
    const teacher = JSON.parse(localStorage.getItem("TEACHER"))
    const classes = useStyles();

    var url_string = window.location.href
    var url = new URL(url_string);
    var token = url.searchParams.get("token");

    useEffect(() => {
      if(token){
          const onSuccess = () =>{
            window.location.replace(window.location.origin+"/")
          }
          const onError = ()=>{
            window.location.replace(window.location.origin+"/signin")
          }
        MagicLogin(token,onSuccess,onError)
      }
    }, [token])
    
    
  
    if (token) {
        return (
            <div className={classes.root}>
            <CircularProgress color="secondary" />
          </div>
        );
      } else {
    
    return(
        <Route
        {...rest}
        render={(props) => {
          if (teacher) {
            return (
              <BaseLayout>
                <Component {...props} />
              </BaseLayout>
            );
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/signin",
                  state: { from: props.location },
                }}
              />
            );
          }
        }}
      />
    )
  }
};

export default AuthRouter;