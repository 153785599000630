const takeNotificationPermission = (onSuccess, onError) => {
    const showMessage = (permission) => {
        if(permission==="granted") {
            onSuccess();
        }
        else {
            onError();
        }
    }
    if(Notification.permission==="default")
    Notification.requestPermission().then(function (permission) {
        console.log("mylog permission", permission);
        showMessage(permission);
    });
}

export default takeNotificationPermission;