import React from "react";
import { useSnackbar } from "react-simple-snackbar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

//api call
// import { TeacherLogin } from "../redux/actions/AuthAction";
// import Toster from "../component/Toster";
import { CircularProgress } from "@material-ui/core";
import { askteacherpassword, TeacherForgotPassword } from "../../redux/actions/AuthAction";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://clapingo.com/">
        Clapingo
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function NumberForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [number, setNumber] = React.useState("");
  const [loder, setLoder] = React.useState(false);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const teacher = JSON.parse(localStorage.getItem("TEACHER"))
  const time_zone = JSON.parse(localStorage.getItem("TIMEZONE"));

  React.useEffect(()=>{
    if(teacher){
      history.push("/");
    }
  })

  const SendOtp = (e) => {
    e.preventDefault();
    setLoder(true);
    if (number.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) && !number.match(/0{5,}/)) {
        
      const data = {
        phoneNumber: number,
        // timezone:time_zone.timezone,
      };

      localStorage.setItem("NUMBER",number)

      if(props.location.state.Text === "Forgot Password"){
        const onSuccess = (res) => {
          setLoder(false);
            openSnackbar("Otp send successfully");
            history.push('/verifyOtp')  
        };
        const onError = (msg) => {
          openSnackbar(msg);
          setLoder(false);
        };
  
          dispatch(TeacherForgotPassword(data,onSuccess,onError))
      
      }else{
      const onSuccess = (res) => {
        setLoder(false);
        if(res.statusCode === 200){
          openSnackbar("Please login with your password");
          history.push('/signin')
        }else{
          openSnackbar("Otp send successfully");
          history.push('/verifyOtp')
        }

      };
      const onError = (msg) => {
        openSnackbar(msg);
        setLoder(false);
      };

        dispatch(askteacherpassword(data,onSuccess,onError))
    }

    //   const data = {
    //     phoneNumber: number,
    //     password: password,
    //   };
    //   const onSuccess = () => {
    //     window.location.reload()
    //     setLoder(false);
    //     openSnackbar("Login successfully");
    //     history.push("/");
    //   };
    //   const onError = (msg) => {
    //     openSnackbar(msg);
    //     setLoder(false);
    //   };
    //   dispatch(TeacherLogin(data, onSuccess, onError));
    } else {
      setLoder(false);
      openSnackbar("Invalid mobile number");
    }
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
           {props.location.state.Text}
          </Typography>
          <form className={classes.form} onSubmit={(e) => SendOtp(e)}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="number"
              label="Mobile Number "
              name="number"
              autoComplete="number"
              autoFocus
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loder?true:false}
            >
              {loder ? <CircularProgress /> : "Send OTP"}
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}

