import {
  Grid,
  Paper,
  Typography
} from "@mui/material";
import React, { useState, useEffect } from "react";

import ChatList from "../component/Chat/ChatList";
import ChatSection from "../component/Chat/ChatSection";
import {
  Route,
  Switch,
} from "react-router-dom";
import { GetAllRooms } from "../redux/actions/ChatAction";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";

const ChatPage = () => {
  const [chatters, setChatters] = useState();
  const [selectedLearner, setSelectedLearner] = useState(null);
  const [selectedLearnerId, setSelectedLearnerId] = useState(null);

  const learners = [];

  useEffect(() => {
    const onSuccess = (res) => {
      console.log("GET ALL ROOMS", res);
      setChatters(res.data.rooms);
    };

    const onError = (err) => {
      console.log(err);
    };

    GetAllRooms(onSuccess, onError);
  }, []);

  const showRecentChatOnTop = (roomId) => {
    const index = chatters.findIndex((chatter)=>chatter._id===roomId);
    if(index!==0){
      const newChatters = [...chatters];
      const chatter = newChatters.splice(index, 1)[0];
      newChatters.splice(0, 0, chatter);
      console.log("setting chatters", newChatters);
      setChatters(newChatters);
    }
  }

  return (
    <div className="chatpage">
      <Grid container component={Paper} className="chat_grid">
        <ChatList learners={learners} chatters={chatters} setSelectedLearner={setSelectedLearner} setSelectedLearnerId={setSelectedLearnerId} />
        <Switch>
          <Route exact path="/chatpage">
            <Grid className="select_conv" item xs={12} sm={7} md={9}>
              <ChatOutlinedIcon className="chat_icon" />
              <Typography variant="h6" sx={{fontWeight: 600, color: "rgb(107, 114, 128)"}}>Select a conversation or start a new chat</Typography>
            </Grid>
          </Route>
          <Route path="/chatpage/:roomId">
            <ChatSection showRecentChatOnTop={showRecentChatOnTop} selectedLearner={selectedLearner} selectedLearnerId={selectedLearnerId} chatters={chatters} setChatters={setChatters}/>
          </Route>
        </Switch>
      </Grid>
    </div>
  );
};

export default ChatPage;
