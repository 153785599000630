import React, { useEffect, useState } from "react";
import { images } from "../assets";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "react-simple-snackbar";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  Button,
  Grid,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import {
  GetTeacherConvStats,
  GetTeacherLeaderboard,
  salesPersonLearnerDetails,
  syncTeacer,
  TecherUpcomingSession,
} from "../redux/actions/SessionAction";
import {
  formatAMPM,
  localToUTCDate,
  utcToLocalDateConverter,
} from "../helper/sessionHelper";
import { UpcomingSessionCard } from "../component/SessionCard";
import moment from "moment";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 8,
  },
  transparentBackdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  closeButton: {
    float: "right",
  },
  card: {
    position: "relative",
    maxWidth: "90%",
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
  },
  requirement: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  ribbon: {
    backgroundColor: "#a31545",
    position: "absolute",
    color: "white",
    width: 150,
    zIndex: 3,
    textAlign: "center",
    textTransform: "uppercase",
    padding: 5,
    font: "Lato",
    "&::before": {
      position: "absolute",
      zIndex: -1,
      content: "",
      display: "block",
      border: "5px solid #2980b9",
    },
    "&::after": {
      position: "absolute",
      zIndex: -1,
      content: "",
      display: "block",
      border: "5px solid #2980b9",
    },
    transform: "rotate(-45deg)",
    top: 20,
    marginLeft: -40,
  },

  acceptBtn: {
    backgroundColor: "#7cb342",
    color: "#fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#43a047",
    },
    // backgroundColor:theme.palette.green[800]
  },
  declineBtn: {
    backgroundColor: "#e53935",
    color: "#fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#d32f2f",
    },
  },

  media: {
    paddingTop: "56.25%",
  },
  content: {
    textAlign: "left",
    padding: theme.spacing.unit * 2,
  },
  divider: {
    margin: `${theme.spacing.unit * 3}px 0`,
  },
  heading: {
    fontWeight: "bold",
  },
  subheading: {
    lineHeight: 1.8,
  },
  cardDiv: {
    flexGrow: 1,
  },
}));

const HomePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [open, setOpen] = React.useState(false);
  // const [sessionId, setSessionId] = React.useState(null);
  const [peerLearningEnabled, setPeerLearningEnabled] = useState(false);

  // const [earningData, setEarningData] = useState();
  const time_zone = JSON.parse(localStorage.getItem("TIMEZONE"));
  // const [loder, setLoder] = React.useState({
  //   id: null,
  //   status: null,
  // });

  const [startDate, setStartDate] = useState(
    moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [fetchStatsLoader, setFetchStatsLoader] = useState(true);

  const [refferalPercent, setReferralPercentage] = useState(0);
  // const teacherReducer = useSelector((state) => state.TeacherReducer);
  const teacher = useSelector((state) => state.TeacherReducer.teacher);
  // const [isToggled, setToggled] = useState(false);
  // const [resourceList, setResourceList] = useState([{}]);
  // const [openModal, setOpenModal] = useState(false);
  // const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [leaderboard, setLeaderboard] = useState();
  const [loading, setLoading] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
    getSalesDemoList();
  };
  const handleClose = () => {
    setOpen(false);
  };
  // const [link, setLink] = useState([{}]);

  // const handleOpen = (index) => {
  //   setOpenModal(true)
  //   setSelectedItemIndex(index);
  // };

  // const handleClose = () => {
  //   setOpenModal(false);
  // };

  // const toggleSide = (index) => {
  //   setToggled((prevState) => !prevState);
  //   const data = {
  //     page: 1,
  //     limit: 10,
  //     documentType: isToggled ? "video/mp4" : "application/pdf",
  //   };
  //   const onSuccess = (res) => {
  //     setResourceList(res?.getAllResourceDetails)
  //     //  setLink(res?.getAllResourceDetails[index].documentAwsLink)
  //   };
  //   const onError = (msg) => {
  //     openSnackbar(msg);
  //   };
  //   ResourcesUpload(data, onSuccess, onError);
  // };

  // useEffect(() => {
  //   toggleSide()
  // }, []);

  useEffect(() => {
    const onSuccess = (res) => {
      setLeaderboard(res);
      setLoading(false);
    };
    const onError = (msg) => {
      console.log(msg);
      setLoading(false);
    };
    GetTeacherLeaderboard(onSuccess, onError);
  }, []);

  // const ViewVideo = () => {
  //   return (
  //     <div>
  //       {resourceList.map((video, index) => (
  //         <div key={video._id} className="resource-content">
  //           <img src={VideoImage} width="150" style={{ objectFit: "cover", borderRadius: "5%" }} onClick={() => handleOpen(index)} />
  //           <div className="content-side">
  //             <div className="resource-name">{video.documentName}</div>
  //             {selectedItemIndex !== null && (
  //               <Modal
  //                 open={openModal}
  //                 onClose={handleClose}
  //                 closeAfterTransition
  //                 BackdropComponent={Backdrop}
  //                 BackdropProps={{
  //                   timeout: 500,
  //                 }}
  //                 className={classes.modal}
  //               >
  //                 <Fade in={openModal}>
  //                   <Paper className={classes.modalContent}>
  //                     <iframe title={video.documentType} src={resourceList[selectedItemIndex].documentAwsLink} width="500" height="600" />
  //                   </Paper>
  //                 </Fade>
  //               </Modal>
  //             )}
  //           </div>
  //         </div>
  //       ))}
  //     </div>
  //   )
  // };

  // const ViewPdf = () => {
  //   return (
  //     <div>
  //       {resourceList?.map((pdf, index) => (
  //         <div key={pdf._id} className="resource-content">
  //           <img src={DummyImage} width="150" style={{ objectFit: "cover", borderRadius: "5px" }} onClick={() => handleOpen(index)} />
  //           <div className="content-side">
  //             <div className="resource-name">{pdf.documentName}</div>
  //             {selectedItemIndex !== null && (
  //               <Modal
  //                 open={openModal}
  //                 onClose={handleClose}
  //                 closeAfterTransition
  //                 BackdropComponent={Backdrop}
  //                 BackdropProps={{
  //                   timeout: 500,
  //                   className: classes.transparentBackdrop,
  //                 }}
  //                 className={classes.modal}
  //               >
  //                 <Fade in={openModal}>
  //                   <Paper className={classes.modalContent}>
  //                     <iframe title={pdf.documentType} src={resourceList[selectedItemIndex].documentAwsLink} width="500" height="600" />
  //                   </Paper>
  //                 </Fade>
  //               </Modal>
  //             )}
  //           </div>
  //         </div>
  //       ))}
  //     </div>
  //   )
  // };

  const UpcommingSession = useSelector(
    (state) => state.TeacherReducer.upcomingSession
  );

  const teacherStats = useSelector(
    (state) => state.TeacherReducer.conversionStats
  );

  useEffect(() => {
    if (!teacherStats) fetchTeacherStats();
    else {
      setFetchStatsLoader(false);
      if (
        teacherStats.demoToSubscriptionPercentage >= 0 &&
        teacherStats.demoToSubscriptionPercentage <= 4.99
      ) {
        setReferralPercentage(0);
      }
      if (
        teacherStats.demoToSubscriptionPercentage >= 5 &&
        teacherStats.demoToSubscriptionPercentage <= 9.99
      ) {
        setReferralPercentage(2);
      }
      if (
        teacherStats.demoToSubscriptionPercentage >= 10 &&
        teacherStats.demoToSubscriptionPercentage <= 14.99
      ) {
        setReferralPercentage(4);
      }
      if (
        teacherStats.demoToSubscriptionPercentage >= 15 &&
        teacherStats.demoToSubscriptionPercentage <= 19.99
      ) {
        setReferralPercentage(7);
      }
      if (teacherStats.demoToSubscriptionPercentage >= 20) {
        setReferralPercentage(10);
      }
    }
  }, [teacherStats]);

  // const PandingSession = useSelector(
  //   (state) => state.TeacherReducer.pandingRequest
  // );

  // const acceptOrDecline = (ststus, sessionid) => {
  //   setLoder({ id: sessionid, status: ststus });
  //   const data = {
  //     status: ststus,
  //     sessionID: sessionid,
  //     teacherID: teacher._id,
  //   };
  //   const onSuccess = () => {
  //     openSnackbar(`Session ${ststus} successfully`);
  //     setLoder({ id: null, status: null });
  //   };
  //   const onError = () => {
  //     openSnackbar("Session accepted successfully");
  //     setLoder({ id: null, status: null });
  //   };
  //   dispatch(AcceptOrDecline(data, onSuccess, onError));
  // };

  const fetchTeacherStats = () => {
    setFetchStatsLoader(true);

    const data = {
      startDate: localToUTCDate(`${startDate}`).toISOString(),
      endDate: localToUTCDate(`${endDate}T24:00`).toISOString(),
      timezone: time_zone.timezone,
    };

    const onSuccess = () => {
      setFetchStatsLoader(false);
    };
    const onError = (err) => {
      setFetchStatsLoader(false);
      openSnackbar(err);
    };
    dispatch(GetTeacherConvStats(data, onSuccess, onError));
  };

  //Teacher earning
  // useEffect(() => {
  //   if (teacher) {
  //     const peerLearningSubject = teacher?.subjects?.filter(
  //       (data) => data?.peerLearning === true
  //     );
  //     if (peerLearningSubject?.length > 0) {
  //       setPeerLearningEnabled(true);
  //     }

  //     var date_today = new Date();
  //     var now_utc = Date.UTC(
  //       date_today.getUTCFullYear(),
  //       date_today.getUTCMonth(),
  //       date_today.getUTCDate(),
  //       date_today.getUTCHours(),
  //       date_today.getUTCMinutes(),
  //       date_today.getUTCSeconds()
  //     );

  //     var var_utc_today = new Date(now_utc).toISOString();
  //     var lastWeek = moment().startOf("month").format("YYYY-MM-DD HH:mm");

  //     const data = {
  //       teacherId: teacher._id,
  //       breakDownAfter: localToUTCDate(lastWeek),
  //       endDate: var_utc_today,
  //     };

  //     const onSuccess = (data) => {
  //       console.log(data);
  //       setEarningData(data);
  //     };
  //     const onError = (msg) => {
  //       console.log(msg);
  //     };
  //     dispatch(TeacherEarnings(data, onSuccess, onError));
  //   }
  // }, [teacher]);

  useEffect(() => {
    if (teacher) {
      const peerLearningSubject = teacher?.subjects?.filter(
        (data) => data?.peerLearning === true
      );
      if (peerLearningSubject?.length > 0) {
        setPeerLearningEnabled(true);
      }

      if (!teacher.newWorkingHoursSync) {
        const data = {
          teacherId: teacher._id,
          timeZone: time_zone.timezone,
          country: time_zone.country,
        };
        dispatch(syncTeacer(data));
      }
      dispatch(
        TecherUpcomingSession(
          {
            teacherID: teacher._id,
            page: 1,
            limit: 10,
            timezone: time_zone.timezone,
          },
          "reload"
        )
      );
    }
  }, [teacher]);

  const onDateChange = (type, date) => {
    if (type === "start") setStartDate(moment(date).format("YYYY-MM-DD"));
    else setEndDate(moment(date).endOf("day").format("YYYY-MM-DD"));
  };

  const GreenTableCell = withStyles((theme) => ({
    root: {
      backgroundColor: "#4CAF50", // Green background color
      color: theme.palette.common.white,
    },
  }))(TableCell);

  const [learnerDetail, setLearnerDetails] = useState(null);

  const getSalesDemoList = () => {
    var data = {
      learners: teacherStats?.learners,
    };

    const onSuccess = (res) => {
      setLearnerDetails(res);
    };

    const onError = (msg) => {
      console.log(msg);
    };

    dispatch(salesPersonLearnerDetails(data, onSuccess, onError));
  };

  return (
    <>
      <div className="main__container">
        {/* <!-- MAIN TITLE STARTS HERE --> */}
        <div>
          {teacher?.referral && !peerLearningEnabled ? (
            <>
              <Alert variant="outlined" severity="success">
                <AlertTitle>Refer & earn</AlertTitle>
                Your referral code is — <strong>{teacher?.referral}</strong> &
                sharable link is{" "}
                <strong>
                  <a
                    href={`/speaker/${teacher?.referral}`}
                    target="_blank"
                    alt="referral profile"
                  >
                    {" "}
                    Profile link
                  </a>
                </strong>
                .
              </Alert>
              <i className="fas fa-share-all"></i>
            </>
          ) : (
            ""
          )}

          <div className="main__title">
            <img src={images.hello} alt="" />
            <div className="main__greeting">
              <h1>
                Hello {teacher ? teacher.name : "Loading.."},{" "}
                {teacher ? (
                  <>
                    {teacher.rating} <i className="fa-solid fa-star"></i>
                  </>
                ) : (
                  "Loading"
                )}{" "}
              </h1>
              <p>Welcome to Clapingo</p>
            </div>
          </div>

          {!peerLearningEnabled && (
            <>
              {/* <!-- MAIN TITLE ENDS HERE --> */}
              <div className="date_conversion">
                <div>
                  <form
                    className="date-picker"
                    onSubmit={(e) => {
                      e.preventDefault();
                      fetchTeacherStats();
                    }}
                  >
                    <input
                      style={{ marginLeft: 0 }}
                      type="date"
                      value={moment(startDate).format("YYYY-MM-DD")}
                      onChange={(e) => onDateChange("start", e.target.value)}
                    />
                    <p>to</p>
                    <input
                      type="date"
                      value={moment(endDate).format("YYYY-MM-DD")}
                      onChange={(e) => onDateChange("end", e.target.value)}
                    />
                    <button
                      type="submit"
                      className="search-btn mr-[40px]"
                      disabled={fetchStatsLoader}
                    >
                      Search
                    </button>
                  </form>
                </div>

                <div
                  style={{ display: "flex", flexDirection: "row", gap: "2rem" }}
                >
                  {/* <div className="card_inner">
                    <div className="card_content">
                      <p className="text-[14px] font-semibold">
                        Current Demo Conversion :{" "}
                      </p>
                      <span className="font-bold text-white">
                        {!fetchStatsLoader
                          ? `${teacherStats.demoToSubscriptionPercentage}%`
                          : "Loading..."}
                      </span>
                    </div>
                    <div className="card_content">
                      <p className="text-[14px] font-semibold">Referral : </p>
                      <span className="font-bold text-white">
                        {!fetchStatsLoader
                          ? `${refferalPercent}%`
                          : "Loading..."}
                      </span>
                    </div>
                  </div> */}
                  <button
                    className="search-btn mr-[40px]"
                    style={{ backgroundColor: "#228f70" }}
                  >
                    <Link
                      to="/helpful-videos"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Helpful Video
                    </Link>
                  </button>
                  <button
                    className="search-btn mr-[40px]"
                    style={{ backgroundColor: "#228f70" }}
                  >
                    <Link
                      to="/helpful-pdfs"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Helpful PDF
                    </Link>
                  </button>
                </div>
              </div>

              {/*<!-- MAIN CARDS STARTS HERE --> */}
              <div className="main__cards">
                {teacher?.isSalesPerson || teacher?.isFluencyCounsellor ? (
                  <div className="card">
                    <i
                      className="fas fa-hand-holding-dollar fa-2x text-lightblue"
                      aria-hidden={true}
                    ></i>
                    <div className="card_inner">
                      <p className="text-primary-p">ARPU</p>
                      <span className="font-bold text-title">
                        {!fetchStatsLoader ? (
                          "₹ " + Math.round(teacherStats?.arpu)
                        ) : (
                          <CircularProgress size="1rem" />
                        )}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="card">
                    <i
                      className="fas fa-chalkboard-teacher fa-2x text-lightblue"
                      aria-hidden={true}
                    ></i>
                    <div className="card_inner">
                      <p className="text-primary-p">Total Booked Sessions</p>
                      <span className="font-bold text-title">
                        {!fetchStatsLoader ? (
                          teacherStats.totalSessionCount
                        ) : (
                          <CircularProgress size="1rem" />
                        )}
                      </span>
                    </div>
                  </div>
                )}
                {/* <div className="card">
                  <i
                    className="fas fa-money-check-alt fa-2x text-red"
                    aria-hidden={true}
                  ></i>
                  <div className="card_inner">
                    <p className="text-primary-p">Total Weekly Earning</p>
                    <span className="font-bold text-title">
                      ₹
                      {earningData
                        ? Math.round(earningData.totalEarning)
                        : "Loading.."}
                    </span>
                    <a href="/earnings">View More</a>
                  </div>
                </div> */}

                <div className="card">
                  <i
                    className="fa fa-laptop fa-2x text-yellow"
                    aria-hidden={true}
                  ></i>
                  <div className="card_inner">
                    <p className="text-primary-p">Upcoming Sessions</p>
                    <span className="font-bold text-title">
                      {!fetchStatsLoader ? (
                        teacherStats.upcomingSessionsCount
                      ) : (
                        <CircularProgress size="1rem" />
                      )}
                    </span>
                  </div>
                </div>
                {teacher?.isSalesPerson || teacher?.isFluencyCounsellor ? (
                  <div className="card">
                    <div
                      style={{
                        display: "flex",
                        gap: 8,
                        alignItems: "flex-end",
                      }}
                    >
                      <i
                        className="fa fa-percent fa-2x text-yellow"
                        aria-hidden={true}
                      ></i>
                    </div>
                    <div className="card_inner">
                      <p className="text-primary-p">Avg. Offer Discount</p>
                      <span className="font-bold text-title">
                        {!fetchStatsLoader ? (
                          Math.round(teacherStats.avgOfferDiscount)
                        ) : (
                          <CircularProgress size="1rem" />
                        )}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="card">
                    <div
                      style={{
                        display: "flex",
                        gap: 8,
                        alignItems: "flex-end",
                      }}
                    >
                      <i
                        className="fa fa-video-camera fa-2x text-yellow"
                        aria-hidden={true}
                      ></i>
                      <i
                        className="fa fa-calendar text-yellow"
                        aria-hidden={true}
                      ></i>
                    </div>
                    <div className="card_inner">
                      <p className="text-primary-p">
                        Number of regular sessions booked
                      </p>
                      <span className="font-bold text-title">
                        {!fetchStatsLoader ? (
                          teacherStats.regularSessionCount
                        ) : (
                          <CircularProgress size="1rem" />
                        )}
                      </span>
                    </div>
                  </div>
                )}
                <div className="card">
                  <div
                    style={{
                      display: "flex",
                      gap: 8,
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: 8,
                        alignItems: "flex-end",
                      }}
                    >
                      <i
                        className="fa-solid fa-circle-play fa-2x text-yellow"
                        aria-hidden={true}
                      ></i>
                      <i
                        className="fa fa-calendar text-yellow"
                        aria-hidden={true}
                      ></i>
                    </div>
                    {teacher?.isSalesPerson ||
                    teacher?.isFluencyCounsellor ||
                    teacher?.allowAutoSwap ||
                    teacher?.isDemo ? (
                      <span
                        style={{
                          fontSize: "12.5px",
                          cursor: "pointer",
                          fontWeight: "900",
                          color: "#4A6EE0",
                        }}
                        onClick={() => handleClickOpen()}
                      >
                        View More
                      </span>
                    ) : null}
                  </div>
                  <div className="card_inner">
                    <p className="text-primary-p">
                      Number of demo sessions booked
                    </p>
                    <span className="font-bold text-title">
                      {!fetchStatsLoader ? (
                        teacherStats.demoSessionCount
                      ) : (
                        <CircularProgress size="1rem" />
                      )}
                    </span>
                  </div>
                </div>
                <div className="card">
                  <div
                    style={{ display: "flex", gap: 8, alignItems: "flex-end" }}
                  >
                    <i
                      className="fa-solid fa-circle-play fa-2x text-yellow"
                      aria-hidden={true}
                    ></i>
                    <i
                      className="fa fa-check text-yellow"
                      aria-hidden={true}
                    ></i>
                  </div>
                  <div className="card_inner">
                    <p className="text-primary-p">
                      Number of demo sessions attended
                    </p>
                    <span className="font-bold text-title">
                      {!fetchStatsLoader ? (
                        teacherStats.learnerAttendedDemoCount
                      ) : (
                        <CircularProgress size="1rem" />
                      )}
                    </span>
                  </div>
                </div>
                <div className="card">
                  <i
                    className="fa fa-arrow-trend-up fa-2x text-green"
                    aria-hidden={true}
                  ></i>
                  <div className="card_inner">
                    <p className="text-primary-p">
                      Demo to Subscription Conversion
                    </p>
                    <span className="font-bold text-title">
                      {!fetchStatsLoader ? (
                        `${teacherStats.demoToSubscriptionPercentage}%(${teacherStats.demoToSubscriptionCount})`
                      ) : (
                        <CircularProgress size="1rem" />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* <!-- MAIN CARDS ENDS HERE --> */}
          {/* <div style={{height: '90px', width: '100%', maxWidth: '970px', margin: '30px auto 0px'}}>
        <Adsense
          client="ca-pub-3715562101512710"
          slot="JustBaat_Clapingo\/Clapingo_728x90"

          style={{width: "100%", height: "90px", display: "block", maxWidth: "970px"}}

          format="fluid"
        />
      </div> */}
          {/* <!-- CHARTS STARTS HERE --> */}
          <div className="session">
            <div className="session__container">
              <div className="session__container__title">
                <div>
                  <h1>Upcoming Session</h1>
                  <p>Click view all to see all upcoming session</p>
                </div>
                <span>
                  <Link
                    style={{ textDecoration: "none", color: "#fff" }}
                    to="/upcomingsession"
                  >
                    View all
                  </Link>
                </span>
              </div>

              <Box mt={3} className={classes.cardDiv}>
                <Grid container spacing={3}>
                  {UpcommingSession ? (
                    UpcommingSession.length > 0 ? (
                      UpcommingSession.slice(0, 3).map((data, index) => (
                        <Grid item xs={12} sm={4} key={index}>
                          <UpcomingSessionCard sessionData={data} />
                        </Grid>
                      ))
                    ) : (
                      <Box mx="auto">
                        <Box m={2} p={1} mx="auto">
                          <i
                            className="fas fa-parachute-box fa-5x"
                            style={{ color: "#D2D2D2" }}
                          ></i>
                        </Box>
                        <Box m={1} mx="auto">
                          No session found !
                        </Box>
                      </Box>
                    )
                  ) : (
                    <Box mx="auto">
                      <CircularProgress />
                    </Box>
                  )}
                </Grid>
              </Box>
            </div>
          </div>
        </div>
        {/* <div className="aside" style={{ height: '650px', overflow: 'auto' }}>
          <div className="resource-btn">
            <button onClick={toggleSide} className="resource-button">{isToggled ? "Helpful Pdf" : "Helpful Videos"}</button>
          </div>
          {isToggled ? <ViewPdf /> : <ViewVideo />}
        </div> */}
      </div>

      {!peerLearningEnabled && (
        <div className="main__container">
          <Typography variant="h5" gutterBottom>
            Teacher Leaderboard
          </Typography>

          <TableContainer component={Paper} style={{ width: "68%" }}>
            <Table aria-label="Teacher Table">
              <TableHead>
                <TableRow>
                  <GreenTableCell>Name</GreenTableCell>
                  <GreenTableCell>Demo Conversion %</GreenTableCell>
                  <GreenTableCell>Converted Subscriptions</GreenTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell>
                      <CircularProgress
                        style={{
                          marginTop: "2rem",
                          marginBottom: "2rem",
                          marginLeft: "100%",
                          color: "black",
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {leaderboard?.teachers?.map((teacher) => (
                      <TableRow key={teacher._id}>
                        <TableCell
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 8,
                          }}
                        >
                          <img
                            src={
                              teacher.image ||
                              "https://clapngobucket.s3.amazonaws.com/Images/blank-profile-picture-g363861f5d_1280.png"
                            }
                            alt={teacher.name}
                            style={{
                              height: "50px",
                              width: "50px",
                              borderRadius: "100%",
                            }}
                          />
                          <span>{teacher.name}</span>
                        </TableCell>

                        <TableCell>
                          {teacher?.monthlyDemoConversionPercent}%
                        </TableCell>
                        <TableCell>
                          {teacher?.monthlyDemoConversionNumber}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Dialog
            onClose={handleClose}
            maxWidth="xl"
            maxHeight="100%"
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogContent
              dividers
              style={{
                width: "100%",
                maxWidth: "100%",
                maxHeight: "100%",
                fontFamily: "inherit",
              }}
            >
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          fontSize: "1rem",
                          textAlign: "center",
                        }}
                      >
                        S.No
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          fontSize: "1rem",
                          textAlign: "center",
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          fontSize: "1rem",
                          textAlign: "center",
                        }}
                      >
                        Phone No.
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          fontSize: "1rem",
                          textAlign: "center",
                        }}
                      >
                        Session Date
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          fontSize: "1rem",
                          textAlign: "center",
                        }}
                      >
                        Start Time
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          fontSize: "1rem",
                          textAlign: "center",
                        }}
                      >
                        End Time
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          fontSize: "1rem",
                          textAlign: "center",
                        }}
                      >
                        Learner Attended
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {learnerDetail?.learnerDetails?.map((data, i) => (
                      <TableRow key={i}>
                        <TableCell style={{ textAlign: "center" }}>
                          {i + 1}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {data?.name}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {data?.phoneNumber}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {utcToLocalDateConverter(
                            data?.sessionsData?.[0]?.originalSessionDate
                          )}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {formatAMPM(data?.sessionsData?.[0]?.startTime)}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {formatAMPM(data?.sessionsData?.[0]?.endTime)}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {data?.sessionsData?.[0]?.learnerAttended === true
                            ? "true"
                            : "false"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default HomePage;
