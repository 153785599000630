import React, { useState, useEffect } from "react";
import { useSnackbar } from "react-simple-snackbar";
import {
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  dateFormater,
  formatTimeslotUTCtoLocalAMPM,
  utcToLocalDateConverter,
} from "../helper/sessionHelper";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  TecherPastSession,
  ZoomMeetingRecording,
} from "../redux/actions/SessionAction";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  acceptBtn: {
    backgroundColor: "#208F70",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#43a047",
    },
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  noData: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
}));

const MenteesSessions = () => {
  const classes = useStyles();
  let { id } = useParams();
  const teacher = useSelector((state) => state.TeacherReducer.teacher);
  const dispatch = useDispatch();
  const [openSnackbar, closeSnackbar] = useSnackbar();

  const [pastSessions, setPastSessions] = useState([]);
  const [loader, setLoader] = useState();
  const [recordingLoader, setRecordingLoader] = useState(false);
  const [mentee, setMentee] = useState({});

  const loadPastSessions = () => {
    setLoader(true);
    const data = {
      teacherID: id,
      page: 1,
      limit: 10,
      timezone: teacher.timezone,
      type: "Demo",
    };
    const onSuccess = (res) => {
      setPastSessions(res?.data?.sessions);
      setLoader(false);
    };
    const onError = (msg) => {
      setLoader(false);
    };

    dispatch(TecherPastSession(data, onSuccess, onError));
  };

  const viewRecording = (sessionData) => {
    const val = {
      meetingId: sessionData.meetingNo,
    };
    const onSuccess = (data) => {
      setRecordingLoader(null);
      console.log(data.recording_files);
      window.open(data.recording_files[0].play_url, "_blank");
    };
    const onError = () => {
      setRecordingLoader(null);
      openSnackbar("Recording not found for this session !!");
    };
    if (sessionData.meetingRecording)
      window.open(
        `https://clapingo-testing.netlify.app/recordings?link=${sessionData.meetingRecording}`,
        "_blank"
      );
    else {
      setRecordingLoader(sessionData._id);
      dispatch(ZoomMeetingRecording(val, onSuccess, onError));
    }
  };

  useEffect(() => {
    if (teacher) {
      loadPastSessions();
      const menteeDetails = teacher?.mentees?.filter(
        (mentee) => mentee?.id === id
      );
      if (menteeDetails?.length > 0) {
        setMentee(menteeDetails[0]);
      }
    }
  }, [teacher]);

  return (
    <Container className={classes.root}>
      <h1 className="heading">{mentee?.name} Demo Sessions</h1>

      {loader ? (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      ) : pastSessions?.length === 0 ? (
        <Typography variant="h6" className={classes.noData}>
          No demo session available
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Subject</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Type</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pastSessions.map((request) => (
                <TableRow key={request._id}>
                  <TableCell>{request.subjects}</TableCell>
                  <TableCell>
                    {utcToLocalDateConverter(
                      request.sessionDate,
                      request.endTime
                    )}
                  </TableCell>{" "}
                  {/* Update here */}
                  <TableCell>
                    {formatTimeslotUTCtoLocalAMPM(
                      `${request.startTime}-${request.endTime}`
                    )}
                  </TableCell>{" "}
                  {/* Update here */}
                  <TableCell>{request.type}</TableCell>
                  <TableCell align="right">
                    <Button
                      className={classes.acceptBtn}
                      onClick={() => viewRecording(request)}
                      disabled={recordingLoader === request._id}
                    >
                      {recordingLoader === request._id ? (
                        <CircularProgress size={24} />
                      ) : (
                        "View Recording"
                      )}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default MenteesSessions;
