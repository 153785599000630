import { FreshchatRestoreId } from "../redux/actions/AuthAction";
import { updateLocalStorageObject } from "./sessionHelper";

// const user = JSON.parse(localStorage.getItem("TEACHER"));

const InitFreshChat = (user, userRef, authenticated) => {
    if (user) {
        if(user?.freshchatRestoreId) console.log("user if widget init with restoreId");
        else console.log("user if widget init");
    function initFreshChat() {
        window.fcWidget.init({
        token: "55e68d9a-e16a-465e-830a-a3dcf17ce458",
        host: "https://wchat.in.freshchat.com",
        externalId: `${user.name}.${user.phoneNumber}`, // user’s id unique to your system
        firstName: user.name, // user’s first name
        email: user?.email, // user’s email address
        phone: user.phoneNumber, // phone number without country code
        restoreId: user?.freshchatRestoreId || null,
        // phoneCountryCode: "+1", // phone’s country code
        });

        window.fcWidget.on("widget:loaded", function () {
            // console.log("user if widget loaded");
            window.fcWidget.user.get(function (resp) {
            var status = resp && resp.status,
                data = resp && resp.data;
                // console.log("INSIDE FIRST BLOCK", resp, resp?.data);
            if (status !== 200) {
                // console.log("INSIDE SECOND BLOCK")
                // window.fcWidget.user.setProperties({
                // isTrialBooked:user.isTrialBooked,
                // isUserSUbscribed:user.isLearnerSubscribed,
                // City:user.city,
                // timezone:UserLocation.timezone
                // });
                window.fcWidget.on("user:created", function (resp) {
                console.log("INSIDE THIRD BLOCK");
                console.log("restoreId:",resp?.data, resp?.data?.restoreId);
                var status = resp && resp.status,
                data = resp && resp.data;
                if (status === 200) {
                    // console.log("INSIDE FOURTH BLOCK")
                    if (data.restoreId) {
                    console.log("INSIDE FIFTH BLOCK")
                    console.log("restoreId:",data.restoreId)
                    // setLocalstoregeData("USER", data.restoreId)
                    // Update restoreId in your database
                    const onSuccess = (res) =>{
                    console.log(res)
                    updateLocalStorageObject("TEACHER", "freshchatRestoreId", data.restoreId);
                    }
                    const onError = (error) =>{
                        console.log(error)
                    }
                    FreshchatRestoreId({restoreId:data.restoreId},onSuccess, onError)
                    }
                }
                });
            }
            });
        });

        window.fcWidget.on("widget:destroyed", function () {
            const localUser = JSON.parse(localStorage.getItem("TEACHER"));
            console.log("user freschat widget destroyed event", user, localUser, user);
            window.fcWidget.init({
              token: "3caa8a34-459b-4fff-ae90-1ed9795a519f",
              host: "https://wchat.in.freshchat.com",
            });
      
            console.log("user freshchat widget updated");
        });
    }
  
    function initialize(i, t) {
        var e;
        if(i.getElementById(t)) initFreshChat()
        else {
            e = i.createElement("script");
            e.id = t;
            e.async = true;
            e.src = "https://wchat.in.freshchat.com/js/widget.js";
            e.onload = initFreshChat;
            i.head.appendChild(e);
        }
    }
    function initiateCall() {
        initialize(document, "freshchat-js-sdk");
    }
    if(window.fcWidget !== undefined && window.fcWidget !== undefined) console.log("user check if init", window.fcWidget.isInitialized())
    if(window.fcWidget !== undefined && window.fcWidget.isInitialized()){
        window.fcWidget.destroy();
        console.log("user freshchat widget destroyed");
    }
    else
    initiateCall();
    
    } else {
        console.log("user else part");

    function initFreshChat() {
        console.log("user else widget init");

        window.fcWidget.init({
        token: "55e68d9a-e16a-465e-830a-a3dcf17ce458",
        host: "https://wchat.in.freshchat.com",
        });

        window.fcWidget.on("widget:loaded", function () {
            console.log("user else widget loaded");
        });
    }
    function initialize(i, t) {
        var e;
        if(i.getElementById(t))initFreshChat()
        else {
            e = i.createElement("script");
            e.id = t;
            e.async = true;
            e.src = "https://wchat.in.freshchat.com/js/widget.js";
            e.onload = initFreshChat;
            i.head.appendChild(e);    
        }
    }
    function initiateCall() {
        initialize(document, "freshchat-js-sdk");
    }
    if(window.fcWidget !== undefined) console.log("user check if init", window.fcWidget.isInitialized())
    if(window.fcWidget !== undefined && window.fcWidget.isInitialized()){
      window.fcWidget.destroy();
      console.log("user else freshchat widget destroyed");
    } 
    else
    initiateCall();
    }
}

export default InitFreshChat;

