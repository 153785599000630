import React, { Fragment, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Alert from "@material-ui/lab/Alert";
import { useSnackbar } from "react-simple-snackbar";
import { useDispatch } from "react-redux";
import { Teacherfeedback } from "../redux/actions/SessionAction";
import { Tooltip } from "@mui/material";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";

export default function FeedbackModalV2({
  SessionId,
  feedbackData,
  subject,
  sessionType,
  sessionCount,
  feedbackModalId,
  forKids,
}) {
  const dispatch = useDispatch();

  const [feedbackInfo, setFeedbackInfo] = useState({
    Confidence: [
      {
        "Eye Contact": {
          didWell: ["Able to Look into the camera"],
          scopeOfImprovement: ["Looking Away from Camera"],
          selected: { didWell: false, scopeOfImprovement: false },
        },
      },
      {
        "Use of fillers": {
          didWell: ["Negligible Use"],
          scopeOfImprovement: ["Repeated Use"],
          selected: { didWell: false, scopeOfImprovement: false },
        },
      },
      {
        "Pauses while speaking": {
          didWell: ["No Lengthy Pauses"],
          scopeOfImprovement: ["Inappropriate Pauses"],
          selected: { didWell: false, scopeOfImprovement: false },
        },
      },
      {
        "Overall body language": {
          didWell: ["Confident"],
          scopeOfImprovement: ["Doubting their abilities"],
          selected: { didWell: false, scopeOfImprovement: false },
        },
      },
    ],
    Grammar: [
      {
        "Use of tenses": {
          didWell: ["Appropriate"],
          scopeOfImprovement: ["Need Improvement"],
          selected: { didWell: false, scopeOfImprovement: false },
        },
      },
      {
        "Use of articles": {
          didWell: ["Appropriate"],
          scopeOfImprovement: ["Need Improvement"],
          selected: { didWell: false, scopeOfImprovement: false },
        },
      },
      {
        "Use of prepositions": {
          didWell: ["Appropriate"],
          scopeOfImprovement: ["Need Improvement"],
          selected: { didWell: false, scopeOfImprovement: false },
        },
      },
      {
        "Use of participles": {
          didWell: ["Appropriate"],
          scopeOfImprovement: ["Need Improvement"],
          selected: { didWell: false, scopeOfImprovement: false },
        },
      },
      {
        "Usage of pronouns": {
          didWell: ["Appropriate"],
          scopeOfImprovement: ["Need Improvement"],
          selected: { didWell: false, scopeOfImprovement: false },
        },
      },
      {
        "Usage of Modal Auxiliaries (Can, Should, May)": {
          didWell: ["Appropriate"],
          scopeOfImprovement: ["Need Improvement"],
          selected: { didWell: false, scopeOfImprovement: false },
        },
      },
      {
        "Application of Singular vs Plural": {
          didWell: ["Appropriate"],
          scopeOfImprovement: ["Need Improvement"],
          selected: { didWell: false, scopeOfImprovement: false },
        },
      },
      {
        "Subject - Verb Agreement": {
          didWell: ["Appropriate"],
          scopeOfImprovement: ["Need Improvement"],
          selected: { didWell: false, scopeOfImprovement: false },
        },
      },
    ],
    "Fluency & Vocabulary": [
      {
        Speed: {
          didWell: ["Appropriate"],
          scopeOfImprovement: ["Too fast", "Too slow"],
          selectedScope: [],
          selected: { didWell: false, scopeOfImprovement: false },
        },
      },
      {
        Continuity: {
          didWell: ["No Fumbles"],
          scopeOfImprovement: ["Fumbles while speaking"],
          selected: { didWell: false, scopeOfImprovement: false },
        },
      },
      {
        "Sentence completion": {
          didWell: ["Complete sentences"],
          scopeOfImprovement: ["Broken sentences"],
          selected: { didWell: false, scopeOfImprovement: false },
        },
      },
      {
        "Sentence formation": {
          didWell: ["Appropriate"],
          scopeOfImprovement: ["Needs Improvement"],
          selected: { didWell: false, scopeOfImprovement: false },
        },
      },
      {
        Volume: {
          didWell: ["Appropriate"],
          scopeOfImprovement: ["Too loud", "Too soft"],
          selectedScope: [],
          selected: { didWell: false, scopeOfImprovement: false },
        },
      },
      {
        Range: {
          didWell: ["Advanced"],
          scopeOfImprovement: [
            "Use only basic words",
            "Use of excessive Abbreviations/ Jargon",
            "Basic",
          ],
          selectedScope: [],
          selected: { didWell: false, scopeOfImprovement: false },
        },
      },
      {
        Accuracy: {
          didWell: ["Appropriate"],
          scopeOfImprovement: ["Use of excessive Abbreviations/Jargon"],
          selected: { didWell: false, scopeOfImprovement: false },
        },
      },
    ],
    Pronunciation: [
      {
        "Speech clarity": {
          didWell: ["Appropriate"],
          scopeOfImprovement: [
            "Difficulty in Pronouncing R",
            "Difficulty in Pronouncing sh",
            "Difficulty in Pronouncing H",
            "Difficulty in Pronouncing E",
            "Difficulty in Pronouncing A",
            "Difficulty in Pronouncing V",
          ],
          selectedScope: [],
          selected: { didWell: false, scopeOfImprovement: false },
        },
      },
      {
        Intonation: {
          didWell: ["Appropriate"],
          scopeOfImprovement: ["Needs Improvement"],
          selected: { didWell: false, scopeOfImprovement: false },
        },
      },
      {
        Modulation: {
          didWell: ["Appropriate"],
          scopeOfImprovement: ["Needs Improvement"],
          selected: { didWell: false, scopeOfImprovement: false },
        },
      },
      {
        "Pronunciation of words with silent letters": {
          didWell: ["Satisfcatory"],
          scopeOfImprovement: ["Needs Improvement"],
          selected: { didWell: false, scopeOfImprovement: false },
        },
      },
      {
        Phonetics: {
          didWell: ["Understands word specific sounds"],
          scopeOfImprovement: ["Unable to grasp certain sounds"],
          selected: { didWell: false, scopeOfImprovement: false },
        },
      },
      {
        "Pronunciation of complex words": {
          didWell: ["Appropriate"],
          scopeOfImprovement: ["Needs Improvement"],
          selected: { didWell: false, scopeOfImprovement: false },
        },
      },
    ],
  });
  const points = [
    "Confidence",
    "Grammar",
    "Fluency & Vocabulary",
    "Pronunciation",
    "Other Feedback",
  ];

  const [feedback, setFeedback] = useState();
  const [selectedPoints, setSelectedPoints] = useState([]);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [loder, setLoder] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [feedbackText, setFeedbackText] = useState(""); //custom feedback text

  const [feedbackForNextTutor, setFeedbackForNextTutor] = useState({
    currentBandScore: "",
    desiredBandScore: "",
    modulesNeedHelp: "",
    timeRequireToAchieveBandScore: "",
    chancesOfEnrolling: "",
  });

  function toCamelCase(str) {
    // Replace & with And, remove content in brackets, and replace - with space
    str = str
      .replace(/&/g, "And")
      .replace(/\(.*?\)/g, "")
      .replace(/-/g, " ");

    // Split the string into words
    let words = str.split(" ");

    // Convert the first word to lower case and remove leading/trailing spaces
    words[0] = words[0].toLowerCase().trim();

    // Convert the rest of the words to title case (first letter upper case)
    for (let i = 1; i < words.length; i++) {
      words[i] =
        words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
    }

    // Join the words together to get the camel case string
    return words.join("");
  }

  useEffect(() => {
    setError("");
  }, [feedbackInfo]);

  const transformFeedbackData = (data) => {
    let result = {
      confidence: { didWell: {}, scopeOfImprovement: {} },
      grammar: { didWell: {}, scopeOfImprovement: {} },
      fluencyAndVocabulary: { didWell: {}, scopeOfImprovement: {} },
      pronunciation: { didWell: {}, scopeOfImprovement: {} },
    };
    for (const [key, value] of Object.entries(data)) {
      let obj1 = new Object();
      let obj2 = new Object();
      for (const [subKey, subValue] of Object.entries(value)) {
        for (const [subSubKey, subSubValue] of Object.entries(subValue)) {
          if (subSubValue.selected.didWell) {
            obj1[toCamelCase(subSubKey)] = subSubValue.didWell;
            result[toCamelCase(key)].didWell = Object.assign({}, obj1);
          }
          if (subSubValue.selected.scopeOfImprovement) {
            if (subSubValue.scopeOfImprovement.length === 1) {
              obj2[toCamelCase(subSubKey)] = subSubValue.scopeOfImprovement;
            } else {
              obj2[toCamelCase(subSubKey)] = subSubValue.selectedScope;
            }
            result[toCamelCase(key)].scopeOfImprovement = Object.assign(
              {},
              obj2
            );
          }
        }
      }
    }
    validateFeedback(result);
  };

  useEffect(() => {
    if (!feedbackData && SessionId === feedbackModalId) {
      setOpen(true);
    }
  }, [feedbackData, feedbackModalId]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (feedback) {
      handleFeedback();
    }
  }, [feedback]);

  const validateFeedback = (feedback) => {
    setError("");
    let error = false;
    if (sessionType === "Regular") {
      let totalDidWell = 0;
      let totalScopeOfImprovement = 0;
      for (const [key, value] of Object.entries(feedback)) {
        totalDidWell =
          totalDidWell + Object.keys(feedback[key]["didWell"]).length;
        totalScopeOfImprovement =
          totalScopeOfImprovement +
          Object.keys(feedback[key]["scopeOfImprovement"]).length;
      }
      if (totalDidWell + totalScopeOfImprovement < 1) {
        setError(
          `Please select at least a positive or a negative in any section for Regular Session.`
        );
        error = true;
      }
      for (const [key, value] of Object.entries(feedback)) {
        if (Object.keys(feedback[key]["scopeOfImprovement"]).length > 0) {
          for (const [subSubKey, subSubValue] of Object.entries(
            feedback[key]["scopeOfImprovement"]
          )) {
            if (subSubValue.length === 0) {
              setError(
                `Please select option in ${
                  subSubKey.charAt(0).toUpperCase() + subSubKey.slice(1)
                }`
              );
              error = true;
              break;
            }
          }
        }
      }
      if (!error) {
        setFeedback(feedback);
      }
    } else {
      let totalDidWell = 0;
      let totalScopeOfImprovement = 0;
      for (const [key, value] of Object.entries(feedback)) {
        totalDidWell =
          totalDidWell + Object.keys(feedback[key]["didWell"]).length;
        totalScopeOfImprovement =
          totalScopeOfImprovement +
          Object.keys(feedback[key]["scopeOfImprovement"]).length;
      }
      if (totalDidWell < 3 || totalScopeOfImprovement < 3) {
        setError(
          `Please select at least 3 positive and 3 negative options in any section for the Demo Session.`
        );
        error = true;
      }
      for (const [key, value] of Object.entries(feedback)) {
        if (Object.keys(feedback[key]["scopeOfImprovement"]).length > 0) {
          for (const [subSubKey, subSubValue] of Object.entries(
            feedback[key]["scopeOfImprovement"]
          )) {
            if (subSubValue.length === 0) {
              setError(
                `Please select option in ${
                  subSubKey.charAt(0).toUpperCase() + subSubKey.slice(1)
                }`
              );
              error = true;
              break;
            }
          }
        }
      }
    }
    if (!error) {
      setFeedback(feedback);
    }
  };

  const handleIELTSFeedback = () => {
    setError("");

    let data = {
      sessionId: SessionId,
    };
    if (
      feedbackForNextTutor.currentBandScore &&
      feedbackForNextTutor.desiredBandScore &&
      feedbackForNextTutor.modulesNeedHelp &&
      feedbackForNextTutor.timeRequireToAchieveBandScore &&
      feedbackForNextTutor.chancesOfEnrolling
    ) {
      data["forNextTutor"] = [
        {
          question: "Current Band Score",
          answer: feedbackForNextTutor.currentBandScore,
        },
        {
          question: "Desired Band Score",
          answer: feedbackForNextTutor.desiredBandScore,
        },
        {
          question: "Modules they need help with",
          answer: feedbackForNextTutor.modulesNeedHelp,
        },
        {
          question:
            "Time you think they might require to achieve their desired Band Score",
          answer: feedbackForNextTutor.timeRequireToAchieveBandScore,
        },
        {
          question: "Chances of them enrolling",
          answer: feedbackForNextTutor.chancesOfEnrolling,
        },
      ];
    } else {
      setError(`Please fill all the fields`);
      return;
    }
    setLoder(true);
    const onSuccess = (msg) => {
      setLoder(false);
      setOpen(false);
      openSnackbar(msg);
      setFeedbackText("");
    };
    const onError = (msg) => {
      setLoder(false);
      setOpen(false);
      openSnackbar(msg);
    };

    dispatch(Teacherfeedback(data, onSuccess, onError));
  };

  const handleFeedback = () => {
    setError("");
    setLoder(true);
    let data = {
      sessionId: SessionId,
    };
    data = { ...data, ...feedback };
    if (feedbackText) {
      data["others"] = feedbackText;
    }

    const onSuccess = (msg) => {
      setOpen(false);
      setLoder(false);
      openSnackbar(msg);
      setFeedbackText("");
    };
    const onError = (msg) => {
      setOpen(false);
      setLoder(false);
      openSnackbar(msg);
    };

    dispatch(Teacherfeedback(data, onSuccess, onError));
  };

  return (
    <>
      <button
        className="feedback"
        style={{ width: !feedbackData ? "48%" : "100%" }}
        onClick={() => setOpen(true)}
        disabled={feedbackData || forKids}
      >
        {feedbackData !== null ? (
          <>
            <i className="fa fa-check "></i> Feedback
          </>
        ) : (
          "Feedback"
        )}
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {subject === "IELTS" ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                padding: "20px",
              }}
            >
              <div
                style={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: 700,
                  textAlign: "center",
                  marginBottom: "8px",
                  width: "400px",
                }}
              >
                IELTS Demo session feedback
              </div>
              <small>Add your feedback</small>
              <TextField
                id="outlined-number"
                style={{ margin: "0.5rem" }}
                label="Current Band Score"
                type="number"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                value={feedbackForNextTutor.currentBandScore}
                onChange={(e) => {
                  setFeedbackForNextTutor({
                    ...feedbackForNextTutor,
                    currentBandScore: e.target.value,
                  });
                }}
                required
              />
              <TextField
                id="outlined-number"
                style={{ margin: "0.5rem" }}
                label="Desired Band Score"
                type="number"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                value={feedbackForNextTutor.desiredBandScore}
                onChange={(e) => {
                  setFeedbackForNextTutor({
                    ...feedbackForNextTutor,
                    desiredBandScore: e.target.value,
                  });
                }}
                required
              />
              <TextField
                id="outlined-text"
                style={{ margin: "0.5rem" }}
                label="Modules they need help with"
                type="text"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                value={feedbackForNextTutor.modulesNeedHelp}
                onChange={(e) => {
                  setFeedbackForNextTutor({
                    ...feedbackForNextTutor,
                    modulesNeedHelp: e.target.value,
                  });
                }}
                required
              />
              <TextField
                id="outlined-text"
                style={{ margin: "0.5rem" }}
                label="Time you think they might require to achieve their desired Band Score"
                type="text"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                value={feedbackForNextTutor.timeRequireToAchieveBandScore}
                onChange={(e) => {
                  setFeedbackForNextTutor({
                    ...feedbackForNextTutor,
                    timeRequireToAchieveBandScore: e.target.value,
                  });
                }}
                required
              />
              <TextField
                id="outlined-number"
                style={{ margin: "0.5rem" }}
                label="Chances of them enrolling"
                type="text"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                value={feedbackForNextTutor.chancesOfEnrolling}
                onChange={(e) => {
                  setFeedbackForNextTutor({
                    ...feedbackForNextTutor,
                    chancesOfEnrolling: e.target.value,
                  });
                }}
                required
              />
              {error ? <Alert severity="error">{error}</Alert> : ""}
              <Button
                onClick={handleIELTSFeedback}
                color="primary"
                variant="contained"
                disabled={loder}
              >
                {loder ? "Submitting.." : "Submit"}
              </Button>
            </div>
          </>
        ) : (
          <div className="feedback-container">
            <div className="feedback-heading">
              Tell us a bit about your learners English proficiency
            </div>
            <div className="feedback-subheading">
              Give feedback to learners and help them improve themselves. Also,
              it helps other tutors better understand learners and evaluate
              them.
            </div>
            <div className="feedback-points-container">
              {points.map((data, index) => {
                const isSelected = selectedPoints.find((point) => {
                  if (point === data) return true;
                });
                return (
                  <div
                    key={index}
                    className="feedback-points"
                    style={{
                      background: isSelected ? "#52C5B6" : "#DBDBDB",
                    }}
                    onClick={() => {
                      if (isSelected) {
                        setSelectedPoints(
                          selectedPoints.filter((point) => point !== data)
                        );
                      } else {
                        setSelectedPoints([...selectedPoints, data]);
                      }
                    }}
                  >
                    <span>{data}</span>
                    {isSelected ? (
                      <img
                        src="/assets/Feedback/Minus.svg"
                        alt="plus"
                        height="20"
                        width="20"
                      />
                    ) : (
                      <img
                        src="/assets/Feedback/Plus.svg"
                        alt="plus"
                        height="20"
                        width="20"
                      />
                    )}
                  </div>
                );
              })}
            </div>
            {selectedPoints.length === 0 && (
              <div className="feedback-middle-empty-container"></div>
            )}
            <div className="feedback-points-details-container">
              {selectedPoints
                .filter(
                  (point) =>
                    point === "Confidence" ||
                    point === "Fluency & Vocabulary" ||
                    point === "Grammar" ||
                    point === "Pronunciation"
                )
                .map((data, index) => (
                  <Fragment key={index}>
                    <div className="topic-heading">{data}</div>
                    <div className="feedback-options-container">
                      {feedbackInfo[data].map((subData, subIndex) => (
                        <div className="feedback-option" key={subIndex}>
                          <div className="feedback-option-title">
                            {Object.keys(subData)[0]}
                          </div>
                          <div className="feedback-choice">
                            <Tooltip
                              title={subData[Object.keys(subData)[0]].didWell}
                              placement="top"
                            >
                              <div
                                className="feedback-choice-positive"
                                onClick={() => {
                                  setFeedbackInfo((prevState) => {
                                    prevState = { ...prevState };
                                    const didWell =
                                      prevState[data][subIndex][
                                        Object.keys(subData)[0]
                                      ].selected.didWell;

                                    prevState[data][subIndex][
                                      Object.keys(subData)[0]
                                    ].selected.didWell = !didWell;

                                    prevState[data][subIndex][
                                      Object.keys(subData)[0]
                                    ].selected.scopeOfImprovement = false;

                                    return prevState;
                                  });
                                }}
                                style={{
                                  background: feedbackInfo[data][subIndex][
                                    Object.keys(subData)[0]
                                  ].selected.didWell
                                    ? "#289968"
                                    : "#9DD1BA",
                                }}
                              >
                                <img
                                  src="/assets/Feedback/positive.svg"
                                  alt="positive"
                                />
                              </div>
                            </Tooltip>
                            <Tooltip
                              title={
                                subData[Object.keys(subData)[0]]
                                  .scopeOfImprovement.length > 1
                                  ? "Select to know more"
                                  : subData[Object.keys(subData)[0]]
                                      .scopeOfImprovement
                              }
                              placement="top"
                            >
                              <div
                                className="feedback-choice-positive"
                                onClick={() => {
                                  setFeedbackInfo((prevState) => {
                                    prevState = { ...prevState };
                                    const scopeOfImprovement =
                                      prevState[data][subIndex][
                                        Object.keys(subData)[0]
                                      ].selected.scopeOfImprovement;

                                    prevState[data][subIndex][
                                      Object.keys(subData)[0]
                                    ].selected.scopeOfImprovement =
                                      !scopeOfImprovement;

                                    prevState[data][subIndex][
                                      Object.keys(subData)[0]
                                    ].selected.didWell = false;

                                    return prevState;
                                  });
                                }}
                                style={{
                                  background: feedbackInfo[data][subIndex][
                                    Object.keys(subData)[0]
                                  ].selected.scopeOfImprovement
                                    ? "#BB5858"
                                    : "#9DD1BA",
                                }}
                              >
                                <img
                                  src="/assets/Feedback/negative.svg"
                                  alt="negative"
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      ))}
                    </div>
                    {feedbackInfo[data].map((subData, subIndex) => (
                      <Fragment key={subIndex}>
                        {subData[Object.keys(subData)[0]].scopeOfImprovement
                          .length > 1 &&
                          subData[Object.keys(subData)[0]].selected
                            .scopeOfImprovement && (
                            <div className="issue-container">
                              <div className="issue-title">
                                Issues with{" "}
                                <strong>‘{Object.keys(subData)[0]}’</strong>
                              </div>
                              <div className="issue-subcontainer">
                                {subData[
                                  Object.keys(subData)[0]
                                ].scopeOfImprovement.map((issue, index) => {
                                  const selectedScope =
                                    feedbackInfo[data][subIndex][
                                      Object.keys(subData)[0]
                                    ].selectedScope;
                                  const selectedScopeIndex =
                                    selectedScope.indexOf(issue);
                                  return (
                                    <div
                                      key={index}
                                      className="issue"
                                      style={{
                                        background:
                                          selectedScopeIndex !== -1
                                            ? "#65CFC1"
                                            : "#fff",
                                      }}
                                      onClick={() => {
                                        setFeedbackInfo((prevState) => {
                                          prevState = { ...prevState };
                                          if (
                                            subData[Object.keys(subData)[0]]
                                              .scopeOfImprovement.length === 2
                                          ) {
                                            prevState[data][subIndex][
                                              Object.keys(subData)[0]
                                            ].selectedScope = [issue];
                                          } else {
                                            if (selectedScopeIndex !== -1) {
                                              selectedScope.splice(
                                                selectedScopeIndex,
                                                1
                                              );
                                            } else {
                                              selectedScope.push(issue);
                                            }
                                          }
                                          return prevState;
                                        });
                                      }}
                                    >
                                      {issue}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                      </Fragment>
                    ))}
                  </Fragment>
                ))}
              {selectedPoints.includes("Other Feedback") && (
                <>
                  <div className="other-feedback-heading">Other Feedback</div>
                  <div className="other-feedback-subheading">
                    Give a constructive feedback
                  </div>
                  <div className="feedback-textarea">
                    <textarea
                      id="outlined-multiline-static"
                      name="multiline-textarea"
                      rows="4"
                      placeholder="Type Here..."
                      value={feedbackText}
                      onChange={(e) => setFeedbackText(e.target.value)}
                    ></textarea>
                  </div>
                </>
              )}
              {error ? <Alert severity="error">{error}</Alert> : ""}
              <div className="feedback-note">
                Note: For demo sessions, there should be at least one positive
                and one negative feedback in each category.
              </div>
              <button
                onClick={() => transformFeedbackData(feedbackInfo)}
                className="feedback-submit-btn"
                disabled={loder}
              >
                {loder ? "Submitting..." : "Submit"}
              </button>
            </div>
          </div>
        )}
      </Dialog>
    </>
  );
}
