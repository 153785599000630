import React from 'react'
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import {useHistory} from 'react-router-dom';

const BookingRequestToast = ({toasts, toastId, name, dismissToast}) => {
    const [progres, setProgres] = useState(0);
    const [isPlaying, setIsPlaying] = useState(true);
    const progress = useRef(0);
    const startTime = useRef(0);
    const timer = useRef();
    const history = useHistory();
    const duration = 5000; // 5 seconds
  
  
  //   useEffect(()=>{
  //     let timeout;
  //     if (isPlaying) {
  //       timeout = setInterval(() => {
  //         const nextProgress = progress.current + 1;
  //         console.log("progress", progres)
  //         // progress.current = progress.current >= 100 ? 0 : nextProgress;
  //         if(nextProgress >= 100){
  //           clearInterval(timeout);
  //           toast.dismiss(toastId)
  //         }
  //         else progress.current = nextProgress;
  //         setProgres(progress.current);
  //       }, 50);
  //       return ()=>clearInterval(timeout);
  //     }
  //     else clearInterval(timeout);
  // }, [isPlaying])
  
  // useEffect(()=>{
  //   const timer = document.getElementsByClassName('timer')[0];
  //   let timeout = setInterval(()=>{
  //     if(timer.style.width==='100%'){ clearInterval(timeout); 
  //       // toast.dismiss(toastId)
  //     }
  //     timer.style.width=parseInt(timer.style.width)+1+'%';
  //   },50)
  //   return ()=>clearInterval(timeout);
  // },[])
  
  const animateProgressBar = (timestamp) => {
    if(!startTime.current) startTime.current=Date.now();
    const elapsed = Date.now() - startTime.current;
    const progress = Math.min(1, elapsed / duration);
  
  
    // Update the width of the progress bar
    // const timer = document.getElementsByClassName('timer')[0];
    timer.current.style.width = `${progress * 100}%`;
  
    if (progress < 1) {
      // Continue the animation
      requestAnimationFrame(animateProgressBar);
    }
    else{toast.dismiss(toastId)}
  };
  
  
  useEffect(()=>{
    // let start = Date.now();
    // let timer = document.querySelector(".timer");
    // // timestamp: time elapsed in milliseconds since the web page was loaded
    // let timeElapsed = requestAnimationFrame(function animateBall(timestamp) {
    //   let interval = Date.now() - start;
  
    //   timer.style.width = timer.style.width + 1 + "px"; // move element down
  
    //   if (interval < 5000) requestAnimationFrame(animateBall); // queue request for next frame
    // });
    // setTimeout(()=>{
    //   requestAnimationFrame(animateProgressBar);
    // }, 1000)
  },[])
  
  
    return (
        <div className='bookingRequestCard'>
          <div className='header'>💥 Demo Request Incoming</div>
          {/* <button onClick={handlePlayPauseClick}>pause</button>
          <button onClick={handlePlayPauseClick}>play</button> */}
          <div className="timer" ref={timer} style={{background: "#99864D", height: '1px', width: `${progres}%`}}></div>
          <div className="body">
            <div className='sessionInfo'>
              <div className='sessionType'>Demo</div>
              <div className='subject'>Spoken English</div>
            </div>
            <div className='learnerInfo'>
              <div className='learnerName'>{name}</div>
              {/* <div className='aboutLearner'> <i class="fa-solid fa-user"></i> About Learner</div> */}
            </div>
            <div className='sessionTiming'>
              <div className='duration'><i className="fa-solid fa-clock"></i> 15 min</div>
              {/* <div className='timeslot'>02:15 PM - 02:30 PM</div> */}
            </div>
            <div className='btnGroup'>
              <button className='acceptBtn' onClick={()=>{history.push(`/instantbooking?requestid=${toastId}`);}}>View</button>
              <button className='declineBtn' onClick={()=>{dismissToast();}}><div className='xmark'><i className="fa-solid fa-xmark"></i></div></button>
            </div>
          </div>
        </div>
    )
  };
  

export default BookingRequestToast