import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {useLocation} from 'react-router-dom'
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DeclineDemoRequest, DeleteInstantBookingRequest, bookSession } from '../../redux/actions/SessionAction';
import toast from 'react-hot-toast';
import moment from 'moment';
import {useHistory} from 'react-router-dom';
import { UTCToLocalMomentObj, localToUTCMomentObj, sessionBookingDateUTCObj } from '../../helper/sessionHelper';
import { demoBookedEvent } from '../../helper/amplitudeHelper';

const DemoRequestCard = ({requestData}) => {
  const [highlight, setHighlight] = useState(false);
  const [bookingStatus, setBookingStatus] = useState("Accept");
  const [declineLoader, setDeclineLoader] = useState(false);
  const {search} = useLocation();
  const requestCard = useRef();
  const teacher = useSelector((state) => state.TeacherReducer.teacher);
  const dispatch = useDispatch();
  const history = useHistory();
  const {timezone} = JSON.parse(localStorage.getItem("TIMEZONE"));
  const id = requestData?.learner?.id
  const name = requestData?.learner?.name;
  const requestId = requestData.instantBookingRequestId || requestData._id;
  
  useEffect(()=>{
    if(requestCard.current && search && window.document){
      const requestid = (new URLSearchParams(search)).get("requestid");
      console.log("mylogpara", search, requestid, id);
      if(requestid===id){
        setHighlight(true);
      }
    }
  },[search, requestCard])

  const pauseRingtone = () => {
    let audio = document.getElementById('ringtone');
    if(audio) audio.pause();
  }

  const declineRequest = () => {
    if(declineLoader) return;
    pauseRingtone();
    setDeclineLoader(true);
    const onSuccess = () => {
      setDeclineLoader(false);
    }
    const onError = (err) => {
      toast.error(err);
      setDeclineLoader(false);
    }
    const payload = {
        instantBookingRequestId: requestId,
        learnerId: id,
    }
    dispatch(DeclineDemoRequest(payload, onSuccess, onError));
  }

  const acceptRequest = () => {
    if(bookingStatus==="booking...") return ;
    pauseRingtone();
    setBookingStatus("booking...");
    let utcMomentObj = localToUTCMomentObj();
    let startTime = utcMomentObj;
    // if(utcMomentObj.seconds()>=15) startTime = utcMomentObj.add('3', 'minutes');
    // else startTime = utcMomentObj.add('2', 'minutes');
    let endTime = startTime.clone().add('15', 'minutes').format('HH:mm');
    startTime = startTime.format('HH:mm');
    const sessionDate = utcMomentObj.format("YYYY-MM-DD");
    const payload = {
        startTime,
        endTime,
        date: sessionDate,
        duration: 15,
        subject: "English",
        type: "Demo",
        learnerId: id,
        teacherId: teacher._id,
        timezone: timezone,
        instantBookingRequestId: requestId
      }
    
      const onSuccess = (res) =>{
        setBookingStatus("Accepted")
        toast.success("Session successfully booked :)")
        history.push(`/upcomingsession?requestid=${requestId}`);
        const baseURL = window.location.origin; 
        const newURL = `${baseURL}/upcomingsession`;

        if (res?.type === "Demo" && res?.sdkType === "HMS") {
          window.open(`https://clapingo-100ms-web.vercel.app/preview/${res?.roomCode}?learner=${teacher?._id}&type=teacher&name=${res?.teacher?.name}&leaveUrl=${newURL}`, '_blank');
        }

        let time_of_day = moment(res?.createdAt).format("HH:mm");
        let splitted_time_of_day = time_of_day.split(':');
        let time_of_day_min = parseInt(splitted_time_of_day[1]);
        let roundOffMin = Math.round(time_of_day_min / 10) * 10;
        time_of_day = splitted_time_of_day[0]+':'+roundOffMin;

        // const eventProps = {
        //   session_date: sessionBookingDateUTCObj(sessionDate, res.startTime).format("DD-MM-YYYY"),
        //   session_duration: 15,
        //   session_time: res?.startTime,
        //   session_type: res?.type,
        //   subject: res?.subjects,
        //   time_of_day, 
        //   learner_name: name, 
        //   tutor_id: teacher?._id, 
        //   tutor_name: res?.teacher?.name,
        //   phone_number: teacher?.phoneNumber,
        // };
        // demoBookedEvent(eventProps);
      }
  
      const onError = (err) =>{
        setBookingStatus("Error! try again")
        toast.error(err)
      }

      console.log("payload", payload)
  
      bookSession(payload,onSuccess,onError);
  }

  const openSessionNotes = () => {
    window.open(`/session-document?doc_url=https://clapngobucket.s3.amazonaws.com/documents/learner/Demo+Session+Document_compressed.pdf`, "_blank");
  }

  const useStyles = makeStyles((theme) => ({
    demoRequestCard: {
        fontFamily: 'Inter',
        width: '328px',
        height: '198px',
        borderRadius: '16px',
        background: 'conic-gradient(from 42deg at 50% 0%, #EDF1FC 0deg, #ECFDF5 360deg)'
    },
    cardHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '12px 16px 0px',
    },
    sessionType: {
        fontSize: '13px',
        fontWeight: 600,
        color: '#0E433A'
    },
    subject: {
        fontSize: '11px',
        fontWeight: 500,
        color: '#0E433A',
        padding: '2px 6px',
        borderRadius: '24px',
        border: '1px solid rgba(170, 186, 183, 0.50)',
        background: 'linear-gradient(180deg, #C6DAB2 -6.45%, rgba(215, 229, 201, 0.85) 108.98%)'

    },
    duration : {
        color: '#647B76',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        marginTop: '6px'
    },
    timeslot : {
        color: '#0E433A',
        fontSize: '12px',
        fontWeight: 600,
        marginTop: '6px',
    },
    cardBody: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '22px',
        padding: '0px 16px 0px',
    },
    sessionInfo: {
        color: '#4A6EE0',
        fontSize: '11px',
        fontWeight: 400,
        marginTop: '2px',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px'
    },
    sessionInfoChild: {
        display: 'flex',
        gap: '4px',
        alignItems: 'center',
        cursor: 'pointer'
    },
    declineBtn: {
        display: 'flex',
        gap: '4px',
        alignItems: 'center',
        marginTop: '8px',
        cursor: 'pointer',

        '& .xmark': {
            backgroundColor: '#EF4444',
            width: '16px',
            height: '16px',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontSize: '8px'
        },

        '& .text': {
            color: '#EF4444',
            fontSize: '12px'
        }
    },
    btnContainer: {
      padding: '12px 16px',
      '& button' : {
        width: '100%',
        height: '36px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#10B981',
        color: 'white',
        borderRadius: '8px',
        fontSize: '13px',
        fontWeight: 600,
        border: 'none',
        cursor: 'pointer',
        '&:hover': {
          background: "#228F70"
        }  
      }
    }
  }))

  const classes = useStyles();

  return (
    <div className={`${classes.demoRequestCard} ${highlight ? 'wiggle' : ''}`} id={`instant-${id}`} ref={requestCard}>
        <div className={classes.cardHeader}>
            <div className={classes.sessionType}>Demo</div>
            <div className={classes.subject}>Spoken English</div>
        </div>
        <div className={classes.cardBody}>
            <div className='sessionTiming'>
            <div style={{fontSize: '13px', fontWeight: 500}}>{name}</div>
                <div className={classes.duration}><i class="fa-solid fa-clock"></i> 15 min</div>
                {/* <div className={classes.timeslot}>02:15 PM - 02:30 PM</div> */}
                <div className={classes.declineBtn} onClick={declineRequest}><div className='xmark'><i class="fa-solid fa-xmark"></i></div><span className='text'>Decline</span></div>
            </div>
            <div className={classes.sessionInfo}>
                <div className={classes.sessionInfoChild} onClick={openSessionNotes}><i class="fa-solid fa-note-sticky fa-rotate-270"></i> Session Notes</div>
                {/* <div className={classes.sessionInfoChild}> <i class="fa-solid fa-user"></i> About Learner</div> */}
            </div>
        </div>
        <div style={{width: '100%', height: '1px', background: '#C9D4F6', marginTop: '18px'}}></div>
        <div className={classes.btnContainer}>
            <button onClick={acceptRequest}>{bookingStatus}</button>
        </div>
    </div>
  )
}

export default DemoRequestCard