import React, { useEffect, useState } from 'react'
import {
  makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, CircularProgress, Container,
  Card,
  Typography,
  Tabs,
  Tab,
  InputLabel,
  FormControl,
  MenuItem,
  Select
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { TeacherAttendance, TeacherEarnings , GetTeacherConvStats} from '../redux/actions/SessionAction';
import moment from 'moment';
import { formatAMPM, localToUTCDate, localToUTC_YYYY_MM_DD, utcToLocalDateConverter } from '../helper/sessionHelper';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';




import { styled } from '@mui/material/styles';
import { textAlign } from '@mui/system';
import { ContactlessOutlined } from '@material-ui/icons';
import { Adsense } from '@ctrl/react-adsense';



const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}



function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  root: {
    padding: "1rem"
  },
  table: {
    width: '100%'
  },
});

function createData(name, sessionType, meetingId, date, time, attendance, joiningTime) {
  return { name, sessionType, meetingId, date, time, attendance, joiningTime };
}



const Earnings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const teacher = useSelector((state) => state.TeacherReducer.teacher);
  const time_zone = JSON.parse(localStorage.getItem("TIMEZONE"));
  const lastDateOfMonth = moment().endOf("month").format("YYYY-MM-DD");
  const currentDate = moment().format("YYYY-MM-DD");
  const lastDateOfPrevMonth = moment().subtract(1, "month").endOf('month').format('YYYY-MM-DD');

  let calcStartDate;
  let calcEndDate = moment().format('YYYY-MM-DD');
  if(lastDateOfMonth===currentDate) {
    calcStartDate=lastDateOfMonth; 
  }
  else {
    calcStartDate=lastDateOfPrevMonth;
  }
  const [startDate, setStartDate] = useState(calcStartDate)
  const [endDate, setEndDate] = useState(calcEndDate)
  const [fetchStatsLoader ,setFetchStatsLoader] = useState(false);
  const [refferalPercent, setReferralPercentage] = useState(0);
  const [teacherStats, setTeacherStats] = useState({});
  const [sessionList, setSessionList] = useState(null);
  const [earningData, setEarningData] = useState();
  const [fetchSessionLoader, setFetchSessionLoader] = useState(false);
  const [value, setValue] = React.useState(0);
  const [onLoadCalled, setOnLoadCalled] = useState(false);
  const [convReferralEarnings, setConvReferralEarnings] = useState(0);
  const [convProjectedEarnings, setConvProjectedEarnings] = useState(0);
  console.log(teacher)
  var today = new Date();
  var date = String(today.getDate()).padStart(2, '0');
  var month = String(today.getMonth() + 1).padStart(2, '0'); // incremented by becoz month starts from 0
  var year = today.getFullYear();
  today = year + '-' + month + '-' + date
  console.log(today);

  var date_today = new Date();
  var now_utc = Date.UTC(date_today.getUTCFullYear(), date_today.getUTCMonth(),
    date_today.getUTCDate(), date_today.getUTCHours(),
    date_today.getUTCMinutes(), date_today.getUTCSeconds());

  var var_utc_today = new Date(now_utc).toISOString()
  console.log(var_utc_today)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  console.log("earnings", earningData)
  const { TabPane } = Tabs;
  useEffect(() => {
    if (teacher && !onLoadCalled) {
      fetchSessionOnLoad();
    }
  }, [teacher])

  useEffect(() => {
    if (teacher) {
      const data = {
        teacherId: teacher._id
      }
      const params = {
        startDate: localToUTCDate(`${startDate}T24:00`).toISOString(),
        endDate: localToUTCDate(`${endDate}T24:00`).toISOString()
      }

      const onSuccess = (data) => {
        setSessionList(data)
      }
      const onError = (msg) => {
        console.log(msg)
      }
      dispatch(TeacherAttendance(data, params, onSuccess, onError))
    }
  }, [teacher])
  const fetchSessionOnLoad = () => {
    setOnLoadCalled(true)
    const data = {
      teacherId: teacher._id,
      breakDownAfter: startDate,
      endDate: endDate
    }
    console.log("fetching...", startDate, data);


    const onSuccess = (data) => {
      console.log(data)
      setEarningData(data)
      let convRefEarnings = 0;
      let convProjEarnings = 0;
      data?.referralTaken[0]?.docs?.forEach(ref=>{
        if(ref?.isDifferentTutorForDemo)
        convRefEarnings = convRefEarnings+ref?.referrerBonus;
      })
      data?.referralTaken[0]?.docs?.forEach(ref=>{
        if(ref?.isDifferentTutorForDemo)
        convProjEarnings = convProjEarnings+ref?.subscriptionAmount;
      })
      console.log("mylogcheckval", convRefEarnings);
      setConvReferralEarnings(convRefEarnings);
      setConvProjectedEarnings(convProjEarnings)
    }
    const onError = (msg) => {
      console.log(msg)
    }
    dispatch(TeacherEarnings(data, onSuccess, onError))
  }
  const fetchSession = (e) => {

    e.preventDefault();
    setFetchSessionLoader(true)
    const data = {
      teacherId: teacher._id,
      breakDownAfter: startDate,
      endDate: endDate
    }
    console.log("fetching... event", startDate, data);


    const onSuccess = (data) => {
      setEarningData(data)
      let convRefEarnings = 0;
      let convProjEarnings = 0;
      data?.referralTaken[0]?.docs?.forEach(ref=>{
        if(ref?.isDifferentTutorForDemo)
        convRefEarnings = convRefEarnings+ref?.referrerBonus;
      })
      data?.referralTaken[0]?.docs?.forEach(ref=>{
        if(ref?.isDifferentTutorForDemo)
        convProjEarnings = convProjEarnings+ref?.subscriptionAmount;
      })
      console.log("mylogcheckval", convRefEarnings);
      setConvReferralEarnings(convRefEarnings);
      setConvProjectedEarnings(convProjEarnings)
      setFetchSessionLoader(false)
    }
    const onError = (msg) => {
      console.log(msg)
      setFetchSessionLoader(false)
    }
    dispatch(TeacherEarnings(data, onSuccess, onError))
  }

  const reAssignPaneltyCalculator = (sessionList) => {
    var panelty = 0;
    sessionList?.map(data => {
      panelty += data.penalty1
    })
    return panelty
  }

  const rows = sessionList?.map((data) => createData(
    data?.learner?.name ? data.learner.name : "N/A",
    `${data.type} ${data.forKids ? "kid session" : "session"}`,
    data.meetingNo,
    moment(new Date(data.sessionDate)).format('YYYY-MM-DD'),
    `${formatAMPM(data.startTime)} - ${formatAMPM(data.endTime)}`,
    data.speakerJoiningTime?.length > 0 ? "Present" : "Absent",
    `${data.speakerJoiningTime[0] ? data.speakerJoiningTime[0] : "N/A"}`
  )
  )

  //tds
  const tds = (earningData?.totalSessionEarning + earningData?.totalReferralEarning) === 0 || (earningData?.totalSessionEarning + earningData?.totalReferralEarning - earningData?.totalPenalty) < 0 ? 0 : Math.round(((earningData?.totalSessionEarning + earningData?.totalReferralEarning) - (earningData?.totalPenalty)) * parseInt(earningData?.TDS) / 100)
  var bonus = 0;
  if (earningData?.totalHours >= 60 && earningData?.totalHours < 75) {
    bonus = 500;
  } else if (earningData?.totalHours >= 75 && earningData?.totalHours < 100) {
    bonus = 1000;
  } else if (earningData?.totalHours >= 100) {
    bonus = 2000;
  }

// SlabConversionStats
 useEffect((loader) => {
    if(loader) setFetchStatsLoader(true);
    const data = {
      startDate: localToUTCDate(startDate).toISOString(),
      endDate: localToUTCDate(`${endDate}T24:00`).toISOString(),
      timezone: time_zone.timezone
    }
    const onSuccess = (res) => {
      setFetchStatsLoader(false);
      setTeacherStats(res.data);
      console.log("check",res.data?.demoToSubscriptionPercentage)
      if(res.data?.demoToSubscriptionPercentage >= 0 && res.data?.demoToSubscriptionPercentage <= 4.99){
        setReferralPercentage(0)
      }
      if(res.data?.demoToSubscriptionPercentage >= 5 && res.data?.demoToSubscriptionPercentage <= 9.99){
        setReferralPercentage(2)
      }
      if(res.data?.demoToSubscriptionPercentage >= 10 && res.data?.demoToSubscriptionPercentage <= 14.99){
        setReferralPercentage(4)
      }
      if(res.data?.demoToSubscriptionPercentage >= 15 && res.data?.demoToSubscriptionPercentage <= 19.99){
        setReferralPercentage(7)
      }
      if(res.data?.demoToSubscriptionPercentage >= 20){
        setReferralPercentage(10)
      }
    }
    const onError = (err) => {
      setFetchStatsLoader(false);
    }
    dispatch(GetTeacherConvStats(data, onSuccess, onError));
  }, [startDate && endDate && earningData?.totalReferralEarning])


  const hourMinuteConverter = (totalHours) => {
    if(!totalHours){
      return `0 hour 0 minutes`
    }
    // Extract the whole number of hours and the decimal part (minutes)
    const wholeHours = Math.floor(totalHours);
    const decimalMinutes = (totalHours - wholeHours) * 60;

    // Format the result
    const result = `${wholeHours} hour ${Math.round(decimalMinutes)} minutes`;
    return result;
  }

  return (
    <div className={classes.root}>
      <form className='date-picker' onSubmit={(e) => fetchSession(e)}>
        <input type="date" value={moment(startDate).format('YYYY-MM-DD')} max={moment().format('YYYY-MM-DD')} onChange={(e) => setStartDate(e.target.value)} />
        <p>to</p>
        <input type='date' value={moment(endDate).format('YYYY-MM-DD')} max={moment().format('YYYY-MM-DD')} onChange={(e) => { e.target.value !== today ? setEndDate(e.target.value) : setEndDate(today) }} />
        <button type='submit' className='search-btn' disabled={fetchSessionLoader} >{fetchSessionLoader ? "Loading..." : "Search"}</button>
      </form>
      {!sessionList ?
        <Box style={{ marginLeft: "50%" }}>
          <CircularProgress />
        </Box>
        :
        <TableContainer component={Paper} >

          <div class="one">
            <h1>Overview</h1>
          </div>

          <Grid container justifyContent="center">

            <Alert severity="info" >By default, earning is displayed from last day of last month. To check a specific duration, use the search option after selecting the date.</Alert>


          </Grid>

          <div className='earning-calculation-container'>
            <div variant="outlined" style={{ textAlign: "center", padding: "15px" }}>
              <div style={{ display: "inline-block", textAlign: "left" }}>
                {/* <p style={{ fontStyle: "italic" }}>All Referral Earning:  ₹{earningData?.totalReferralEarning}</p>
              <p style={{ fontStyle: "italic" }}>All Session Earning :  ₹{earningData?.totalSessionEarning}</p>

              <div><span style={{ fontSize: "22px", color: "green", fontWeight: "600" }}>Total Earning: </span><span style={{ fontSize: "22px", color: "green", fontWeight: "600" }}> ₹{earningData?.totalEarning}</span></div> */}
                <div>
                  <TableContainer component={Paper} style={{ maxHeight: 150 }}>
                    <Table className={classes.table} aria-label="simple table" stickyHeader>
                      <TableHead>
                        <TableRow style={{ fontWeight: "bold" }} >

                          <TableCell>Session Type</TableCell>
                          <TableCell>Session Taken</TableCell>
                          <TableCell>Pay Per Session</TableCell>
                          <TableCell>Total Pay</TableCell>



                        </TableRow>
                      </TableHead>
                      <TableBody>

                        {/* <TableRow className="success-row"> */}
                        {/* {earningData?.sessionTaken?.map((data, i) => (
                        <div>
                          <TableCell>{`Total Sessions:${data.totalSessions}`}<br/> {`Pay Per Session:${data.payForEachSession}`} </TableCell>
                          
                          </div>
                        ))} */}
                        {/* </TableRow> */}
                        {earningData?.sessionTaken?.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            className="success-row"
                          >
                            <TableCell component="th" scope="row">
                              {row.forKids === false ? `${row.subject} ${row.type} ${row.sessionDuration}` : `Kids ${row.type} ${row.sessionDuration}`}
                            </TableCell>
                            <TableCell align="right">{row.totalSessions}</TableCell>
                            <TableCell align="right">{row.payForEachSession}₹ <br/><span style={{fontSize:"0.7rem"}}> {row._id.subjects==="English" && row._id.type==="Demo" && row._id.sessionDuration==="15" ? `(₹70 for week days and ₹80 for weekends)` : "" }</span></TableCell>
                           <TableCell align="right">{row.earnings} ₹</TableCell>
                            {/* 
                              <TableCell align="right">{row.carbs}</TableCell>
                              <TableCell align="right">{row.protein}</TableCell> 
                          */}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                </div>
              </div>

{/* Tutor Slab conversion */}
              <div style={{ display: "inline-block", textAlign: "left" ,marginTop:"20px" }}>
                <div>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow style={{ fontWeight: "bold" }}>

                        <TableCell>Status</TableCell>
                          <TableCell>Demo Conversion(%)</TableCell>
                          <TableCell>Referral(%)</TableCell>
                          <TableCell>Total Referral Amount</TableCell>


                        </TableRow>
                      </TableHead>
                      <TableBody>

                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            className="success-row first-row"
                          >
                             <TableCell component="th" scope="row">
                              Current 
                            </TableCell>
                            <TableCell align="right">{teacherStats.demoToSubscriptionPercentage}</TableCell>
                            <TableCell align="right">{refferalPercent}</TableCell>
                            <TableCell align="right">₹{Math.round(convReferralEarnings)}</TableCell>
                          </TableRow>

                         {teacherStats.demoToSubscriptionPercentage >= 5 && teacherStats.demoToSubscriptionPercentage <= 9.99 ? "" :
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            className="success-row"
                          >
                             <TableCell component="th" scope="row">
                              Projected
                            </TableCell>
                            <TableCell align="right">5-9.99</TableCell>
                            <TableCell align="right">2</TableCell>
                            <TableCell align="right">₹{Math.round(convProjectedEarnings * (2/100))}</TableCell>
                          </TableRow>
                          }

                          {teacherStats.demoToSubscriptionPercentage >= 10 && teacherStats.demoToSubscriptionPercentage <= 14.99 ? "" :
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            className="success-row"
                          >
                             <TableCell component="th" scope="row">
                              Projected
                            </TableCell>
                            <TableCell align="right">10-14.99</TableCell>
                            <TableCell align="right">4</TableCell>
                            <TableCell align="right">₹{Math.round(convProjectedEarnings * (4/100))}</TableCell>
                          </TableRow>
                         }

                          {teacherStats.demoToSubscriptionPercentage >= 15 && teacherStats.demoToSubscriptionPercentage <= 19.99 ? "":
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            className="success-row"
                          >
                             <TableCell component="th" scope="row">
                              Projected
                            </TableCell>
                            <TableCell align="right">15-19.99</TableCell>
                            <TableCell align="right">7</TableCell>
                            <TableCell align="right">₹{Math.round(convProjectedEarnings * (7/100))}</TableCell>
                          </TableRow>
                          } 

                          {teacherStats.demoToSubscriptionPercentage >= 20 ? "":
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            className="success-row"
                          >
                            <TableCell component="th" scope="row">
                              Projected
                            </TableCell>
                            <TableCell align="right">20+</TableCell>
                            <TableCell align="right">10</TableCell>
                            <TableCell align="right">₹{Math.round(convProjectedEarnings * (10/100))}</TableCell>
                          </TableRow>
}

                      </TableBody>
                    </Table>
                  </TableContainer>

                </div>
              </div>
              {/* <div style={{height: '250px', width: '300px', margin: '16px auto 0px'}}>
                <Adsense
                  client="ca-pub-3715562101512710"
                  slot="JustBaat_Clapingo\/Clapingo_728x90"
                  style={{width: "300px", height: "250px", display: "block"}}
                  format="fluid"
                />
              </div> */}
            </div>

            <div className="total-calculation">



              <p>Session Earnings : <span style={{ color: "green" }}>+{Math.round(earningData?.totalSessionEarning)}₹</span> </p>


              <p>Late Join Penalty ( {earningData?.latePenalty} session * {Number(earningData?.lateJoinPenalty)}): <span className='red'>-{Math.round(earningData?.latePenalty * Number(earningData?.lateJoinPenalty))}₹</span></p>
              <p>Missed Session Penalty ({earningData?.sessionMissingPenalty} session * {Number(earningData?.missingSessionPenalty)}): <span className='red'>-{Math.round(earningData?.sessionMissingPenalty * Number(earningData?.missingSessionPenalty))}₹</span></p>
              <p>Cancelled Session Penalty : <span className='red'>- {earningData?.reassignPenalty ? earningData?.reassignPenalty + " ₹" : "Not available"}</span></p>

              <hr />

              <p>Total Session Earning : <span style={{ color: "green" }}>+{Math.round(earningData?.totalSessionEarning)}₹</span></p>
              <p>Referral Earning : <span style={{ color: "green" }}>+{Math.round(earningData?.totalReferralEarning)}₹</span></p>
              <p>Total Penalty :  <span className='red'>-{Math.round(earningData?.totalPenalty)}₹ </span></p>
              <p>TDS({earningData?.TDS}% after penalty) :  <span className='red'>{tds}₹ </span></p>

              <div style={{ border: "2px solid black", textAlign: "center" }}>
                <strong style={{ color: "green" }}>Bonus criteria</strong><hr /><br />
                Session hrs &nbsp;-&nbsp; Bonus amount
                <br /><br />
                <p>60-75 hrs &nbsp;-&nbsp; ₹500</p>
                <p>75-100 hrs &nbsp;-&nbsp; ₹1000</p>
                <p>{">"}100 hrs &nbsp;-&nbsp; ₹2000</p>
              </div>

              <p>Your session hrs : <span>{
                hourMinuteConverter(earningData?.totalHours)
              } &nbsp;&nbsp; || Bonus : <strong style={{ color: bonus > 0 ? "green" : "red" }}>₹ {bonus}</strong></span></p>

              <hr />
              <h3 className='total-earning'>Net Total :  {Math.round((((earningData?.totalSessionEarning + earningData?.totalReferralEarning) - (earningData?.totalPenalty)) - tds)) + bonus}₹</h3>
            </div>
          </div>



          <div>
            <div class="one">
              <h1>DETAILED BREAKDOWN</h1>
            </div>


            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} variant="scrollable" aria-label="basic tabs example">
                {earningData?.sessionTaken?.map((data, i) => (
                  <Tab label={data.forKids === false ? `${data.subject} ${data.type} ${data.sessionDuration}` : `Kids ${data.type} ${data.sessionDuration}`} {...a11yProps(i)} />
                ))}
                {earningData?.reassignSessions.length > 0 ?
                  <Tab label={'Cancelled Sessions'} />
                  : ""
                }
                {earningData?.referralTaken.length > 0 ?
                  <Tab label="Referral" />
                  : ""}

              </Tabs>
            </Box>

            {earningData?.sessionTaken?.map((data, i, row) => (
              <>
                <TabPanel value={value} index={i}>

                  <div style={{ padding: "20px" }}>
                    <p style={{ marginTop: "10px" }}>Earning Breakdown of this category</p>
                    <Card variant="outlined" style={{ padding: "15px" }}>
                      {/* <p>Total Sessions: {data.totalSessions}</p>
                      <p>Pay Per Session:  {data.payForEachSession} ₹</p>

                      <p style={{ color: "green" }}>Total Earning:  {data.totalSessions * data.payForEachSession} ₹</p> */}

                      <p>Total Sessions: {data.totalSessions}</p>
                      <p>Pay Per Session:  {data.payForEachSession} ₹ {data._id.subjects==="English" && data._id.type==="Demo" && data._id.sessionDuration==="15" ? `(₹70 for week days and ₹80 for weekends)` : "" }</p>
                      <p style={{ color: "green" }}>Total Earning:  {data.earnings} ₹</p>

                    </Card>
                    <p style={{ marginTop: "10px" }}>Session Details of this category</p>


                    <div>
                      <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Session Date</TableCell>
                              <TableCell align="right">Session Start Time</TableCell>
                              <TableCell align="right">Session End Time</TableCell>
                              <TableCell align="right">Speaker Joining Time</TableCell>
                              {/* <TableCell align="right">Learner Joining Time</TableCell> */}

                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.sessionsData.map((session, i) => (
                              <TableRow key={i} className="success-row">
                                <TableCell component="th" scope="row">
                                  {utcToLocalDateConverter(session.sessionDate, session.endTime)}
                                </TableCell>
                                <TableCell align="right">{formatAMPM(session.startTime)}</TableCell>
                                <TableCell align="right">{formatAMPM(session.endTime)}</TableCell>
                                <TableCell align="right">{formatAMPM(session.speakerJoiningTime)}</TableCell>
                                {/* <TableCell align="right">{formatAMPM(session.learnerJoiningTime)}</TableCell> */}


                                </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>

                    </div>

                  </div>
                </TabPanel>
                {/* {earningData?.referralTaken?.map((data, j) => ( */}


                {/* // ))} */}
              </>
            ))}

            {earningData?.reassignSessions?.length > 0 ?
              <TabPanel value={value} index={earningData?.sessionTaken?.length}>

                <div style={{ padding: "20px" }}>
                 
                  <div>
                    <TableContainer component={Paper} >
                      <Table className={classes.table} aria-label="a dense table">
                        <TableHead>
                          <TableRow>

                            <TableCell align="right">Learner Name</TableCell>
                            <TableCell align="right">Session Time</TableCell>
                            <TableCell align="right">Session Type</TableCell>
                            <TableCell align="right">Session Date</TableCell>

                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {earningData?.reassignSessions?.map((session, i) => (
                            <TableRow key={i} className="success-row">

                              <TableCell align="right">{session?.learner?.name || "N/A"}</TableCell>
                              <TableCell align="right">{`${formatAMPM(session?.startTime)}-${formatAMPM(session?.endTime)}`}</TableCell>
                              <TableCell align="right">{session?.type}</TableCell>
                              <TableCell align="right">{moment(new Date(session?.sessionDate)).format('YYYY-MM-DD')}</TableCell>
                              
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                  </div>

                </div>
              </TabPanel> : ""}

            {earningData?.referralTaken.length > 0 ?
              <TabPanel value={value} index={earningData?.reassignSessions.length > 0 ? earningData?.sessionTaken?.length+1 : earningData?.sessionTaken?.length}>

                <div style={{ padding: "20px" }}>
                  <p style={{ marginTop: "10px" }}>Total Referral Earning</p>
                  <Card variant="outlined" style={{ padding: "15px" }}>

                    <p style={{ color: "green" }}>₹{earningData?.referralTaken[0]?.referralEarning?.toFixed()}</p>
                  </Card>
                  <p style={{ marginTop: "10px" }}>Details of this category</p>
          

                  <div>
                    <TableContainer component={Paper} >
                      <Table className={classes.table} aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Referree Name</TableCell>
                            <TableCell align="right">Referrer Name</TableCell>
                            <TableCell align="right">Referree Bonus</TableCell>
                            <TableCell align="right">Referrer Bonus</TableCell>
                            <TableCell align="right">Subscription Amount</TableCell>
                            <TableCell align="right">Referrer Type</TableCell>
                            <TableCell align="right">Date of Demo</TableCell>
                            <TableCell align="right">Date of purchase</TableCell>
                            <TableCell align="right">Conversion Referral</TableCell>
                            {/* <TableCell align="right">Learner Joining Time</TableCell> */}

                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {earningData?.referralTaken[0]?.docs?.map((refer,i) => (
                            <TableRow key={i} className="success-row">
                              <TableCell component="th" scope="row">
                                {refer.referreeName}
                              </TableCell>
                              <TableCell align="right">{refer.referrerName}</TableCell>
                              <TableCell align="right">{Math.round(refer.referreeBonus)}</TableCell>
                              <TableCell align="right">{Math.round(refer.referrerBonus)}</TableCell>
                              <TableCell align="right">{refer.subscriptionAmount}</TableCell>
                              <TableCell align="right">{refer.referrerType}</TableCell>

                              {refer.DemoDate !== "null" || refer.DemoDate !== "N/A" ?
                              <TableCell align="right">{moment(refer.DemoDate).utcOffset("+05:30").format('DD/MM/YYYY')}</TableCell>
                              :
                              <TableCell align="right">N/A</TableCell>
                            }
                           
                              <TableCell align="right">{moment(refer.purchaseDate).utcOffset("+05:30").format('DD/MM/YYYY')}</TableCell>

                              <TableCell align="right">{refer.isDifferentTutorForDemo? "True" : "False"}</TableCell>

                              
                              {/* <TableCell align="right">{formatAMPM(session.learnerJoiningTime)}</TableCell> */}


                            </TableRow>
                          ))}
                          
                        </TableBody>
                        
                      </Table>
                    </TableContainer>

                  </div>

                </div>
              </TabPanel>
              :
              ""}



          </div>


        </TableContainer>
      }


    </div>

  );
}


export default Earnings;