import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "react-simple-snackbar";
import { Link } from "react-router-dom";
import {
  Container,
  Paper,
  Box,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Button,
  Divider,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import { AcceptOrDecline } from "../redux/actions/SessionAction";
import { formatAMPM, dateFormater } from "../helper/sessionHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.grey[300],
    paddingTop: theme.spacing(3),
  },
  card: {
    maxWidth: 300,
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
  },

  acceptBtn: {
    backgroundColor: "#7cb342",
    color: "#fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#43a047",
    },
    // backgroundColor:theme.palette.green[800]
  },
  declineBtn: {
    backgroundColor: "#e53935",
    color: "#fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#d32f2f",
    },
  },
  media: {
    paddingTop: "56.25%",
  },
  content: {
    textAlign: "left",
    padding: theme.spacing.unit * 2,
  },
  divider: {
    margin: `${theme.spacing.unit * 3}px 0`,
  },
  heading: {
    fontWeight: "bold",
  },
  subheading: {
    lineHeight: 1.8,
  },
  cardDiv: {
    flexGrow: 1,
  },
  pagination: {
    marginTop: theme.spacing(5),
  },
}));

const SessionRequests = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [loder, setLoder] = React.useState({
    id: null,
    status: null,
  });
  const teacher = useSelector((state) => state.TeacherReducer.teacher);
  const acceptOrDecline = (ststus, sessionid) => {
    setLoder({ id: sessionid, status: ststus });
    const data = {
      status: ststus,
      sessionID: sessionid,
      teacherID: teacher._id,
    };
    const onSuccess = () => {
      openSnackbar(`Session ${ststus} successfully`);
      setLoder({ id: null, status: null });
    };
    const onError = () => {
      openSnackbar("oops !! somthing went wront");
      setLoder({ id: null, status: null });
    };
    dispatch(AcceptOrDecline(data, onSuccess, onError));
  };

  const PandingSession =   useSelector(
    (state) => state.TeacherReducer.pandingRequest
  );

  const [page, setPage] = React.useState(1);
  const [showPerPage, setShowPerPage] = useState(9);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });
  var total = PandingSession ? PandingSession.length : "0";
  var noOfBtn = Math.ceil(total / showPerPage);

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  useEffect(() => {
    const value = showPerPage * page;
    onPaginationChange(value - showPerPage, value);
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <Container className={classes.root}>
      <Paper component={Box} p={2} mx="auto">
        <h1 className="heading">Session Requests</h1>
        <p>
          You can decline only three session <Link>Terms</Link>
        </p>

        <Box mt={3} className={classes.cardDiv} mx="auto">
          <Grid container spacing={3}>
            {PandingSession ? (
              PandingSession.length > 0 ? (
                PandingSession.slice(pagination.start, pagination.end).map(
                  (data,i) => {
                    return (
                      <Grid item xs={12} sm={4} key={i}>
                        <Card className={classes.card}>
                          <CardActionArea>
                            <CardMedia
                              className={classes.media}
                              image={
                                "https://images.unsplash.com/photo-1522881193457-37ae97c905bf?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80"
                              }
                            />
                          </CardActionArea>
                          <CardContent className={classes.content}>
                            <Typography
                              className={"MuiTypography--heading"}
                              variant={"h6"}
                              gutterBottom
                            >
                              Clapingo Session Request
                            </Typography>
                            <Box>
                            <Typography variant="subtitle2" gutterBottom>
                              Time :{" "}
                              {formatAMPM(data.startTime) +
                                " - " +
                                formatAMPM(data.endTime)}
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                              Date : {dateFormater(data.sessionDate)}
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                              Type : {data.type}
                            </Typography>
                          </Box>
                            <Divider className={classes.divider} light />

                            <Box display="flex" justifyContent="space-between">
                            <Button
                              variant="contained"
                              fullWidth={true}
                              className={classes.acceptBtn}
                              onClick={() =>
                                acceptOrDecline("accept", data._id)
                              }
                              disabled={
                                data._id === loder.id &&
                                loder.status === "accept"
                                  ? true
                                  : false
                              }
                            >
                              {data._id === loder.id &&
                              loder.status === "accept" ? (
                                <CircularProgress />
                              ) : (
                                "Accept"
                              )}
                            </Button>
                            <Button
                              variant="contained"
                              fullWidth={true}
                              className={classes.declineBtn}
                              onClick={() =>
                                acceptOrDecline("decline", data._id)
                              }
                              disabled={
                                data._id === loder.id &&
                                loder.status === "decline"
                                  ? true
                                  : false
                              }
                            >
                              {data._id === loder.id &&
                              loder.status === "decline" ? (
                                <CircularProgress />
                              ) : (
                                "Decline"
                              )}
                            </Button>
                          </Box>
                       
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  }
                )
              ) : (
                <Box mx="auto">
                  <Box m={2} p={1} mx="auto">
                    <i
                      className="fas fa-parachute-box fa-5x"
                      style={{ color: "#D2D2D2" }}
                    ></i>
                  </Box>
                  <Box m={1} mx="auto">
                    Zero Session Requests !
                  </Box>
                </Box>
              )
            ) : (
              <Box mx="auto">
                <CircularProgress />
              </Box>
            )}
          </Grid>
        </Box>

        <Box
          className={classes.pagination}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Pagination
            count={noOfBtn}
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
          />
        </Box>
      </Paper>
    </Container>
  );
};

export default SessionRequests;
